import React, { FC } from 'react'
import Styles from './Modal.module.scss'
import classNames from 'classnames';
import { IModalProps } from '@/shared/types/ModalProps';

interface IModalHeaderProps extends IModalProps {
    title: string;
}

export const ModalHeader: FC<IModalHeaderProps> = ({ title, children, onClose }) => {
    return (
        <>
            <div className={classNames('flex a-center mb-30', Styles.header)}>
                {children}
                <h2 className={classNames(Styles.modalTitle)}>
                    {title}
                </h2>
            </div>
            <div onClick={onClose} className={classNames(Styles.close)}>
            </div>
        </>

    )
}
