import { forwardRef } from 'react';
import classNames from 'classnames';
import Styles from './Input.module.scss';
import { InputProps } from '@/shared/types/InputPropsType';
import { InputError } from './InputError';
import InputMask from 'react-input-mask';
import { observer } from 'mobx-react-lite';


interface Props extends InputProps {
	type?: string;
	isSearch?: boolean;
	masked?: true;
	mask?: string | (string | RegExp)[];
	maskPlaceholder?: string;
	accept?: string | undefined;
	alt?: string | undefined;
	autoComplete?: string | undefined;
	defaultValue?: string | undefined;
	autoFocus?: boolean | undefined;
	capture?: boolean | 'user' | 'environment' | undefined;
	increment?: () => void;
	decrement?: () => void;
	onClick?: () => void;
}

export const Input = observer(forwardRef<HTMLInputElement, Props>(({
																																		 className,
																																		 placeholder,
																																		 onChange,
																																		 isSearch,
																																		 error,
																																		 disabled,
																																		 color,
																																		 defaultValue,
																																		 value,
																																		 type,
																																		 onBlur,
																																		 masked,
																																		 mask,
																																		 maskPlaceholder,
																																		 name,
																																		 increment,
																																		 decrement,
																																		 readonly,
																																		 onClick,
																																	 }, ref) => {

	return (
		<>
			<div className={classNames(Styles.inputWrapper, typeof (className) != 'string' ? className?.wrapper : '')}>
				{
					masked ?
						<InputMask
							className={classNames(Styles.input, error?.length ? Styles.error : '', typeof (className) === 'string' ? className : className?.input, color ? Styles.disabled : '')}
							mask={mask || ''}
							placeholder={placeholder}
							value={value}
							maskPlaceholder={maskPlaceholder}
							ref={ref as any}
							onBlur={onBlur}
							disabled={disabled}
							name={name}
						/>
						:
						<input
							onClick={onClick}
							defaultValue={defaultValue}
							onBlur={onBlur}
							name={name}
							disabled={disabled}
							type={type}
							ref={ref}
							readOnly={readonly}
							onChange={onChange}
							value={value}
							placeholder={placeholder}
							className={classNames(Styles.input, error?.length ? Styles.error : '', typeof (className) === 'string' ? className : className?.input, color ? Styles.disabled : '')} />
				}


				{
					(type === 'number' && !isSearch) &&
					<>
						<div onClick={increment} className={classNames(Styles.arrow)} />
						<div onClick={decrement} className={classNames(Styles.arrow, Styles.arrowDown)} />
					</>
				}
			</div>
			{
				error?.length ?
					<InputError error={error} /> : ''
			}
		</>
	);
}));

