import {PageTitle} from '@/shared/ui/PageTitle'
import {BreadCrumb, BreadCrumbItem} from '@/shared/ui/BreadCumb'
import {Card} from '@/shared/ui/Card'
import {TableView} from '@/shared/ui/Tables'
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from '@tanstack/react-table'
import React, {useEffect, useMemo, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {KkmStore} from "@/entities/Kkm";
import {observer} from "mobx-react-lite";
import {autorun} from "mobx";
import {formatDate} from "@/shared/lib";
import Scrollbars from "react-custom-scrollbars-2";
import Styles from './KkmTransactionInfoPage.module.scss';
import classNames from "classnames";
import {formatDateUnix} from "@/shared/lib/formatDate";

interface ITransaction {
    transactionId: string;
    type: string;
    shiftNumber: number;
    fiscalId: number | null;
    offlineFiscalId: number;
    transactionDate: string;
    sum: number;
    acceptedDate: string;
}

const columnHelper = createColumnHelper<ITransaction>()

export const KkmTransactionInfoPage = observer(() => {
        const location = useLocation()
        const {kkmId, id, transaction_date} = useParams()
        const [data, setData] = useState<ITransaction[]>([])
        const [sorting, setSorting] = useState<SortingState>([])
        useEffect(() => {
            KkmStore.fetchBreadCrumbs(kkmId as string)
            KkmStore.fetchTransactionById(id as string, transaction_date as string)
        }, [id, transaction_date])

        useEffect(() => {
            autorun(() => {
                if (KkmStore.transaction) {
                    setData([KkmStore.transaction])
                }
            })
        }, [])

        const columns = useMemo(() =>
                [
                    columnHelper.accessor('transactionId', {
                        header: "Транзакция"
                    }),
                    columnHelper.accessor('type', {
                        header: "Операция"
                    }),
                    columnHelper.accessor('shiftNumber', {
                        header: "Смена"
                    }),
                    columnHelper.accessor('fiscalId', {
                        header: "Номер"
                    }),
                    columnHelper.accessor('offlineFiscalId', {
                        header: "Аварийный номер"
                    }),
                    columnHelper.accessor('transactionDate', {
                        header: "Дата",
                        cell: ({getValue}) => formatDate(getValue()),
                        sortingFn: (a, b) => formatDateUnix(b.original.transactionDate) - formatDateUnix(a.original.transactionDate)
                    }),
                    columnHelper.accessor('sum', {
                        header: "Сумма"
                    }),
                    columnHelper.accessor("acceptedDate", {
                        header: "Пришло на сервер",
                        cell: ({getValue}) => formatDate(getValue())
                    })
                ]
            , [])

        const tableInstance = useReactTable({
            data,
            columns,
            state: {
                sorting
            },
            onSortingChange: setSorting,
            getSortedRowModel: getSortedRowModel(),
            getCoreRowModel: getCoreRowModel()
        })

        return (
            <>
                <Card className='pt-30 pb-21 mb-12'>
                    <div className='px-27 mb-30'>
                        <BreadCrumb>
                            <BreadCrumbItem to='/taxpayers'>Налогоплательщики</BreadCrumbItem>
                            <BreadCrumbItem
                                to={`/taxpayers/${KkmStore.breadCrumbs?.id}`}>{KkmStore.breadCrumbs?.title}</BreadCrumbItem>
                            <BreadCrumbItem to={`/kkm/${kkmId}`}>ККМ</BreadCrumbItem>
                            <BreadCrumbItem active to={location.pathname}>Информация о транзакции</BreadCrumbItem>
                        </BreadCrumb>
                        <PageTitle>
                            <>
                                Информация о транзакции c ID {id}
                            </>
                        </PageTitle>
                    </div>
                    <Scrollbars autoHeight>
                        <TableView className={classNames(Styles.table, "table-left")} tableInstance={{...tableInstance}}/>
                    </Scrollbars>
                </Card>
                <Card>
                    <h3 className='fs-18 py-15 px-27 fw-400 c_first_color'>
                        Данные чека:
                    </h3>
                    <div className='bg_fourth_color'>
                        <div className='c_first_color fs-12 py-15 px-27'>
                        <pre>
                            {
                                JSON.stringify({
                                    requestBody: JSON.parse(KkmStore.transaction?.requestBody || '{}'),
                                    responseBody: JSON.parse(KkmStore.transaction?.responseBody || '{}')
                                }, null, 1)
                            }

                        </pre>
                        </div>
                    </div>
                </Card>
            </>
        )
    }
)
