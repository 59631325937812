import React, { FC, useState } from 'react';
import { PageTitle } from '@/shared/ui/PageTitle';
import { Card } from '@/shared/ui/Card';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useSelect } from '@/shared/hooks';
import { Select } from '@/shared/ui/Select';
import s from './Decoder.module.scss';
import { Textarea } from '@/shared/ui/Textarea';
import { Button } from '@/shared/ui/Buttons';
import { api } from '@/shared/api/api';
import { CloseSearchIcon } from '@/assets/icons/CloseSearchIcon';
import { Preload } from '@/shared/ui/Preload';
import axios from 'axios';
import { toast } from 'react-toastify';


export const DecoderPage: FC = observer(() => {
		const {
			options,
			handleChangeOption,
			getLabelOption,
		} = useSelect([
			{
				id: 0,
				label: 'Запрос (HEX|BASE64)',
				checked: true,
			},
			{
				id: 1,
				label: 'Ответ (HEX|BASE64)',
				checked: false,
			},
		]);
		const [message, setMessage] = useState('');
		const [data, setData] = useState<null | { messageHeader: string; payload: string; error: string }>(null);
		const [isLoading, setIsLoading] = useState(false);
		const decode = async () => {
			if(!message.length) return
			setIsLoading(true);
			try {
				const selectedOption = options.find(item => item.checked);
				const response = await api.decode(selectedOption?.id === 0 ? 'outbound' : 'incoming', message);
				setData(response);
			} catch (e) {
				if (axios.isAxiosError(e)) {
					const response = e?.response as { data: { details: string[] } };
					toast.error(response?.data?.details[0] as unknown as string || 'Произошла ошибка, попробуйте еще раз');
				}
			}
			setIsLoading(false);
		};
		const reset = () => {
			setData(null);
			setMessage('');
		};
		return (
			<div>
				<Card className={classNames('pt-30 pr-32 pl-27 pb-23 mb-12')}>
					<PageTitle className={classNames('mb-30')}>
						Дешифратор
					</PageTitle>
					<Select contentClassName={s.selectContent}
									className={s.select} label={getLabelOption()} closeOnSelect={true}>
						{
							options.map(item =>
								<li key={item.id}
										onClick={() => {
											handleChangeOption(item.id);
										}}
										className={classNames('option', item.checked && 'active')}>
									<label>
										{item.label}
									</label>
								</li>,
							)
						}
					</Select>
					<div className={s.wrap}>
						<div className={s.reset} onClick={reset}>
							<CloseSearchIcon />
						</div>
						<Textarea value={message} onChange={setMessage} className={s.textarea}
											placeholder='Введите код запроса или ответа для расшифрования'></Textarea>
					</div>
					<Button onClick={decode} className={s.button}>Дешифровать</Button>
				</Card>
				<Card className={classNames('mb-12', s.header)}>
					<h3 className='fs-18 py-15 px-27 fw-400 c_first_color'>
						Заголовок:
					</h3>
					<div className={s.body}>
						<div className='c_first_color fs-12'>
							{data?.messageHeader || '-'}
						</div>
					</div>
				</Card>
				<Card className={classNames('mb-12', s.header)}>
					<h3 className='fs-18 py-15 px-27 fw-400 c_first_color'>
						Сообщение (текст):
					</h3>
					<div className={s.body}>
						<div className='c_first_color fs-12'>
							{data?.payload ? JSON.stringify(JSON.parse(data?.payload), null, 1) : '-'}
						</div>
					</div>
				</Card>
				{isLoading && <Preload />}
			</div>
		);
	},
);
