import {api} from '@/shared/api/api'
import {flow, types} from 'mobx-state-tree'


const ServiceAccountModel = types.model('ServiceAccountModel', {
    loading: types.boolean,
    totalItems: types.number,
    itemsPerPage: types.number,
    totalPages: types.number,
    currentPage: types.number,
    organisationUsersList: types.array(types.frozen())
}).actions(self => ({
    fetchAll: flow(function* (params?) {
        self.loading = true
        try {
            const response = yield api.getServicesAccountList(params)
            self.currentPage = response.currentPage
            self.totalPages = response.totalPages
            self.organisationUsersList = response.organisationUsersList || []
            self.itemsPerPage = response.itemsPerPage
        }catch (e) {
            throw e
        }finally {
            self.loading = false
        }

    }),
    addServiceAccount: flow(function* (user: any) {
        const data = yield api.createServiceAccount(user)
        self.organisationUsersList.push(data)
    }),
    deleteServiceAccount: flow(function* (id: number | string) {
        yield api.serviceAccountDelete(id)
        const index = self.organisationUsersList.findIndex(item => item.id === id)
        self.organisationUsersList.splice(index, 1)
    }),
    updateServiceAccount: flow(function* (id: number, user: any) {
        const index = self.organisationUsersList.findIndex(item => item.id === id)
        yield api.updateServiceAccount(user)
        self.organisationUsersList.splice(index, 1, {...self.organisationUsersList[index], ...user})
    }),
    bindToOrg: flow(function* (login: string, orgId: number) {
        const response = yield api.serviceAccountBindingToOrg(login, orgId)
        return response
    })
})).views(self => ({
    serviceAccount(id?: number | null) {
        return self.organisationUsersList.find(item => item.id === id)
    }
}))

export const ServiceAccountStore = ServiceAccountModel.create({
    loading: false,
    organisationUsersList: [],
    currentPage: 1,
    itemsPerPage: 15,
    totalItems: 0,
    totalPages: 0,
})
