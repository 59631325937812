import React from 'react';
import {Card} from "@/shared/ui/Card";
import {TariffForm} from "@/features/Tariffs";
import cn from "classnames"
export const TariffsFormPage = () => {
    return (
        <Card className={cn("py-30 px-27")}>
            <TariffForm/>
        </Card>
    );
};

