import {api} from '@/shared/api/api'
import {flow, types} from 'mobx-state-tree'


const UsersModel = types.model('UserListModel', {
    loading: types.boolean,
    totalItems: types.number,
    itemsPerPage: types.number,
    totalPages: types.number,
    currentPage: types.number,
    users: types.array(types.frozen())
}).actions(self => ({
    fetchAll: flow(function* (data?, params?) {
        self.loading = true
        try {
            const response = yield api.getUsersList(data, params)
            self.currentPage = response.currentPage
            self.totalPages = response.totalPages
            self.users = response.users || []
            self.itemsPerPage = response.itemsPerPage
        } catch (e) {
            console.error(e)
            throw e
        }
        self.loading = false
    }),
    addUser: flow(function* (user: any) {
        yield api.createUser(user)
    }),
    deleteUser: flow(function* (id: number) {
        yield api.deleteUser(id)
    }),
    editUser: flow(function* (id: number, user: any) {
        const index = self.users.findIndex(item => item.id === id)
        if (self.users[index].blocked !== user.blocked) {
            yield api.userSetActivate(id, !user.blocked)
        }
        yield api.editUser(id, user)
    })
})).views(self => ({
    user(id?: number | null) {
        return self.users.find(item => item.id === id)
    }
}))

export const UsersStore = UsersModel.create({
    loading: false,
    users: [],
    currentPage: 1,
    itemsPerPage: 15,
    totalItems: 0,
    totalPages: 0,
})
