import React, {useEffect, useState} from 'react';
import classNames from "classnames";
import {Input} from "@/shared/ui/Inputs";
import {Button} from "@/shared/ui/Buttons";
import Styles from '../styles/style.module.scss'
import {showToast} from "@/shared/lib";
import {TaxpayersStore} from "@/entities/Taxpayer";
import {useParams} from "react-router-dom";
import {ConfirmModal} from "@/modals/General";

const BlockUnblock = () => {
    const [confirmBlockModal, setConfirmModalBlock] = useState(false)
    const [reason, setReason] = useState("")
    const {id} = useParams()

    useEffect(() => {
        if (!TaxpayersStore.currentTaxpayer?.blockedAt) {
            setReason("")
        } else {
            setReason(TaxpayersStore?.currentTaxpayer?.blockReason)
        }
    }, [TaxpayersStore.currentTaxpayer])
    const block = async () => {
        setConfirmModalBlock(false)
        await showToast(async () => {
            const response = await TaxpayersStore.blockUnblock(Number(id as string), Boolean(TaxpayersStore.currentTaxpayer?.blockedAt), reason)
            await TaxpayersStore.fetchById(Number(id))
            return response
        })
    }
    return (
        <div className={classNames("item grow")}>
            <div className='mb-15'>
                Блокировка организации:
            </div>
            <Input placeholder="Опишите причину блокировки" className='mb-15' value={reason}
                   onChange={e => setReason(e.target.value)}/>
            <Button disabled={!TaxpayersStore.currentTaxpayer?.blockedAt && !reason}  className={classNames(Styles.button)}
                    onClick={() => setConfirmModalBlock(true)}>
                {TaxpayersStore.currentTaxpayer?.blockedAt ? "Разблокировать" : "Блокировать"}
            </Button>
            {
                confirmBlockModal &&
                <ConfirmModal onAccept={block} acceptButtonText="Да" onReject={() => setConfirmModalBlock(false)}
                              message={`Вы действительно хотите ${TaxpayersStore?.currentTaxpayer.blockedAt ? "разблокировать" : "заблокировать"} организацию?`}/>
            }
        </div>
    );
};

export default BlockUnblock;
