import {useState, FC, useMemo, useEffect, useRef, useCallback} from 'react'
import {Card} from '@/shared/ui/Card'
import classNames from 'classnames'
import {PageTitle} from '@/shared/ui/PageTitle'
import {Button} from '@/shared/ui/Buttons'
import {InputSearchIcon} from '@/shared/ui/Inputs'
import {SearchIcon} from '@/assets/icons/SearchIcon.'
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from '@tanstack/react-table'
import {TableView} from '@/shared/ui/Tables'
import {observer} from 'mobx-react-lite'
import {getSnapshot} from 'mobx-state-tree'
import {ContextMenu, ContextMenuItem} from '@/shared/ui/Menu'
import {EyeIcon} from '@/assets/icons/EyeIcon'
import {TrashIcon} from '@/assets/icons/TrashIcon'
import {EditIcon} from '@/assets/icons/EditIcon'
import theme from '@/shared/themes'
import {ButtonRefresh} from '@/shared/ui/Buttons'
import {SearchNoResult} from '@/shared/ui/SearchNoResult'
import {PageNoData} from '@/shared/ui/PageNoData'
import {UsersStore} from '@/store/UsersStore.'
import {IServiceAccount} from '@/shared/types/GlobalTypes'
import {useSearchParams} from 'react-router-dom'
import {Paginate} from '@/shared/ui/Paginate'
import {Preload} from '@/shared/ui/Preload'
import {ServiceAccountStore} from "@/store/ServiceAccountStore";
import Styles from './ServiceAccountPage.module.scss'
import {DeleteServiceAccountModal} from "@/modals/ServiceAccount/DeleteServiceAccountModal";
import {CardServiceAccountModal} from "@/modals/ServiceAccount/CardServiceAccountModal";
import {TypeModal} from "@/modals/ServiceAccount/typeModal";
import {formatDate} from "@/shared/lib";
const columnHelper = createColumnHelper<IServiceAccount>()

export const ServiceAccountPage: FC = observer(() => {

    const [searchParams, setSearchParams] = useSearchParams()
    const page = searchParams.get('page')
    const size = searchParams.get('size')
    const searchQueryParam = searchParams.get("search")
    const [sorting, setSorting] = useState<SortingState>([])
    const searchInput = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (searchInput.current) {
            searchInput.current.value = searchQueryParam || ""
        }
    }, [searchInput])
    /** **/
    const fetchData = useCallback(() => {
            return ServiceAccountStore.fetchAll({login: searchQueryParam, page, size})
        }
        , [size, page, searchQueryParam])

    useEffect(() => {
        fetchData()
        if (Number(size) > 25) {
            setSearchParams(params => {
                params.set("size", "10")
                return params
            })
        }
    }, [size, page, searchQueryParam, fetchData])

    const [typeModal, setTypeModal] = useState<null | TypeModal>(null)
    const [showModal, setShowModal] = useState(false)
    const [currentAccountId, setAccountId] = useState<null | number>(null)

    const openCardModal = (type: TypeModal, accountId?: number) => {
        setTypeModal(type)
        setShowModal(true)
        if (accountId) setAccountId(accountId)
    }

    const closeCardModal = () => {
        setShowModal(false)
        setAccountId(null)
        setTypeModal(null)
    }

    const onPaginate = (event: React.ChangeEvent<unknown>, page: Number) => {
        setSearchParams({
            page: page.toString(),
        })
    }

    const handleRefresh = () => {
        if (searchInput.current) {
            searchInput.current.value = ''
            setSearchParams({})
        }
    }

    const onChangeSelectSize = (size: number) => {
        setSearchParams(params => {
            params.set("size", size.toString())
            return params
        })
    }

    const handleSearch = () => {
        const searchQuery = searchInput.current?.value
        setSearchParams({
            search: searchQuery || ""
        })
    }

    const [deleteServiceAccountModal, setDeleteServiceAccountModal] = useState<boolean>(false)
    /*creating table */
    const columns = useMemo(() =>
            [
                columnHelper.accessor('name', {
                    header: 'ФИО'
                }),
                columnHelper.accessor('createdAt', {
                    header: 'Дата создания',
                    cell: ({getValue}) => formatDate(getValue())
                }),
                columnHelper.accessor('login', {
                    header: 'Логин'
                }),
                columnHelper.accessor('email', {
                    header: 'E-mail'
                }),
                columnHelper.display({
                    id: 'id',
                    cell: (props) =>
                        <ContextMenu>
                            <ContextMenuItem onClick={() => openCardModal(TypeModal.VIEW, props.row.original.id)}>
                                <div className="flex a-center">
                                    <div className='flex a-center mr-10'>
                                        <EyeIcon color={theme.firstColor}/>
                                    </div>
                                    <span>
                                    Просмотр аккаунта
                                </span>
                                </div>
                            </ContextMenuItem>
                            <ContextMenuItem onClick={() => openCardModal(TypeModal.EDIT, props.row.original.id)}>
                                <div className="flex a-center">
                                    <div className="flex a-center mr-10">
                                        <EditIcon/>
                                    </div>
                                    <span>
                                    Редактировать аккаунт
                                </span>
                                </div>
                            </ContextMenuItem>
                            <ContextMenuItem onClick={() => {
                                setAccountId(props.row.original.id)
                                setDeleteServiceAccountModal(true)
                            }
                            }>
                                <div className="flex a-center">
                                    <div className="flex a-center mr-10">
                                        <TrashIcon color={theme.deleteColor} width='15' height='16'/>
                                    </div>
                                    <div className='c_danger_color'>
                                        Удалить аккаунт
                                    </div>
                                </div>

                            </ ContextMenuItem>
                        </ContextMenu>

                })
            ]
        , [])

    const tableInstance = useReactTable({
        columns,
        state:{
          sorting,
        },
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        data: getSnapshot(ServiceAccountStore.organisationUsersList),
        getCoreRowModel: getCoreRowModel(),
    })


    return (
        <>
            <Card className={classNames('py-30 px-27 mb-12')}>
                <PageTitle className={classNames('mb-30')}>
                    Служебный аккаунт
                </PageTitle>
                <div className="flex a-center">
                    <InputSearchIcon
                        ref={searchInput}
                        className={classNames('input')}
                        id="search-input"
                        placeholder='Поиск по аккаунту (логину)'>
                        <Button onClick={handleSearch} isIcon className='search-button'>
                            <SearchIcon color={theme.fifthColor}/>
                        </Button>
                    </InputSearchIcon>
                    <ButtonRefresh className='mr-32' onClick={handleRefresh}/>
                    <Button onClick={() => openCardModal(TypeModal.CREATE)}
                            className={classNames('button', Styles.buttonCreate)}>
                        <div className="flex j-center a-center">
                            <span className='mr-8 fs-20 plus'>+</span>
                            <span>Создать служебный аккаунт</span>
                        </div>
                    </Button>
                </div>
            </Card>
            {
                !ServiceAccountStore.organisationUsersList.length && !searchInput.current?.value
                    ?
                    <PageNoData/> :
                    !ServiceAccountStore.organisationUsersList.length && searchInput.current?.value ?
                        <SearchNoResult searchQuery={searchInput.current?.value}/> :
                        <Card>
                            <TableView tableInstance={{...tableInstance}}
                                       className={classNames('table-border-radius table-left')}/>
                        </Card>
            }
            {
                deleteServiceAccountModal &&
                <DeleteServiceAccountModal accountId={currentAccountId}
                                           onClose={() => setDeleteServiceAccountModal(false)}/>
            }
            {
                ServiceAccountStore.organisationUsersList.length ?
                    <Paginate
                        onChange={onPaginate}
                        currentPage={ServiceAccountStore.currentPage}
                        countPages={ServiceAccountStore.totalPages}
                        size={size}
                        onChangeSelectSize={onChangeSelectSize}
                    /> : ''
            }
            {
                showModal &&
                <CardServiceAccountModal onFetch={() => fetchData()}
                                         id={currentAccountId} type={typeModal}
                                         onEdit={() => setTypeModal(TypeModal.EDIT)}
                                         onClose={closeCardModal}/>
            }
            {
                UsersStore.loading && <Preload/>
            }
        </>

    )
})
