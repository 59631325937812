import { FC, useState } from 'react'
import { LoginForm } from './LoginForm'
import { Input } from '@/shared/ui/Inputs'
import Styles from './Auth.module.scss'
import { Button } from '@/shared/ui/Buttons'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'
import { rootStore } from '@/store/rootStore'
import { IErrorMessage } from '@/shared/api/instanceAxios'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import { Preload } from '@/shared/ui/Preload'


export const ResetPass: FC = () => {
  const navigate = useNavigate()
  const [showPreload, setShowPreload] = useState(false)
  const {
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm({
    mode: 'onBlur',
  })

  const sendCode = async ({ email }: any) => {
    setShowPreload(true)
    try {
      const response = await rootStore.recoveryPassword.getCodePassReset(email)
      if (response) {
        navigate('/login/checkcode')
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const response = await e.response?.data as IErrorMessage
        toast.error(response?.details?.join(" "), {
          position: 'bottom-right'
        })
      }
    } finally {
      setShowPreload(false)
    }
  }

  return (
    <div>
      <LoginForm
        className="pt-99 pb-118 px-36"
        title='Восстановление пароля'
        subtitle='Для восстановления пароля введите свой e-mail, под которым Вы зарегистрированы в Системе'>
        <div className='px-14'>
          <form onSubmit={handleSubmit(sendCode)}>
            <label className={classNames(Styles.label, "mt-34 mb-21")}>
              <span className={Styles.captionInput}>
                E-mail
              </span>
              <Input {
                ...register('email', {
                  required: {
                    value: true,
                    message: 'Это поле обязательно для заполнения'
                  }, pattern: {
                    value: /^[_a-z0-9-\+-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i,
                    message: 'Введите корректный адрес электронной почты'
                  },
                })
              }
                error={errors.email?.message as string}
                placeholder='Введите Email'
                type='email' />
            </label>
            <div className="flex" style={{ columnGap: 18 }}>
              <Button color='cancel' onClick={() => navigate('/login')}>
                Отмена
              </Button>
              <Button type='submit'>
                Отправить
              </Button>
            </div>
          </form>
        </div>





      </LoginForm>
      <ToastContainer />
      {
        showPreload && <Preload />
      }
    </div >
  )
}
