import { FC, useEffect, useState } from 'react';
import { LoginForm } from './LoginForm';
import { Button } from '@/shared/ui/Buttons';
import { Input } from '@/shared/ui/Inputs/Input';
import classNames from 'classnames';
import Styles from './Auth.module.scss';
import { useNavigate } from 'react-router-dom';
import { rootStore } from '@/store/rootStore';
import { useForm } from 'react-hook-form';
import { api } from '@/shared/api/api';

export const CheckCode: FC = () => {

	const {
		register,
		handleSubmit,
		setError,
		formState: {
			errors,
		},
	} = useForm();


	const navigate = useNavigate();

	const [showIsSend, setShowIsSend] = useState(false);

	useEffect(() => {
		if (!rootStore.recoveryPassword.codeWaiting) {
			navigate('/');
		}
	}, [rootStore.recoveryPassword.codeWaiting, navigate]);

	useEffect(() => {
		let timerId = setTimeout(() => {
			setShowIsSend(true);
		}, 30000);
		return () => {
			clearTimeout(timerId);
		};
	}, [showIsSend]);


	const resend = () => {
		rootStore.recoveryPassword.getCodePassReset(rootStore.recoveryPassword.emailRecovery as string);
		setShowIsSend(false);
	};

	const sendCode = async (data: any) => {
		try {
			const response = await api.checkRecoveryCode(rootStore.recoveryPassword.emailRecovery as string, data.code);
			if (response.data.valid) {
				rootStore.recoveryPassword.setCode(data.code as string);
				navigate('/login/newPass');
			}else{
				setError('code', {
					message: 'Неверный код',
				});
			}
		} catch (e) {
			console.log(e)
		}
	};

	return (
		<LoginForm
			className='pt-99 pb-138 px-36'
			title='Восстановление пароля'
			subtitle='На Ваш e-mail отправлен проверочный код'>
			<form onSubmit={handleSubmit(sendCode)}>
				<label className={classNames(Styles.label, 'mt-34 mb-21')}>
                    <span className={Styles.captionInput}>
                        Проверочный код
                    </span>
					<Input {...register('code', {
							required: {
								value: true,
								message: 'Заполните это поле',
							},
							minLength: {
								value: 4,
								message: 'Неверный код',

							},
						},
					)}
								 error={errors?.code?.message as string}
								 placeholder='Введите код'
					/>
				</label>

				<div className='flex mb-15' style={{ columnGap: 18 }}>
					<Button color='cancel' onClick={() => navigate('/login')}>
						Отмена
					</Button>
					<Button type='submit'>
						Отправить
					</Button>
				</div>
				{
					showIsSend && <label onClick={resend} className={classNames(Styles.resendLabel, 'pointer')}>
						Отправить ещё раз
					</label>
				}
			</form>

		</LoginForm>

	);
};
