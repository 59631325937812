import React, { FC, useEffect, useState } from "react";
import { Modal } from "@/shared/ui/Modal";
import classNames from "classnames";
import { Button } from "@/shared/ui/Buttons";
import { IModalProps } from "@/shared/types/ModalProps";
import { observer } from "mobx-react-lite";
import { DangerIcon } from "@/assets/icons/DangerIcon";
import { ArrowIcon } from "@/assets/icons/ArrowIcon";
import styles from "./ResultUploadModalExcel.module.scss";
interface IProps extends IModalProps {
  validEmails: string[];
  invalidEmails: string[];
}

export const ResultUploadModalExcel: FC<IProps> = observer(
  ({ onClose, validEmails, invalidEmails }) => {
    const [showInvalids, setShowInvalids] = useState(false);
    return (
      <Modal onClose={onClose}>
        <div className={classNames("flex column a-center")}>
          <DangerIcon width="50" height="50" />
          <div className={classNames("fs-18 c_first_color mb-7")}>
            Ошибка!
          </div>
          <div className={classNames("fs-18 c_first_color mb-28")}>
            Не удалось загрузить некоторые записи из файла
          </div>
          <div className={classNames("c_first_color fs-16 mb-22")}>
            Загружено записей {validEmails.length} из{" "}
            {invalidEmails.length + validEmails.length}
          </div>
          <div className={classNames("fs-16 c_danger_color mb-8")}>
            Неуспешно: {invalidEmails.length}
          </div>
          <div
            onClick={() => setShowInvalids(!showInvalids)}
            className={classNames("fs-16 flex a-center pointer mb-20")}
          >
            <span>
              Посмотреть
            </span>
            <div>
              <ArrowIcon />
            </div>
          </div>
          {showInvalids
            ? (
              <div className={classNames(styles.list)}>
                {invalidEmails.map((item) => (
                  <div className={classNames(styles.item)} key={item}>
                    {item}
                  </div>
                ))}
              </div>
            )
            : ""}
        </div>
        <div className="flex j-end">
          <Button
            onClick={onClose}
            color="cancel"
            className={classNames("mr-18 button")}
          >
            Закрыть
          </Button>
        </div>
      </Modal>
    );
  },
);
