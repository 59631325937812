import React, { FC, ReactNode } from 'react'
import Styles from './LoginForm.module.scss'
import { Card } from '@/shared/ui/Card/Card'
import classNames from 'classnames'

interface LoginFormProps {
    title: string;
    subtitle: string;
    children: ReactNode;
    className?: string;
    style?: React.CSSProperties

}

export const LoginForm: FC<LoginFormProps> = ({ title, subtitle, children, className, style }) => {
    return (
        <div className={classNames(Styles.loginForm)}>
            <Card className={classNames(Styles.card, className)} style={style} >
                <h2 className={Styles.title}>
                    {title}
                </h2>
                <h3 className={Styles.subtitle}>
                    {subtitle}
                </h3>
                {children}
            </Card>
        </div >
    )
}
