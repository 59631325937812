import { FC } from 'react'
import { IconProp } from './iconType'
export const MapIcon: FC<IconProp> = ({ color }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.65 21.41C15.22 21.41 14.79 21.32 14.44 21.15L9.19004 18.52C8.89004 18.37 8.30004 18.38 8.01004 18.55L5.65004 19.9C4.63004 20.48 3.58004 20.56 2.79004 20.09C1.99004 19.63 1.54004 18.69 1.54004 17.51V7.79003C1.54004 6.88003 2.14004 5.85003 2.93004 5.40003L7.26004 2.92003C7.99004 2.50003 9.10004 2.47003 9.85004 2.85003L15.1 5.48003C15.4 5.63003 15.98 5.61003 16.28 5.45003L18.63 4.11003C19.65 3.53003 20.7 3.45003 21.49 3.92003C22.29 4.38003 22.74 5.32003 22.74 6.50003V16.23C22.74 17.14 22.14 18.17 21.35 18.62L17.02 21.1C16.64 21.3 16.14 21.41 15.65 21.41ZM8.64004 16.92C9.07004 16.92 9.50004 17.01 9.85004 17.18L15.1 19.81C15.4 19.96 15.98 19.94 16.28 19.78L20.61 17.3C20.93 17.12 21.24 16.58 21.24 16.22V6.49003C21.24 5.86003 21.06 5.39003 20.73 5.21003C20.41 5.03003 19.91 5.10003 19.37 5.41003L17.02 6.75003C16.29 7.17003 15.18 7.20003 14.43 6.82003L9.18004 4.19003C8.88004 4.04003 8.30004 4.06003 8.00004 4.22003L3.67004 6.70003C3.35004 6.88003 3.04004 7.42003 3.04004 7.79003V17.52C3.04004 18.15 3.22004 18.62 3.54004 18.8C3.86004 18.99 4.36004 18.91 4.91004 18.6L7.26004 17.26C7.65004 17.03 8.15004 16.92 8.64004 16.92Z" fill={color} />
            <path d="M8.56006 17.75C8.15006 17.75 7.81006 17.41 7.81006 17V4C7.81006 3.59 8.15006 3.25 8.56006 3.25C8.97006 3.25 9.31006 3.59 9.31006 4V17C9.31006 17.41 8.97006 17.75 8.56006 17.75Z" fill={color} />
            <path d="M15.73 20.75C15.32 20.75 14.98 20.41 14.98 20V6.62C14.98 6.21 15.32 5.87 15.73 5.87C16.14 5.87 16.48 6.21 16.48 6.62V20C16.48 20.41 16.14 20.75 15.73 20.75Z" fill={color} />
        </svg>

    )
}

