import { DangerIcon } from '@/assets/icons/DangerIcon'
import classNames from 'classnames'
import Styles from './WarningMessage.module.scss'
import React, { FC } from 'react'

interface IWarningMessage {
    children: React.ReactNode;
    className?: string;
}

export const WarningMessage: FC<IWarningMessage> = ({ children, className }) => {
    return (
        <div className={classNames(Styles.wrapper, className)}>
            <div className="flex a-center">
                <div className={classNames(Styles.border)}></div>
                <div className='item mr-12'>
                    <DangerIcon />
                </div>
                {children}
            </div>
        </div>
    )
}
