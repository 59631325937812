import {PageTitle} from '@/shared/ui/PageTitle'
import {BreadCrumb, BreadCrumbItem} from '@/shared/ui/BreadCumb'
import {Card} from '@/shared/ui/Card'
import {TableView} from '@/shared/ui/Tables'
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from '@tanstack/react-table'
import classNames from 'classnames'
import React, {useEffect, useMemo, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import Styles from './LastShiftPage.module.scss'
import {Scrollbars} from "react-custom-scrollbars-2";
import {KkmStore} from "@/entities/Kkm";
import {observer} from "mobx-react-lite";
import {getSnapshot} from "mobx-state-tree";
import {formatDateUnix} from "@/shared/lib/formatDate";

interface ILastShift {
    transactionId: number;
    lastOpenedShiftId: number;
    lastClosedShiftId: number;
    numOfTransactionsInShift: number;
    transactionDate: string;
    lastOpenedShiftDate: string;
    lastClosedShiftDate: string;
    transactionType: string;
    currentShiftId: number;
    isShiftClosed: boolean;

}

const columnHelper = createColumnHelper<ILastShift>()

export const LastShiftPage = observer(() => {
        const location = useLocation()
        const {id} = useParams()
        const [sorting, setSorting] = useState<SortingState>([])
        useEffect(() => {
            KkmStore.fetchBreadCrumbs(id as string)
            KkmStore.fetchLastShift(Number(id))
        }, [id])
        const columns = useMemo(() => [
            columnHelper.accessor('transactionId', {
                header: "№ последней транзакции"
            }),
            columnHelper.accessor('lastOpenedShiftId', {
                header: '№ последней открытой смены'
            }),
            columnHelper.accessor('lastClosedShiftId', {
                header: "№ последней закрытой смены"
            }),
            columnHelper.accessor('numOfTransactionsInShift', {
                header: "Количество транзакций / документов в смене"
            }),
            columnHelper.accessor('transactionDate', {
                header: "Дата последней транзакции",
                sortingFn: (a, b) => formatDateUnix(b.original.transactionDate) - formatDateUnix(a.original.transactionDate)
            }),
            columnHelper.accessor('lastOpenedShiftDate', {
                header: "Дата последнего открытия смены",
                sortingFn: (a, b) => formatDateUnix(b.original.lastOpenedShiftDate) - formatDateUnix(a.original.lastOpenedShiftDate)

            }),
            columnHelper.accessor('lastClosedShiftDate', {
                header: "Дата последнего закрытия смены",
                sortingFn: (a, b) => formatDateUnix(b.original.lastClosedShiftDate) - formatDateUnix(a.original.lastClosedShiftDate)
            }),
            columnHelper.accessor('transactionType', {
                header: "Тип последней транзакции"
            }),
            columnHelper.accessor('currentShiftId', {
                header: "Номер текущей смены"
            }),
            columnHelper.accessor('isShiftClosed', {
                header: "Состояние закрытия смены",
                cell: ({getValue}) => getValue() ? 'Да' : 'Нет'
            }),


        ], [])


        const tableInstance = useReactTable({
            columns,
            state: {
                sorting
            },
            onSortingChange: setSorting,
            data: getSnapshot(KkmStore.lastShift),
            getCoreRowModel: getCoreRowModel(),
            getSortedRowModel: getSortedRowModel()
        })

        return (
            <>
                <Card className='mb-12 pb-21'>
                    <div className={classNames('pt-30 pl-27 pr-26')}>
                        <BreadCrumb>
                            <BreadCrumbItem to='/taxpayers'>Налогоплательщики</BreadCrumbItem>
                            <BreadCrumbItem
                                to={`/taxpayers/${KkmStore.breadCrumbs?.id}`}>{KkmStore.breadCrumbs?.title}</BreadCrumbItem>
                            <BreadCrumbItem to={`/kkm/${id}`}>ККМ</BreadCrumbItem>
                            <BreadCrumbItem active to={location.pathname}>Последняя смена</BreadCrumbItem>

                        </BreadCrumb>
                        <PageTitle className={classNames('mb-24')}>
                            Последняя смена
                        </PageTitle>
                    </div>
                    <Scrollbars autoHeight>
                        <TableView className={classNames(Styles.table, "table-left")} tableInstance={{...tableInstance}}/>
                    </Scrollbars>
                </Card>
            </>
        )
    }
)
