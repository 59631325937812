import React, {FC, useEffect, useRef, useState} from 'react'
import {Input} from '@/shared/ui/Inputs'
import {Modal, ModalHeader} from '@/shared/ui/Modal'
import {NewUserIcon} from '@/assets/icons/NewUserIcon'
import {Button} from '@/shared/ui/Buttons'
import {ModalSuccess} from '@/shared/ui/Modal'
import {Accordion, AccordionDetails, AccordionSummary, FormControlLabel} from '@mui/material'
import Scrollbars from 'react-custom-scrollbars-2'
import {Checkbox} from '@/shared/ui/Checkbox'
import {ExpandIcon} from '@/assets/icons/ExpandIcon'
import classNames from 'classnames'
import {ExpandAllIcon} from '@/assets/icons/ExpandAllIcon'
import Styles from './CardRoleModal.module.scss'
import {AccordionChildItem, AccordionRootItem, IRoleModalProps, TypeModal} from './types'
import {useStylesAccordion} from './stylesAccordion/useStylesAccordion'
import theme from '@/shared/themes'
import {RolesStore} from '@/store/RolesStroe'
import {PermissionsStore} from '@/store/PermissionsStore'
import {useForm} from 'react-hook-form'
import {rootStore} from '@/store/rootStore'
import {observer} from 'mobx-react-lite'
import axios from "axios";
import {IErrorMessage} from "@/shared/api/instanceAxios";
import {toast} from "react-toastify";


const rootIsChecked = (root: AccordionRootItem): boolean => {
    for (let item of root.permissions) {
        if (!item.checked) return false
    }
    return true
}

const isCheckedChild = (item: any): boolean | undefined => {
    for (let child of item.permissions) {
        if (child.checked) return true
    }
}


export const CardRoleModal: FC<IRoleModalProps> = observer(({onClose, type, id, fetchData}) => {


    const {
        register,
        handleSubmit,
        setValue,
        formState: {
            errors
        }
    } = useForm({
        mode: 'onBlur'
    })

    const [rootItems, setrootItems] = useState<AccordionRootItem[]>([])
    const codeInputRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
        if (id) {
            const getPermissionsList = (section: AccordionRootItem) => {
                const result: any[] = []
                if (type === TypeModal.VIEW) {
                    RolesStore.currentRole.permissions?.forEach((item: any) => {
                        section.permissions.forEach(per => {
                            if (per.id === item.id) {
                                result.push(per)
                            }
                        })
                    });
                } else if (type === TypeModal.EDIT) {
                    return section.permissions.map(item => {
                        return {
                            ...item,
                            checked: Boolean(RolesStore.currentRole.permissions.find((per: any) => per.id === item.id)),
                        }
                    })

                }
                return result
            }

            RolesStore.fetchById(id).then(() => {
                const r = [...PermissionsStore.sections?.map((item: AccordionRootItem) => {
                    return {
                        ...item,
                        expanded: false,
                        permissions: getPermissionsList(item)
                    }
                })].filter(section => {
                    if (type !== TypeModal.CREATE) {
                        return section.permissions.length > 0
                    } else {
                        return true
                    }
                })
                setValue("name", RolesStore.role(id).name)
                setrootItems(r)
            })
        } else {
            const r = [...PermissionsStore.sections?.map((item: AccordionRootItem) => {
                return {
                    ...item,
                    expanded: false,
                    permissions: item.permissions.map(per => {
                        return {
                            ...per,
                            checked: false,
                        }
                    })
                }
            })]
            setrootItems(r)
        }
    }, [type, id])


    useEffect(() => {
        if (codeInputRef.current) {
            codeInputRef.current.value = RolesStore.currentRole?.code || ''
        }
    }, [RolesStore.currentRole, codeInputRef])


    const [successAddRole, setSuccesAddRole] = useState<boolean>(false)
    const [expandedAllControl, setExpandedAllControl] = useState<boolean>(false)

    const handleExpandAccordion = (id: number): void => {
        setrootItems(rootItems.map(item => {
            const data = {...item}
            if (item.id === id) {
                data.expanded = !data.expanded
            }
            return data
        }))
    }

    const handleAccordionChangeRoot = (id: number): void => {
        const newRootItems = rootItems.map(rootItems => {
            let data = {...rootItems}
            if (rootItems.id === id) {
                data.permissions = data.permissions.map((child: AccordionChildItem) => ({
                    ...child,
                    checked: !rootIsChecked(data)
                }))
            }
            return data
        })
        setrootItems(newRootItems)
    }

    const handleAccordionChangeChild = (rootId: number, childId: number): void => {
        const changeRelatedPer = (id: number, array: AccordionRootItem[]) => {
            array.forEach(root => {
                root.permissions.forEach(child => {
                    if (child.relatedPermissions.find(relatedPer => relatedPer === id)) child.checked = false
                })
            })
        }

        const NewRootItems = rootItems.map((rootItems, index, array) => {
            const data = {...rootItems}
            data.permissions.forEach((child) => {
                if (data.id === rootId && child.id === childId) {
                    child.checked = !child.checked
                    if (child.relatedPermissions.length && child.checked) {
                        array.forEach(item => {
                            item.permissions.forEach(_item => {
                                if (child.relatedPermissions.find(relatedPer => relatedPer === _item.id)) {
                                    _item.checked = child.checked
                                }
                            })
                        })
                    } else if (!child.checked) {
                        changeRelatedPer(child.id, array)
                    }
                }
            })
            return data
        })
        setrootItems(NewRootItems)
    }

    const expandAll = (): void => {
        setExpandedAllControl(!expandedAllControl)
        if (rootItems.length) {
            const data = rootItems.map(rootItems => ({...rootItems, expanded: !expandedAllControl}))
            setrootItems(data)
        }
    }

    const isExpandedAll = (): boolean => {
        for (let item of rootItems) {
            if (!item.expanded) return false
        }
        return true
    }

    const classesAccordion = useStylesAccordion()


    const stylesAccardionSummary = (item: AccordionRootItem) => {
        return {
            color: isCheckedChild(item) && type !== TypeModal.VIEW ? theme.mainPressColor : theme.firstColor,
            backgroundColor: isCheckedChild(item) && type !== TypeModal.VIEW ? theme.bgColor : ''
        }

    }

    useEffect(() => {
        if (isExpandedAll() && !expandedAllControl) {
            expandAll()
        } else if (!isExpandedAll() && expandedAllControl) {
            setExpandedAllControl(false)
        }
    }, [rootItems])


    const onSubmit = async (data: any) => {
        try {
            const permissions: AccordionChildItem[] = []
            rootItems.forEach(item => {
                permissions.push(...item.permissions.filter(per => per.checked))
            })
            const obj = {
                name: data.name,
                code: data.code || codeInputRef.current?.value,
                description: "",
                basic: false,
                author: {
                    id: rootStore.me.id,
                    firstName: rootStore.me.firstName,
                    lastName: rootStore.me.lastName,
                    phone: rootStore.me.phone,
                    email: rootStore.me.email,
                    blocked: rootStore.me.bloced,
                },
                permissions,


            }
            if (id) {
                await RolesStore.editRole(id, obj)
            } else {
                await RolesStore.createRole(obj)
            }
            fetchData()
            setSuccesAddRole(true)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const response = await e.response?.data as IErrorMessage
                toast.error(response?.details?.join(" "), {
                    position: 'bottom-right'
                })
            }
        }

    }

    return (
        <>
            {
                successAddRole ?
                    <ModalSuccess className='pt-8 pb-28' onClose={onClose}>
                        {
                            id ? 'Роль отредактирована' : 'Роль добавлена!'
                        }
                    </ModalSuccess>
                    :
                    <Modal onClose={onClose}>
                        <ModalHeader title={type} onClose={onClose}>
                            <NewUserIcon/>
                        </ModalHeader>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Scrollbars style={{height: 782}} autoHide={false} className={Styles.scrollBar}>
                                <div className="modal-form">
                                    <label className='label'>
                                        <span>Автор</span>
                                        <Input color='disabled' disabled className='--fluid'/>
                                    </label>
                                    <label className='label'>
                                        <span>Название роли{type !== TypeModal.VIEW &&
                                            <span className='required'>*</span>}</span>
                                        <Input
                                            className='--fluid'
                                            {...register("name", {required: "Заполните это поле!"})}
                                            error={errors.name?.message as string}
                                            placeholder='Введите новое название роли'/>
                                    </label>
                                    <label className='label'>
                                        <span>Код роли{type === TypeModal.CREATE &&
                                            <span className='required'>*</span>}</span>
                                        {
                                            id ? <Input
                                                    disabled
                                                    color={'disabled'}
                                                    className='--fluid'
                                                    ref={codeInputRef}
                                                    placeholder='Введите код роли'/>
                                                :
                                                <Input
                                                    disabled={type !== TypeModal.CREATE}
                                                    color={type !== TypeModal.CREATE ? 'disabled' : undefined}
                                                    className='--fluid'
                                                    error={errors.code?.message as string}
                                                    {...register("code", {required: "Заполните это поле!"})}
                                                    placeholder='Введите код роли'/>
                                        }

                                    </label>
                                    <div
                                        className={classNames("flex a-center j-end mb-10", Styles.expandedAll, expandedAllControl ? Styles.expanded : "")}>
                                        <div onClick={() => expandAll()} className="flex-item flex a-center pointer">
                                            <div
                                                className={classNames("icon-wrapper mr-6 flex a-center", expandedAllControl ? Styles.expandedIcon : "")}>
                                                <ExpandAllIcon/>
                                            </div>
                                            <span className='fs-13'>
                                                Развернуть все
                                            </span>
                                        </div>
                                    </div>
                                    {
                                        rootItems.map(item =>
                                            <Accordion
                                                key={item.id}
                                                expanded={item.expanded}
                                                onChange={() => handleExpandAccordion(item.id)}
                                                classes={{
                                                    root: classesAccordion.MuiAccordionroot,
                                                    expanded: classesAccordion.MuiAccordionExpanded
                                                }}
                                            >
                                                <AccordionSummary
                                                    style={stylesAccardionSummary(item)}
                                                    classes={{
                                                        root: classesAccordion.MuiAccordionSummaryRoot,
                                                        content: classesAccordion.MuiAccordionSummaryContent
                                                    }}
                                                    expandIcon={<ExpandIcon/>}
                                                    aria-label="Expand"
                                                    aria-controls="additional-actions1-content"
                                                    id="additional-actions1-header"
                                                >
                                                    <FormControlLabel
                                                        aria-label="Acknowledge"
                                                        onClick={(event) => event.stopPropagation()}
                                                        onFocus={(event) => event.stopPropagation()}
                                                        control={
                                                            <>
                                                                {
                                                                    type !== TypeModal.VIEW &&
                                                                    <Checkbox
                                                                        onChange={() => handleAccordionChangeRoot(item.id)}
                                                                        className={classNames('mr-10')}
                                                                        checked={rootIsChecked(item)}/>
                                                                }
                                                            </>

                                                        }
                                                        label={`${item.nameRu} ${type !== TypeModal.VIEW ? `(${item.permissions.length})` : ''}`}
                                                    />
                                                </AccordionSummary>
                                                <AccordionDetails
                                                    classes={{
                                                        root: classesAccordion.MuiAccordionDetailsRoot,
                                                    }}
                                                >
                                                    <ul>
                                                        {
                                                            item.permissions.map(child =>
                                                                <li className={classNames('mb-22')} key={child.id}>
                                                                    <label className='flex a-center pointer'>
                                                                        {
                                                                            type !== TypeModal.VIEW &&
                                                                            <Checkbox className='mr-10 flex a-center'
                                                                                      checked={child.checked}
                                                                                      onChange={() => handleAccordionChangeChild(item.id, child.id)}/>
                                                                        }
                                                                        <span>
                                                                            {child.description}
                                                                        </span>
                                                                    </label>
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                    }

                                </div>
                            </Scrollbars>
                            <div className="modal-form">
                                <div className="flex j-end mt-40">
                                    <Button color='cancel' className='mr-18 button' onClick={onClose}>
                                        {
                                            type === TypeModal.VIEW ?
                                                'Закрыть' :
                                                'Отмена'
                                        }
                                    </Button>
                                    {
                                        type !== TypeModal.VIEW &&
                                        <Button type='submit' className='button'>
                                            {
                                                type === TypeModal.CREATE ?
                                                    "Создать"
                                                    :
                                                    "Сохранить"

                                            }
                                        </Button>
                                    }
                                </div>
                            </div>
                        </form>
                    </Modal>
            }
        </>

    )
})
