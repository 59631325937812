import theme from '@/shared/themes'
import React from 'react'

export const ExpandIcon = () => {
    return (
        <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.71136 0H3.9926H0.643931C0.0708961 0 -0.215621 0.704706 0.190278 1.11781L3.28228 4.26469C3.77771 4.76891 4.58354 4.76891 5.07898 4.26469L6.25489 3.0679L8.17098 1.11781C8.57091 0.704706 8.28439 0 7.71136 0Z" fill={theme.firstColor} />
        </svg>
    )
}


