import React, {useEffect} from 'react'
import {PageTitle} from '@/shared/ui/PageTitle'
import {BreadCrumb, BreadCrumbItem} from '@/shared/ui/BreadCumb'
import {Card} from '@/shared/ui/Card'
import {Dictionary} from '@/shared/ui/Dictionary'
import Styles from './KkmCheckDetailPage.module.scss'
import classNames from 'classnames'
import {useLocation, useParams} from 'react-router-dom'
import {KkmStore} from "@/entities/Kkm";
import {observer} from "mobx-react-lite";
import moment from "moment";


const PAYMENTS_TYPE = {
    "PAYMENT_CASH": "Получено наличными",
    "PAYMENT_CARD": "Получено безналичными"
}

export const KkmCheckDetailPage = observer(() => {
        const location = useLocation()
        const {kkmId, transactionDate, transactionId} = useParams()

        useEffect(() => {
            KkmStore.fetchBreadCrumbs(kkmId as string)
            KkmStore.fetchTicket(transactionId as string, transactionDate as string)
        }, [kkmId, transactionDate, transactionId])
        const Ticket = KkmStore.ticket
        const getDate = () => {
            const date = Ticket?.requestBody.ticket.dateTime
            if (date) {
                const {year, day, month} = date.date
                const {hour, minute, second} = date.time
                const r = new Date()
                r.setHours(hour)
                r.setFullYear(year, month - 1, day)
                r.setMinutes(minute)
                r.setSeconds(second)
                return moment(r).format("DD.MM.YYYY HH:mm:ss")

            }
            return ''
        }
        const taxes = Ticket?.requestBody.ticket.taxes.find((item: any) => item.taxType === "VAT" && item.isInTotalSum)
        const data = [
            {
                key: 'ИИН/БИН',
                value: Ticket?.requestBody.service.regInfo.org.inn || ""
            },
            {
                value: Ticket?.requestBody.service.regInfo.org.address || ""
            },
            {
                key: 'ЗНМ',
                value: Ticket?.requestBody.service.regInfo.kkm.serialNumber || ""
            },
            {
                key: "ID ККМ",
                value: Ticket?.requestBody.service.regInfo.kkm.kkmId
            },
            {
                key: "КАССИР",
                value: Ticket?.requestBody.ticket.operator.name,
            }
        ]

        const dataFix = [
            {
                value: ''
            },
            {
                key: 'Фискальный признак',
                value: `${Ticket?.responseBody.ticket.ticketNumber}`,
            },
            {
                key: "Аварийный",
                value: `${Ticket?.requestBody.ticket?.offlineTicketNumber}`,
                isNotVisible: !Ticket?.requestBody.ticket?.offlineTicketNumber,
            },
            {
                key: 'Код ККМ КГД',
                value: Ticket?.requestBody.service.regInfo.kkm.fnsKkmId
            },
        ]
        return (
            <>
                <Card className='pt-30 pb-34 px-27 mb-14'>
                    <BreadCrumb>
                        <BreadCrumbItem to='/taxpayers'>Налогоплательщики</BreadCrumbItem>
                        <BreadCrumbItem
                            to={`/taxpayers/${KkmStore.breadCrumbs?.id}`}>{KkmStore.breadCrumbs?.title}</BreadCrumbItem>
                        <BreadCrumbItem to={`/kkm/${kkmId}`}>ККМ</BreadCrumbItem>
                        <BreadCrumbItem active to={location.pathname}>Просмотр чека</BreadCrumbItem>
                    </BreadCrumb>
                    <PageTitle className='mb-34'>
                        Просмотр чека
                    </PageTitle>
                    <div className='c_first_color fw-500 mb-9'>
                        {Ticket?.requestBody.service.regInfo.org.title || ""}
                    </div>
                    <Dictionary data={data} className="mb-33"/>
                    <div className='c_first_color fw-500'>
                        {getDate()}
                    </div>
                </Card>
                <Card className='pt-30 c_first_color mb-17'>
                    <div className='pl-27 fs-18 fw-500 mb-30'>
                        Продажа
                    </div>
                    <ul>
                        {
                            Ticket?.requestBody.ticket.items.map((p: any, index: number) =>
                                <li key={p.commodity.name} className={classNames(Styles.rowProduct, "py-9")}>
                                    <div className='title'>
                                        {index + 1}. {p.commodity.name}
                                    </div>
                                    <div className="flex">
                                        <div className='mr-10'>{Number(p.commodity.quantity) / 1000}</div>
                                        <div className='mr-15'>шт.</div>
                                        <div className='mr-10'>x</div>
                                        <div className='mr-10'>{p.commodity.price.bills}.{p.commodity.price.coins}</div>
                                        <div className='mr-10'>=</div>
                                        <div>{p.commodity.sum.bills}.{p.commodity.sum.coins}₸</div>
                                    </div>
                                </li>
                            )
                        }
                        <li className={classNames(Styles.row, "py-8 fw-700 fs-20")}>
                            <div>
                                ИТОГО
                            </div>
                            <div className='flex'>
                                <div className='mr-15'>
                                    =
                                </div>
                                <div>
                                    {Ticket?.requestBody.ticket.amounts.total.bills}.{Ticket?.requestBody.ticket.amounts.total.coins}₸
                                </div>
                            </div>
                        </li>
                        {
                            Ticket?.requestBody.ticket.payments.map((item: any) =>
                                <li key={item.type} className={classNames(Styles.row, "py-11")}>
                                    <div>
                                        {PAYMENTS_TYPE[item.type as keyof typeof PAYMENTS_TYPE]}
                                    </div>
                                    <div className='flex'>
                                        <div className='mr-15'>
                                            =
                                        </div>
                                        <div>
                                            {item.sum.bills}.{item.sum.coins}₸
                                        </div>
                                    </div>
                                </li>
                            )
                        }
                        {
                            Ticket?.requestBody.ticket.amounts.change &&
                            <li className={classNames(Styles.row, "py-11")}>
                                <div>
                                    Сдача:
                                </div>
                                <div className='flex'>
                                    <div className='mr-15'>
                                        =
                                    </div>
                                    <div>
                                        {Ticket?.requestBody.ticket.amounts.change.bills}.{Ticket?.requestBody.ticket.amounts.change.coins}₸
                                    </div>
                                </div>
                            </li>
                        }
                        {
                            taxes &&
                            <li className={classNames(Styles.row, "py-11")}>
                                <div>
                                    Налог:
                                </div>
                                <div className='flex'>
                                    <div
                                        className="mr-15">{Ticket?.requestBody.ticket.amounts.total.bills}.{Ticket?.requestBody.ticket.amounts.total.coins}₸
                                    </div>
                                    <div className="mr-15">
                                        {(taxes.percent).toLocaleString("DE-de")}%
                                    </div>
                                    <div className='mr-15'>
                                        =
                                    </div>
                                    <div>
                                        {taxes.sum.bills}.{taxes.sum.coins}₸
                                    </div>
                                </div>
                            </li>
                        }
                        {
                            Ticket?.requestBody.ticket.printed_document_number &&
                            <li className={classNames(Styles.rowProduct, "py-9")}>
                                <div className='pr-119 flex j-between grow'>
                                    <div>
                                        printedDocumentNumber
                                    </div>
                                    <div className='mr-119'>
                                        {Ticket.requestBody.ticket.printed_document_number}
                                    </div>
                                </div>
                            </li>
                        }
                        {
                            Ticket?.requestBody.ticket.printed_ticket &&
                            <li className={classNames(Styles.rowProduct, "py-9")}>
                                <div className='pr-119 flex j-between grow'>
                                    <div>
                                        printedTicket
                                    </div>
                                    <div className='mr-119'>
                                        {Ticket?.requestBody.ticket.printed_ticket}
                                    </div>
                                </div>
                            </li>
                        }
                    </ul>
                </Card>
                <Card className='pt-30 pb-33 px-27'>
                    <div className='c_first_color fs-18 fw-500 mb-34'>
                        Фискальный чек
                    </div>
                    <Dictionary data={dataFix}/>
                </Card>
            </>
        )
    }
)
