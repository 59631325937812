import theme from '@/shared/themes'
import React from 'react'

export const UnlockIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.25 4.96875V4.96875C5.25 3.05301 6.80301 1.5 8.71875 1.5H9C11.0711 1.5 12.75 3.17893 12.75 5.25V8.25" stroke={theme.doneColor} strokeWidth="2" strokeLinecap="round" />
            <rect x="3.75" y="8.25" width="10.5" height="7.5" rx="1" stroke={theme.doneColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}


