import { forwardRef } from 'react'
import { InputProps } from '@/shared/types/InputPropsType'
import Styles from './Input.module.scss'
import classNames from 'classnames'
import { SearchIcon } from '@/assets/icons/SearchIcon.'


export const InputSearchIcon = forwardRef<HTMLInputElement, InputProps>(({ className, value, placeholder, onChange, children, id }, ref) => {
    return (
        <div className={classNames(Styles.searchWrap, typeof (className) === 'string' ? className : className?.wrapper)}>
            <div className={classNames(Styles.iconContainer)}>
                <SearchIcon />
            </div>
            <input ref={ref} type="text" id={id} className={classNames(Styles.inputIcon, Styles.inputSearchIcon, typeof (className) !== 'string' ? className?.input : '')} placeholder={placeholder} onChange={onChange} value={value} />
            <label className={classNames(Styles.searchIconWrapper)} htmlFor={id} />
            <div className={classNames(Styles.buttonContainer)}>
                {children}
            </div>
        </div>

    )
})
