import React from 'react'

export const UsersOrganizationIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22.7498C11.3 22.7498 10.59 22.4798 10.06 21.9498L8.34998 20.2598C7.92998 19.8398 7.35001 19.6097 6.76001 19.6097H6C3.93 19.6097 2.25 17.9398 2.25 15.8898V4.97974C2.25 2.92974 3.93 1.25977 6 1.25977H18C20.07 1.25977 21.75 2.92974 21.75 4.97974V15.8898C21.75 17.9398 20.07 19.6097 18 19.6097H17.24C16.65 19.6097 16.07 19.8498 15.65 20.2598L13.94 21.9498C13.41 22.4798 12.7 22.7498 12 22.7498ZM6 2.74976C4.76 2.74976 3.75 3.74973 3.75 4.96973V15.8798C3.75 17.1098 4.76 18.0997 6 18.0997H6.76001C7.75001 18.0997 8.70997 18.4998 9.40997 19.1898L11.12 20.8798C11.61 21.3598 12.4 21.3598 12.89 20.8798L14.6 19.1898C15.3 18.4998 16.26 18.0997 17.25 18.0997H18C19.24 18.0997 20.25 17.0998 20.25 15.8798V4.96973C20.25 3.73973 19.24 2.74976 18 2.74976H6Z" fill="white" />
            <path d="M11.9999 10.7498C10.2999 10.7498 8.91992 9.3698 8.91992 7.6698C8.91992 5.9698 10.2999 4.58984 11.9999 4.58984C13.6999 4.58984 15.08 5.9698 15.08 7.6698C15.08 9.3698 13.6999 10.7498 11.9999 10.7498ZM11.9999 6.08984C11.1299 6.08984 10.4199 6.7998 10.4199 7.6698C10.4199 8.5398 11.1299 9.24982 11.9999 9.24982C12.8699 9.24982 13.58 8.5398 13.58 7.6698C13.58 6.7998 12.8699 6.08984 11.9999 6.08984Z" fill="white" />
            <path d="M16 16.4103C15.59 16.4103 15.25 16.0703 15.25 15.6603C15.25 14.2803 13.79 13.1504 12 13.1504C10.21 13.1504 8.75 14.2803 8.75 15.6603C8.75 16.0703 8.41 16.4103 8 16.4103C7.59 16.4103 7.25 16.0703 7.25 15.6603C7.25 13.4503 9.38 11.6504 12 11.6504C14.62 11.6504 16.75 13.4503 16.75 15.6603C16.75 16.0703 16.41 16.4103 16 16.4103Z" fill="white" />
        </svg>

    )
}

