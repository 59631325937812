import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import { BreadCrumb, BreadCrumbItem } from '@/shared/ui/BreadCumb'
import { Card } from '@/shared/ui/Card/Card'
import classNames from 'classnames'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { PageTitle } from '@/shared/ui/PageTitle'
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from '@tanstack/react-table'
import { TableView } from '@/shared/ui/Tables/TableView'
import Styles from './TaxpayerUsersOrganizationPage.module.scss'
import { UsersOrgStore } from '@/store/UsersOrgStore'
import { Paginate } from '@/shared/ui/Paginate'
import { observer } from 'mobx-react-lite'
import { getSnapshot } from 'mobx-state-tree'
import { TaxpayersStore } from '@/entities/Taxpayer/model/TaxpayesStore'
import { Preload } from '@/shared/ui/Preload'


interface IUserOrganization {
    name: string;
    login: string;
    phone: string;
    blockedAt: string;
}

const columnHelper = createColumnHelper<IUserOrganization>()


export const TaxpayerUsersOrganizationPage: FC = observer(() => {
    const { id } = useParams()
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const page = searchParams.get('page')
    const size = searchParams.get('size')
    const [sorting, setSorting] = useState<SortingState>([])
    /** **/
    const fetchData = useCallback(() =>
        UsersOrgStore.fetchAll({ page, size, orgId: id }),
        [size, page, id])

    useEffect(() => {
        TaxpayersStore.fetchById(id as string)
    }, [id])

    useEffect(() => {
        fetchData()
    }, [size, page, fetchData])

    const onPaginate = (event: React.ChangeEvent<unknown>, page: Number) => {
        setSearchParams({
            page: page.toString(),
        })
    }

    const onChangeSelectSize = (size: number) => {
        setSearchParams({
            size: size.toString()
        })
    }

    const columns = useMemo(() => [
        columnHelper.accessor('name', {
            header: 'ФИО'
        }),
        columnHelper.accessor('login', {
            header: 'Логин'
        }),
        columnHelper.accessor('phone', {
            header: 'Телефон'
        }),
        columnHelper.accessor('blockedAt', {
            header: 'Статус блокировки',
            cell: ({ getValue }) => getValue() ? <span className='c_done_color'>Активен</span> : <span>Деактивен</span>
        })
    ], [])

    const tableInstance = useReactTable({
        data: getSnapshot(UsersOrgStore.organisationUsersList),
        columns,
        state:{
          sorting
        },
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        getCoreRowModel: getCoreRowModel(),
    })

    return (
        <>
            <Card>
                <div className={classNames('pt-30 pl-27 pr-26')}>
                    <BreadCrumb>
                        <BreadCrumbItem to='/taxpayers'>Налогоплательщики</BreadCrumbItem>
                        <BreadCrumbItem to={`/taxpayers/${id}`}>{TaxpayersStore.currentTaxpayer.title}</BreadCrumbItem>
                        <BreadCrumbItem active to={location.pathname} >Пользователи организации</BreadCrumbItem>

                    </BreadCrumb>
                    <PageTitle className='mb-30'>
                        Пользователи организации
                    </PageTitle>
                    <div className="c_first_color fs-12 mb-30">
                        Всего найдено: {UsersOrgStore.organisationUsersList.length}
                    </div>
                </div>
                <TableView className={classNames('table-left', Styles.table)} tableInstance={{ ...tableInstance }} />

            </Card>
            {
                UsersOrgStore.organisationUsersList.length ?
                    <Paginate
                        onChange={onPaginate}
                        currentPage={UsersOrgStore.currentPage}
                        countPages={UsersOrgStore.totalPages}
                        size={size}
                        onChangeSelectSize={onChangeSelectSize}
                    /> : ''
            }
            {
                UsersOrgStore.loading && <Preload />
            }
        </>

    )
}
)
