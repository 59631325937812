import theme from '@/shared/themes';
import React, { FC, useState } from 'react'

interface IEditIcon {
    color?: string;
}

export const EditIcon: FC<IEditIcon> = ({ color }) => {
    const defaultColor = theme.firstColor
    const [currentColor] = useState<string>(color || defaultColor)
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.69332 13.0133C3.28666 13.0133 2.90666 12.8733 2.63332 12.6133C2.28666 12.2867 2.11999 11.7933 2.17999 11.26L2.42666 9.09999C2.47332 8.69333 2.71999 8.15333 3.00666 7.85999L8.47999 2.06666C9.84666 0.619992 11.2733 0.579992 12.72 1.94666C14.1667 3.31333 14.2067 4.73999 12.84 6.18666L7.36666 11.98C7.08666 12.28 6.56666 12.56 6.15999 12.6267L4.01332 12.9933C3.89999 13 3.79999 13.0133 3.69332 13.0133ZM10.62 1.93999C10.1067 1.93999 9.65999 2.25999 9.20666 2.73999L3.73332 8.53999C3.59999 8.67999 3.44666 9.01333 3.41999 9.20666L3.17332 11.3667C3.14666 11.5867 3.19999 11.7667 3.31999 11.88C3.43999 11.9933 3.61999 12.0333 3.83999 12L5.98666 11.6333C6.17999 11.6 6.49999 11.4267 6.63332 11.2867L12.1067 5.49333C12.9333 4.61333 13.2333 3.79999 12.0267 2.66666C11.4933 2.15333 11.0333 1.93999 10.62 1.93999Z" fill={currentColor} />
            <path d="M11.56 7.29998C11.5467 7.29998 11.5267 7.29998 11.5134 7.29998C9.43336 7.09331 7.76003 5.51331 7.44003 3.44665C7.40003 3.17331 7.58669 2.91998 7.86003 2.87331C8.13336 2.83331 8.38669 3.01998 8.43336 3.29331C8.68669 4.90665 9.99336 6.14665 11.62 6.30665C11.8934 6.33331 12.0934 6.57998 12.0667 6.85331C12.0334 7.10665 11.8134 7.29998 11.56 7.29998Z" fill={currentColor} />
            <path d="M14 15.1667H2C1.72667 15.1667 1.5 14.94 1.5 14.6667C1.5 14.3934 1.72667 14.1667 2 14.1667H14C14.2733 14.1667 14.5 14.3934 14.5 14.6667C14.5 14.94 14.2733 15.1667 14 15.1667Z" fill={color || theme.firstColor} />
        </svg>
    )
}


