import React, { FC } from 'react'
import classNames from 'classnames'
import Styles from '../Info.module.scss'
import { Dictionary } from '@/shared/ui/Dictionary'
import { observer } from 'mobx-react-lite';

interface IData {
    key: string;
    value: string;
    isFirst?: boolean;
}
interface IKkmInfoProps {
    data: IData[];
}
export const KkmInfo: FC<IKkmInfoProps> = observer(({ data }) => {

    return (
        <div className={classNames("flex j-between wrap", Styles.fieldsRow)}>
            <div className="flex-item">
                <Dictionary data={data.slice(0, 3)} />
            </div>
            <div className="flex-item">
                <Dictionary data={data.slice(3, 6)} />
            </div>

        </div>
    )
}
)
