import {instance} from './instanceAxios';

export const kkmServices = {
    getKkmList: async (data?: any, params?: any) => {
        const response = await instance.post('/kkm/list', data, {
            params
        })
        return response.data
    },
    getKkmById: async (id: string) => {
        const response = await instance.get(`/kkm/get-kkm/id/${id}`)
        return response.data
    },
    getOperationList: async (kkm_id: number, shift_number: number, params: any) => {
        const response = await instance.get(`/kkm/operationList/${kkm_id}`, {
            params: {
                ...params,
                shift_number,
            }
        })
        return response.data
    },
    getLastShift: async (kkmId: number) => {
        const response = await instance.get('/kkm/getLastTransaction', {
            params: {
                kkmId,
            }
        })
        return response.data
    },
    getKkmChangeList: async (params: any) => {
        const response = await instance.get("/history/kkm/list", {
            params
        })
        return response.data
    },
    getBillingHistoryList: async (kkmId: number) => {
        const response = await instance.get("/history/kkm/billing-status/list", {
            params: {
                kkmId,
            }
        })
        return response.data
    },
    getKkmInisHistoryList: async (params: any) => {
        const response = await instance.get('/kkm/getInisHistoryList', {
            params
        })
        return response.data
    },
    getShiftList: async (kkm_id: number, params: any) => {
        const response = await instance.get('/kkm/shiftList', {
            params: {
                kkm_id,
                ...params
            }
        })
        return response.data
    },
    getTransactionById: async (transaction_id: string, transaction_date: string) => {
        const response = await instance.get(`/kkm/search-transaction/${transaction_id}/${transaction_date}`)
        return response.data
    },
    changeKkm: async (id: number, kgdId: string, manufactureYear: number, serialNum: string, model: string) => {
        const response = await instance.put('/kkm/update', {
            id,
            kgdId,
            manufactureYear,
            serialNum,
            model
        })
        return response.data
    },
    getRegistrationStatusHistory: async (kkmId: number) => {
        const response = await instance.get('/history/kkm/registration-status/list', {
            params: {
                kkmId
            }
        })
        return response.data
    },
    getTicket: async (transaction_id: string, transaction_date: string) => {
        const response = await instance.get(`/kkm/search-ticket/${transaction_id}/${transaction_date}`)
        return response.data
    },
    blockUnblockKkm: async ({
                                inactiveReasonText, kkmId, isActive
                            }: { inactiveReasonText: string, kkmId: number, isActive: boolean }) => {
        const response = await instance.post("/kkm/enable_disable", {
            inactiveReasonText,
            kkmId,
            isActive,
        })
        return response.data
    },
    getStateKkm: async (kkmId: number) => {
        const response = await instance.get("/kkm/getKkmState", {
            params: {
                kkmId
            }
        })
        return response.data
    },
    changeRegistrationStatusKkm: async (kkmId: number, statusId: number) => {
        const response = await instance.put('/kkm/registration-status', {}, {
            params: {
                kkmId,
                statusId,
            }
        })
        return response.data
    },
    blockUnblockTrafficKkm: async (data: {
        kkmId: number,
        isActive: boolean
    }) => {
        const response = await instance.post('/kkm/enable_disable_traffic', data)
        return response.data
    },
    changeBillingStatusKkm: async (kkmId: number, statusId: number) => {
        const response = await instance.put('/kkm/billing-status', {}, {
            params: {
                kkmId,
                statusId
            }
        })
        return response.data
    },
    setValidationMask: async (kkm_id: number, mask: number) => instance.get(`/kkm/set-validation-mask/${kkm_id}/${mask}`).then(response => response.data)
}
