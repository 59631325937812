import {Card} from '@/shared/ui/Card'
import React, {useMemo, useState} from 'react'
import classNames from 'classnames'
import {useLocation, useParams} from 'react-router-dom'
import {BreadCrumb, BreadCrumbItem} from '@/shared/ui/BreadCumb'
import {PageTitle} from '@/shared/ui/PageTitle'
import {IStatusQuery} from './KkmHistoryEditsPage'
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from '@tanstack/react-table'
import {TableView} from '@/shared/ui/Tables/TableView'
import Styles from './KkmQueryInisPage.module.scss';
import Scrollbars from 'react-custom-scrollbars-2'
import {formatDateUnix} from "@/shared/lib/formatDate";

interface IQueryInis extends IStatusQuery {
    syncSendData: string;
}


const columnQueryInisHelper = createColumnHelper<IQueryInis>()

export const KkmQueryInisPage = () => {

    const location = useLocation()
    const {id} = useParams()
    const [sorting, setSorting] = useState<SortingState>([])
    const columnsQuery = useMemo(() =>
        [
            columnQueryInisHelper.accessor('id', {
                header: 'ID запроса',
            }),
            columnQueryInisHelper.accessor('guid', {
                header: 'GUID запроса',
            }),
            columnQueryInisHelper.accessor('createdAt', {
                header: 'Дата создания',
                sortingFn:(a, b) => formatDateUnix(b.original.createdAt) - formatDateUnix(a.original.createdAt)
            }),
            columnQueryInisHelper.accessor('status', {
                header: 'Статус',
            }),
            columnQueryInisHelper.accessor('syncSendData', {
                header: 'Синхронная отправка запроса',
            }),
        ], [])


    const tableInstance = useReactTable({
        data: [],
        columns: columnsQuery,
        getCoreRowModel: getCoreRowModel(),
        state: {
            sorting
        },
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
    })

    return (
        <>
            <Card className='mb-12'>
                <div className="py-30 px-27">
                    <BreadCrumb className='mb-30'>
                        <BreadCrumbItem to='/taxpayers'>Налогоплательщики</BreadCrumbItem>
                        <BreadCrumbItem to={location.pathname}>ОФД KZ. Бизнес Администрирование</BreadCrumbItem>
                        <BreadCrumbItem to={location.pathname}>ККМ</BreadCrumbItem>
                        <BreadCrumbItem to={location.pathname}>История изменений</BreadCrumbItem>
                        <BreadCrumbItem to={location.pathname}>Запрос в ИНИС</BreadCrumbItem>
                    </BreadCrumb>
                    <PageTitle className='mt-30'>
                        {`Запрос с ID ${id}  в ИНИС`}
                    </PageTitle>
                </div>
                <Scrollbars style={{width: 1054}} autoHeight>
                    <TableView className={classNames(Styles.table, "table-left")} tableInstance={tableInstance}/>
                </Scrollbars>
            </Card>
            <Card className={classNames('mb-12', Styles.cardCode)}>
                <h3 className='fs-18 py-15 px-27 fw-400 c_first_color'>
                    Данные запроса:
                </h3>
                <div className={Styles.code}>
                    <div className='c_first_color fs-12'>
                        {
                            '<?xml version="1.0" encoding="UTF-8" standalone="yes"?><Doc xmlns:ns2="http://www.w3.org/2000/09/xmldsig#"><ServiceInfo><RequestGUID>4eb065c7-99b7-494f-8259-f85e40509ee4</RequestGUID><OfdId>1</OfdId><RequestDate>2021-11-08T11:00:19.265+06:00</RequestDate><RequestType>APPLY</RequestType><ActionType>USC_INIS_REGKKM</ActionType></ServiceInfo><AcceptanceDate>2021-11-08+06:00</AcceptanceDate><CodeBI>960624350642</CodeBI><KKM><Mark>0x0065000087fa</Mark><SerialNumber>ISCR1026909794906</SerialNumber><MadeYear>2021</MadeYear></KKM><Address><KATO>711210000</KATO><Street>ÐÐ±Ð°Ñ</Street><House>1</House><Flat>1</Flat></Address></Doc>'
                        }
                    </div>
                </div>
            </Card>
            <Card className={classNames('mb-12', Styles.cardCode)}>
                <h3 className='fs-18 py-15 px-27 fw-400 c_first_color'>
                    Данные асинхронного ответа:
                </h3>
                <div className={Styles.code}>
                    <div className='c_first_color fs-12'>
                        {
                            '<?xml version="1.0" encoding="UTF-8" standalone="yes"?><Doc xmlns:ns2="http://www.w3.org/2000/09/xmldsig#"><ServiceInfo><RequestGUID>4eb065c7-99b7-494f-8259-f85e40509ee4</RequestGUID><OfdId>1</OfdId><RequestDate>2021-11-08T11:00:19.265+06:00</RequestDate><RequestType>APPLY</RequestType><ActionType>USC_INIS_REGKKM</ActionType></ServiceInfo><AcceptanceDate>2021-11-08+06:00</AcceptanceDate><CodeBI>960624350642</CodeBI><KKM><Mark>0x0065000087fa</Mark><SerialNumber>ISCR1026909794906</SerialNumber><MadeYear>2021</MadeYear></KKM><Address><KATO>711210000</KATO><Street>ÐÐ±Ð°Ñ</Street><House>1</House><Flat>1</Flat></Address></Doc>'
                        }
                    </div>
                </div>
            </Card>
            <Card className={classNames('mb-12', Styles.cardCode)}>
                <h3 className='fs-18 py-15 px-27 fw-400 c_first_color'>
                    Данные синхронного ответа:
                </h3>
                <div className={Styles.code}>
                    <div className='c_first_color fs-12'>
                        {
                            '<?xml version="1.0" encoding="UTF-8" standalone="yes"?><Doc xmlns:ns2="http://www.w3.org/2000/09/xmldsig#"><ServiceInfo><RequestGUID>4eb065c7-99b7-494f-8259-f85e40509ee4</RequestGUID><OfdId>1</OfdId><RequestDate>2021-11-08T11:00:19.265+06:00</RequestDate><RequestType>APPLY</RequestType><ActionType>USC_INIS_REGKKM</ActionType></ServiceInfo><AcceptanceDate>2021-11-08+06:00</AcceptanceDate><CodeBI>960624350642</CodeBI><KKM><Mark>0x0065000087fa</Mark><SerialNumber>ISCR1026909794906</SerialNumber><MadeYear>2021</MadeYear></KKM><Address><KATO>711210000</KATO><Street>ÐÐ±Ð°Ñ</Street><House>1</House><Flat>1</Flat></Address></Doc>'
                        }
                    </div>
                </div>
            </Card>
        </>

    )
}
