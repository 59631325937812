import classNames from 'classnames';
import React, {FC, useState} from 'react'
import {Card} from '@/shared/ui/Card';
import Styles from './Styles.module.scss'

interface IProps {
    title?: string;
    children?: React.ReactNode;
    className?: {
        title?: string;
        content?: string;
        root?: string;
        card?: string;
    };
}

export const Accordion: FC<IProps> = ({title, children, className}) => {

    const [expand, setExpand] = useState(false)
    const toggle = () => setExpand(!expand)

    return (
        <div className={classNames(Styles.wrapper, className?.root)}>
            <Card className={classNames("mb-2", className?.card)} onClick={toggle}>
                <div className={classNames(Styles.title, className?.title)}>
                    <span>{title}</span>
                    <div className={classNames(Styles.arrow, expand && Styles.arrowRotate)}></div>
                </div>
            </Card>
            <Card className={classNames(Styles.content, className?.card, expand && Styles.contentExpand)}>
                <div className={classNames(className?.content)}>
                    {children}
                </div>
            </Card>
        </div>
    )
}
