import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import cn from "classnames"
import s from './style.module.scss'
import {TariffStore} from "@/entities/Tariff";
import {Controller, useForm} from "react-hook-form";
import {Input, RadioButton} from "@/shared/ui/Inputs";
import {Button} from "@/shared/ui/Buttons";
import {InputError} from "@/shared/ui/Inputs/InputError";
import {Select} from "@/shared/ui/Select";
import classNames from "classnames";
import {useSelect} from "@/shared/hooks";
import {Textarea} from "@/shared/ui/Textarea";
import {observer} from "mobx-react-lite";

const TariffForm = observer(() => {
    const {id} = useParams()
    const Tariff = TariffStore.currentTariff
    const Periods = TariffStore.periods

    const {
        options,
        handleChangeOption,
        getLabelOption,
    } = useSelect([
        {
            id: 0,
            label: "Беспроводное",
            checked: false,
        },
        {
            id: 1,
            label: "Проводное",
            checked: false
        },
        {
            id: 2,
            label: "Посредством АПК",
            checked: false,
        }
    ])

    const periodSelect = useSelect([])
    const periodSelectSetOptions = periodSelect.setOptions
    const handleChangeOptionPeriod = periodSelect.handleChangeOption
    const {
        register,
        control,
        getValues,
        setValue,
        formState: {
            errors
        },
        handleSubmit
    } = useForm({
        mode: "onBlur"
    })

    useEffect(() => {
        if (id) {
            TariffStore.fetchById(Number(id))
        }
    }, [id])

    useEffect(() => {
        const fetchPeriods = async () => {
            await TariffStore.fetchPeriods()
            if (TariffStore.periods) {
                periodSelectSetOptions(TariffStore.periods.map((item, index) => ({
                    id: index,
                    label: item.nameRu,
                    checked: false,
                })))
            }
        }
        if (!Periods) fetchPeriods()
    }, [id, periodSelectSetOptions, Periods])


    useEffect(() => {
        if (Tariff && Periods) {
            setValue("nameRu", Tariff.nameRu)
            setValue("nameKz", Tariff.nameKz)
            setValue("tariffType", Tariff.tariffType)
            setValue("extId", Tariff.extId)
            handleChangeOption(Tariff.connectionType as number)
            handleChangeOptionPeriod(Periods.findIndex(item => item.code === Tariff.periodUnit))
            setValue("fullDescriptionRu", Tariff.fullDescriptionRu)
            setValue("fullDescriptionKz", Tariff.fullDescriptionKz)
            setValue("periodPrice", Tariff.periodPrice)

        }
    }, [Tariff, handleChangeOption, setValue, Periods, handleChangeOptionPeriod])

    const submit = (data: any) => {
    }
    return (
        <div>
            <div className={cn(s.title, "mb-30")}>
                {
                    id ? `Просмотр и редактирование тарифа “${Tariff?.nameRu}”` : "Создать новый тариф"
                }
            </div>
            <form onSubmit={handleSubmit(submit)} className={cn(s.form)}>
                <label className="label fs-14 mb-20 block">
                    <span>Название тарифа RU <span className="required">*</span></span>
                    <Input {...register("nameRu", {
                        required: {
                            value: true,
                            message: 'Это поле обязательно для заполнения'
                        },
                    })} error={errors.nameRu?.message as string} className={cn("--fluid")}
                           placeholder="Введите название тарифа на русском языке"/>
                </label>
                <label className="label fs-14 mb-20 block">
                    <span>Название тарифа KZ <span className="required">*</span></span>
                    <Input {...register("nameKz", {
                        required: {
                            value: true,
                            message: 'Это поле обязательно для заполнения'
                        },
                    })} error={errors.nameKz?.message as string} className={cn("--fluid")}
                           placeholder="Введите название тарифа на казахском языке"/>
                </label>
                <div className="label fs-14 mb-20">
                    <span>Тип тарифа <span className={cn("required")}>*</span></span>
                    <div className="flex">
                        <label className={cn("flex", "mr-79")}>
                            <RadioButton {...register("tariffType", {
                                required: {
                                    value: true,
                                    message: 'Это поле обязательно для заполнения'
                                },
                            })} value={0} name="typeTariff" checked={getValues("tariffType") === 0}/>
                            <span className={cn("ml-8")}>
                                публичный
                            </span>
                        </label>
                        <label className={cn("flex")}>
                            <RadioButton {...register("tariffType")} checked={getValues("tariffType") === 1} value={1}
                                         name="typeTariff"/>
                            <span className={cn("ml-8")}>
                                специальный
                            </span>
                        </label>
                    </div>
                    <InputError error={errors.typeTariff?.message as string}/>
                </div>
                <label className="label fs-14 mb-20 block">
                    <span>Billing ID <span className="required">*</span></span>
                    <Input {...register("extId", {
                        required: {
                            value: true,
                            message: 'Это поле обязательно для заполнения'
                        }
                    })} error={errors.extId?.message as string} className={cn("--fluid")}
                           placeholder="Введите billing ID"/>
                </label>
                <label className="label fs-14 mb-20 block">
                    <span>
                        Тип соединения<span className="required">*</span>
                    </span>
                    <Controller
                        control={control}
                        name="typeConnection"
                        rules={{
                            required: {
                                value: true,
                                message: "Это поле обязательно для заполнения"
                            }
                        }}
                        render={({field, fieldState}) =>
                            <Select
                                contentClassName={s.selectContent}
                                className={s.select}
                                label={getLabelOption()}
                                onBlur={field.onBlur}
                                ref={field.ref}
                                placeholder="Выберите из списка"
                                closeOnSelect={true}
                                error={fieldState.error?.message}
                                clickOutside>
                                {
                                    options.map(item =>
                                        <li key={item.id}
                                            onClick={() => {
                                                field.onChange(item.id)
                                                handleChangeOption(item.id)
                                            }}
                                            className={classNames('option', item.checked && 'active')}>
                                            <label>
                                                {item.label}
                                            </label>
                                        </li>
                                    )
                                }
                            </Select>
                        }
                    />
                </label>
                <label className="label fs-14 mb-20 block">
                    <span>Краткое описание RU <span className="required">*</span></span>
                    <Input {...register("shortDescriptionRu", {
                        required: {
                            value: true,
                            message: "Это поле обязательно для заполнения"
                        }
                    })} className="--fluid" error={errors.shortDescriptionRu?.message as string}
                           placeholder="Введите описание тарифа на русском языке"/>
                </label>
                <label className="label fs-14 mb-20 block">
                    <span>Краткое описание KZ  <span className="required">*</span></span>
                    <Input {...register("shortDescriptionKz", {
                        required: {
                            value: true,
                            message: "Это поле обязательно для заполнения"
                        }
                    })} className="--fluid" error={errors.shortDescriptionRu?.message as string}
                           placeholder="Введите описание тарифа на казахском языке"/>
                </label>
                <label className="label fs-14 mb-20 block">
                    <span>Полное описание RU<span className="required">*</span></span>
                    <Controller
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Это поле обязательно для заполнения"
                            }
                        }}
                        name="fullDescriptionRu"
                        render={({field}) => <Textarea onBlur={field.onBlur} onChange={value => {
                            field.onChange(value)
                        }} className="--fluid" placeholder="Опишите тариф на русском языке"/>}
                    />
                    <InputError error={errors.fullDescriptionRu?.message as string}/>
                </label>
                <label className="label fs-14 mb-20 block">
                    <span>Полное описание KZ<span className="required">*</span></span>
                    <Controller
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Это поле обязательно для заполнения"
                            }
                        }}
                        name="fullDescriptionKz"
                        render={({field}) => <Textarea onBlur={field.onBlur} onChange={value => {
                            field.onChange(value)
                        }} className="--fluid" placeholder="Опишите тариф на казахском языке"/>}
                    />
                    <InputError error={errors.fullDescriptionRu?.message as string}/>
                </label>
                <label className="label fs-14 mb-20 block">
                    <span>
                        Период<span className="required">*</span>
                    </span>
                    <Controller
                        control={control}
                        name="periodUnit"
                        rules={{
                            required: {
                                value: true,
                                message: "Это поле обязательно для заполнения"
                            }
                        }}
                        render={({field, fieldState}) =>
                            <Select
                                contentClassName={s.selectContent}
                                className={s.select}
                                label={periodSelect.getLabelOption()}
                                onBlur={field.onBlur}
                                ref={field.ref}
                                placeholder="Выберите из списка"
                                closeOnSelect={true}
                                error={fieldState.error?.message}
                                clickOutside>
                                {
                                    periodSelect.options.map(item =>
                                        <li key={item.id}
                                            onClick={() => {
                                                field.onChange(item.id)
                                                periodSelect.handleChangeOption(item.id)
                                            }}
                                            className={classNames('option', item.checked && 'active')}>
                                            <label>
                                                {item.label}
                                            </label>
                                        </li>
                                    )
                                }
                            </Select>
                        }
                    />
                </label>
                <label className="label fs-14 mb-20 block">
                    <span>Стоимость * <span className="required">*</span></span>
                    <Input {...register("periodPrice", {
                        required: {
                            value: true,
                            message: 'Это поле обязательно для заполнения'
                        }
                    })} error={errors.extId?.message as string} className={cn("--fluid")}
                           placeholder="Введите стоимость тарифа"/>
                </label>
                <Button type="submit" className={s.button}>
                    Создать
                </Button>
            </form>
        </div>
    );
})

export default TariffForm;
