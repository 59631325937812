import React from 'react';

const FileUploadButton = () => {
	return (
		<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path d='M7 4L10 1L13 4' stroke='white' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='round'
						strokeLinejoin='round' />
			<path d='M10 14L10 2' stroke='white' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='round'
						strokeLinejoin='round' />
			<path d='M2 11C2 15.42 5 19 10 19C15 19 18 15.42 18 11' stroke='white' strokeWidth='1.5' strokeMiterlimit='10'
						strokeLinecap='round' strokeLinejoin='round' />
		</svg>

	);
};

export default FileUploadButton;
