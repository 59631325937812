import React, {useEffect, useState, useRef, forwardRef} from 'react'
import classNames from 'classnames'
import Styles from './Select.module.scss'
import {Input} from '@/shared/ui/Inputs'

interface ISelectProps {
    children?: React.ReactNode;
    label?: string | number;
    style?: React.CSSProperties;
    className?: string;
    contentClassName?: string;
    clickOutside?: boolean;
    closeOnSelect?: boolean;
    error?: string;
    forbidOpening?: boolean;
    inputClassName?: string;
    onBlur?: () => void;
    placeholder?: string;
}

export const Select = forwardRef<HTMLInputElement, ISelectProps>(({
                                                                      children,
                                                                      label,
                                                                      className,
                                                                      contentClassName,
                                                                      closeOnSelect,
                                                                      clickOutside,
                                                                      error,
                                                                      forbidOpening,
                                                                      inputClassName,
                                                                      onBlur,
                                                                      placeholder
                                                                  }, ref) => {

    const [isShowContent, setIsShowContent] = useState<boolean>(false)

    const closeSelect = () => {
        setIsShowContent(false)
    }

    const handleShowContent = (e: React.MouseEvent) => {
        if (!forbidOpening) {
            e.stopPropagation()
            setIsShowContent(!isShowContent)
            if (!isShowContent && clickOutside) {
                window.addEventListener('click', closeSelect, {once: true})
            }
        }
    }

    useEffect(() => {
        return () => window.addEventListener('click', closeSelect, {once: true})
    }, [])
    const refDiv = useRef<HTMLDivElement>(null)
    return (
        <>
            <div className={classNames(Styles.wrap, className)} onClick={handleShowContent}>
                <Input ref={ref} value={label} readonly error={error} className={inputClassName} placeholder={placeholder}/>
                <div
                    className={classNames(Styles.arrow, isShowContent ? "" : Styles.arrowDown, Styles.arrow)}></div>
                {
                    isShowContent &&
                    <div ref={refDiv} className={classNames(Styles.content, contentClassName)} onClick={(e) => {
                        if (!closeOnSelect) {
                            e.stopPropagation()
                        }
                    }}>
                        {children}
                    </div>
                }
            </div>
        </>


    )
})
