import { api } from '@/shared/api/api';
import { flow, types } from 'mobx-state-tree';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { instance } from '@/shared/api/instanceAxios';
// @ts-ignore

const SmsModel = types
	.model('TemplatesModel', {
		templatesList: types.array(
			types.model({
				code: types.string,
				title: types.maybeNull(types.string),
				description: types.string,
			}),
		),
		currentTemplate: types.maybeNull(
			types.model({
				body: types.frozen(),
				code: types.string,
				description: types.string,
				headers: types.array(
					types.model({
						name: types.string,
						value: types.string,
					}),
				),
				id: types.number,
				isHtmlBody: types.boolean,
				params: types.string,
				subject: types.string,
				title: types.maybeNull(types.string),
			}),
		),
	})
	.actions((self) => ({
		fetchTemplates: flow(function* () {
			const data = yield api.getTemplateList();
			self.templatesList = data;
			return data;
		}),
		fetchTemplate: flow(function* (code: string) {
			self.currentTemplate = yield api.getTemplateData(code);
			const currentTemplate = self.currentTemplate;
			if (currentTemplate) {
				if (currentTemplate.isHtmlBody) {
					const blocksFromHtml = htmlToDraft(currentTemplate.body);
					const contentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks, blocksFromHtml.entityMap);
					currentTemplate.body = EditorState.createWithContent(contentState);
				} else {
					const contentState = ContentState.createFromText(currentTemplate.body);
					currentTemplate.body = EditorState.createWithContent(contentState);
				}
			}
		}),
		changeBodyTemplate(editorState: EditorState) {
			if (self.currentTemplate) {
				self.currentTemplate.body = editorState;
			}
		},
		changeIsBodyHtml(value: boolean) {
			if (self.currentTemplate) {
				const currentTemplate = self.currentTemplate;
				currentTemplate.isHtmlBody = value;
				const currentEditorState = currentTemplate.body as EditorState;
				if (value) {
					const blockFromHtml = htmlToDraft(currentEditorState.getCurrentContent().getPlainText());
					const contentState = ContentState.createFromBlockArray(blockFromHtml.contentBlocks, blockFromHtml.entityMap);
					currentTemplate.body = EditorState.createWithContent(contentState);
				} else {
					const contentRaw = convertToRaw(currentEditorState.getCurrentContent());
					const htmlContent = draftToHtml(contentRaw);
					const contentState = ContentState.createFromText(htmlContent);
					currentTemplate.body = EditorState.createWithContent(contentState);
				}
			}
		},
		setHeader(index: number, value: string, name: string) {
			if (self.currentTemplate?.headers) {
				const header = self.currentTemplate.headers[index];
				if (header) {
					header.name = name;
					header.value = value;
				}
			}
		},
		addHeader() {
			self.currentTemplate?.headers.push({
				name: '',
				value: '',
			});
		},
		deleteHeader(index: number) {
			self.currentTemplate?.headers.splice(index, 1);
		},
		changeSubject(value: string) {
			if (self.currentTemplate) {
				self.currentTemplate.subject = value;
			}
		},
		send: flow(function* (to: string, files: File[]) {
			if (self.currentTemplate) {
				const headers = [...self.currentTemplate.headers].filter(item => item.name && item.value);
				const headerIndex = headers.findIndex(item => item.name === 'to');
				headers[headerIndex].value = to;
				const body = self.currentTemplate.body;
				const formData = new FormData();
				files.forEach(item => formData.append('files', item));
				formData.append('templateDto', JSON.stringify({
					...self.currentTemplate,
					headers,
					body: body.getCurrentContent().getPlainText(),
				}));
				yield api.sendLetter(formData);
			}
		}),
		saveChanges: flow(function* () {
			const currentTemplate = self.currentTemplate;
			if (currentTemplate && currentTemplate.headers) {
				while (
					currentTemplate.headers.some((item) => !item.value || !item.name)
					) {
					const index = currentTemplate.headers.findIndex(
						(item) => !item.value,
					);
					currentTemplate.headers.splice(index, 1);
				}
				const currentEditorState = currentTemplate.body as EditorState;
				yield api.changeTemplate(
					currentTemplate.code,
					{
						...currentTemplate,
						body: currentEditorState.getCurrentContent().getPlainText(),
						description: undefined,
						title: undefined,
					},
				);
			}
		}),
	}));
export const SmsStore = SmsModel.create({
	templatesList: [],
});
