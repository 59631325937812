import { instance } from "./instanceAxios"

export const permissionsServices = {
    getPermissionsSections: async (params?: any) => {
        const response = await instance.get('/permissions/sections', {
            params: {
                size: 100
            },
        })
        return response.data
    },
    getPermissionsList: async () => {
        const response = await instance.get('/permissions', {
            params: {
                size: 100,
            }
        })
        return response.data
    }
}