import React, { FC } from 'react'
import { RefreshIcon } from '@/assets/icons/RefreshIcon';
import classNames from 'classnames';
import Styles from './Button.module.scss';

interface IButtonRefresh {
    onClick?: () => void;
    className?: string;
}

export const ButtonRefresh: FC<IButtonRefresh> = ({ onClick, className }) => {
    return (
        <button className={classNames(Styles.buttonRefresh, className)} onClick={onClick}>
            <RefreshIcon />
        </button>
    )
}
