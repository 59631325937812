import { FC } from 'react'
import { IconProp } from './iconType'
export const ContentIcon: FC<IconProp> = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.6201 9.62H12.3701C11.9601 9.62 11.6201 9.28 11.6201 8.87C11.6201 8.46 11.9601 8.12 12.3701 8.12H17.6201C18.0301 8.12 18.3701 8.46 18.3701 8.87C18.3701 9.28 18.0401 9.62 17.6201 9.62Z" fill={color} />
      <path d="M7.12006 10.3799C6.93006 10.3799 6.74006 10.3099 6.59006 10.1599L5.84006 9.40995C5.55006 9.11995 5.55006 8.63995 5.84006 8.34995C6.13006 8.05995 6.61006 8.05995 6.90006 8.34995L7.12006 8.56995L8.84006 6.84995C9.13006 6.55995 9.61006 6.55995 9.90006 6.84995C10.1901 7.13995 10.1901 7.61995 9.90006 7.90995L7.65006 10.1599C7.51006 10.2999 7.32006 10.3799 7.12006 10.3799Z" fill={color} />
      <path d="M17.6201 16.62H12.3701C11.9601 16.62 11.6201 16.28 11.6201 15.87C11.6201 15.46 11.9601 15.12 12.3701 15.12H17.6201C18.0301 15.12 18.3701 15.46 18.3701 15.87C18.3701 16.28 18.0401 16.62 17.6201 16.62Z" fill={color} />
      <path d="M7.12006 17.3799C6.93006 17.3799 6.74006 17.3099 6.59006 17.1599L5.84006 16.4099C5.55006 16.1199 5.55006 15.6399 5.84006 15.3499C6.13006 15.0599 6.61006 15.0599 6.90006 15.3499L7.12006 15.5699L8.84006 13.8499C9.13006 13.5599 9.61006 13.5599 9.90006 13.8499C10.1901 14.1399 10.1901 14.6199 9.90006 14.9099L7.65006 17.1599C7.51006 17.2999 7.32006 17.3799 7.12006 17.3799Z" fill={color} />
      <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" fill={color} />
    </svg>
  )
}


