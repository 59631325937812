import React from 'react'

export const NoDataIcon = () => {
    return (
        <svg width="115" height="125" viewBox="0 0 115 125" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M74.6241 62.8604L52.25 85.2324C52.25 85.2324 60.2066 82.7475 71.6415 86.7237C73.5652 87.3924 76.7841 84.1735 76.1155 82.2497C72.1393 70.8148 74.6241 62.8604 74.6241 62.8604Z" fill="#00ADEF" />
            <path d="M14.7453 25.3537C28.3297 11.7714 50.3832 11.7714 63.9676 25.3537C77.5498 38.9381 77.5498 60.9916 63.9676 74.576C50.3832 88.1582 28.3297 88.1582 14.7453 74.576C1.16303 60.9916 1.16303 38.9381 14.7453 25.3537Z" fill="url(#paint0_linear_1513_8803)" />
            <path d="M59.4684 21.5527C61.0463 22.6728 62.5523 23.9405 63.9677 25.3538C77.55 38.9382 77.55 60.9917 63.9677 74.5761C50.3834 88.1584 28.3298 88.1584 14.7455 74.5761C13.946 73.7745 13.193 72.9455 12.4863 72.0913C25.653 76.3691 40.7013 73.2809 51.1554 62.8269C62.3055 51.6767 65.0773 35.2995 59.4684 21.5527Z" fill="#E5EEF8" />
            <path d="M11.7621 22.3715C26.9918 7.1418 51.72 7.1418 66.9497 22.3715C82.1794 37.6012 82.1794 62.3294 66.9497 77.5591C51.72 92.7887 26.9918 92.7887 11.7621 77.5591C-3.46758 62.3294 -3.46758 37.6012 11.7621 22.3715ZM16.2361 26.8455C28.9957 14.0859 49.7161 14.0859 62.4757 26.8455C75.2353 39.6051 75.2353 60.3255 62.4757 73.0851C49.7161 85.8447 28.9957 85.8447 16.2361 73.0851C3.47649 60.3255 3.47649 39.6051 16.2361 26.8455Z" fill="#CADCF0" />
            <path d="M4.39416 67.3174C-2.84521 52.7226 -0.389901 34.5229 11.7622 22.3708C23.1739 10.9591 39.9182 8.09876 54.0067 13.7899C54.1079 15.0829 54.1606 16.3928 54.1606 17.7154C54.1606 18.7363 54.129 19.7509 54.0678 20.7592C41.8103 14.5872 26.4667 16.6164 16.2362 26.8448C5.3919 37.6891 3.76346 54.2835 11.3551 66.8554C9.13182 67.1591 6.86213 67.3174 4.55869 67.3174H4.39416Z" fill="url(#paint1_linear_1513_8803)" />
            <path d="M69.932 93.9654C66.2258 90.2592 66.2258 84.2496 69.932 80.5413C73.6381 76.8352 79.6499 76.8352 83.356 80.5413L113.934 111.119C114.756 111.942 112.417 115.618 108.713 119.322C105.009 123.026 101.332 125.366 100.509 124.543L69.932 93.9654Z" fill="#CADCF0" />
            <path d="M69.932 93.9654C66.2258 90.2592 66.2258 84.2496 69.932 80.5413C73.6381 76.8352 79.6499 76.8352 83.356 80.5413L111.031 108.216C111.854 109.039 109.514 112.716 105.81 116.42C102.106 120.124 98.4296 122.463 97.607 121.64L69.932 93.9654Z" fill="#CADCF0" />
            <path d="M34.8882 43.3233C34.8397 41.3131 35.5949 39.845 36.7592 38.9843C38.5902 37.6343 41.326 37.6913 42.811 39.364C43.4481 40.0833 43.8172 41.1 43.8278 42.3868C43.8531 45.4728 42.406 47.2763 40.9885 49.1431C39.0817 51.6596 37.1769 54.2457 36.9406 58.5636C36.8774 59.7279 37.7717 60.7214 38.934 60.7847C40.0963 60.848 41.0919 59.9557 41.1552 58.7935C41.3324 55.5134 42.9017 53.6023 44.3509 51.6912C46.2915 49.1325 48.0824 46.5802 48.0465 42.353C48.0254 39.8682 47.1964 37.9507 45.9645 36.5628C43.0874 33.3228 37.8034 32.9726 34.2554 35.5882C32.1292 37.1576 30.583 39.7564 30.6695 43.4225C30.6969 44.5868 31.6651 45.5086 32.8295 45.4812C33.9917 45.4538 34.9156 44.4877 34.8882 43.3233Z" fill="#00ADEF" />
            <path d="M38.8504 68.6187C40.2833 68.6187 41.4449 67.4571 41.4449 66.0242C41.4449 64.5913 40.2833 63.4297 38.8504 63.4297C37.4175 63.4297 36.2559 64.5913 36.2559 66.0242C36.2559 67.4571 37.4175 68.6187 38.8504 68.6187Z" fill="#00ADEF" />
            <path d="M76.8106 13.9231L83.1388 3.37624C83.7378 2.37851 83.4151 1.08124 82.4153 0.482179C81.4175 -0.114774 80.1203 0.20796 79.5212 1.20569L73.1931 11.7526C72.594 12.7524 72.9189 14.0497 73.9166 14.6466C74.9164 15.2457 76.2116 14.923 76.8106 13.9231Z" fill="#758EA8" />
            <path d="M86.7909 20.8733L109.994 3.99831C110.935 3.31276 111.144 1.99229 110.46 1.05151C109.775 0.108618 108.454 -0.0981004 107.513 0.585337L84.3103 17.4603C83.3674 18.1459 83.1607 19.4664 83.8441 20.4071C84.5297 21.35 85.8501 21.5567 86.7909 20.8733Z" fill="#758EA8" />
            <path d="M90.2783 33.8696L107.153 29.6508C108.284 29.3681 108.972 28.2228 108.689 27.0921C108.406 25.9636 107.261 25.276 106.13 25.5586L89.2552 29.7774C88.1267 30.06 87.439 31.2054 87.7217 32.336C88.0044 33.4646 89.1497 34.1522 90.2783 33.8696Z" fill="#758EA8" />
            <defs>
                <linearGradient id="paint0_linear_1513_8803" x1="14.9583" y1="30.4246" x2="63.7018" y2="74.8375" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#E9F3FC" />
                </linearGradient>
                <linearGradient id="paint1_linear_1513_8803" x1="17.8857" y1="15.1673" x2="35.6214" y2="69.1947" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CADCF0" />
                    <stop offset="1" stopColor="#A4BBDB" />
                </linearGradient>
            </defs>
        </svg>

    )
}


