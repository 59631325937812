import { FC } from 'react'
import { IconProp } from './iconType'
export const TaxpayersIcon: FC<IconProp> = ({ color }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 21.75H7C2.59 21.75 1.25 20.41 1.25 16V8C1.25 3.59 2.59 2.25 7 2.25H17C21.41 2.25 22.75 3.59 22.75 8V16C22.75 20.41 21.41 21.75 17 21.75ZM7 3.75C3.42 3.75 2.75 4.43 2.75 8V16C2.75 19.57 3.42 20.25 7 20.25H17C20.58 20.25 21.25 19.57 21.25 16V8C21.25 4.43 20.58 3.75 17 3.75H7Z" fill={color} />
            <path d="M19 8.75H14C13.59 8.75 13.25 8.41 13.25 8C13.25 7.59 13.59 7.25 14 7.25H19C19.41 7.25 19.75 7.59 19.75 8C19.75 8.41 19.41 8.75 19 8.75Z" fill={color} />
            <path d="M19 12.75H15C14.59 12.75 14.25 12.41 14.25 12C14.25 11.59 14.59 11.25 15 11.25H19C19.41 11.25 19.75 11.59 19.75 12C19.75 12.41 19.41 12.75 19 12.75Z" fill={color} />
            <path d="M19 16.75H17C16.59 16.75 16.25 16.41 16.25 16C16.25 15.59 16.59 15.25 17 15.25H19C19.41 15.25 19.75 15.59 19.75 16C19.75 16.41 19.41 16.75 19 16.75Z" fill={color} />
            <path d="M8.49994 12.04C7.08994 12.04 5.93994 10.89 5.93994 9.48004C5.93994 8.07004 7.08994 6.92004 8.49994 6.92004C9.90994 6.92004 11.0599 8.07004 11.0599 9.48004C11.0599 10.89 9.90994 12.04 8.49994 12.04ZM8.49994 8.42004C7.91994 8.42004 7.43994 8.90004 7.43994 9.48004C7.43994 10.06 7.91994 10.54 8.49994 10.54C9.07994 10.54 9.55994 10.06 9.55994 9.48004C9.55994 8.90004 9.07994 8.42004 8.49994 8.42004Z" fill={color} />
            <path d="M11.9999 17.08C11.6199 17.08 11.2899 16.79 11.2499 16.4C11.1399 15.32 10.2699 14.45 9.17991 14.35C8.71991 14.31 8.25991 14.31 7.79991 14.35C6.70991 14.45 5.83991 15.31 5.72991 16.4C5.68991 16.81 5.31991 17.12 4.90991 17.07C4.49991 17.03 4.19991 16.66 4.23991 16.25C4.41991 14.45 5.84991 13.02 7.65991 12.86C8.20991 12.81 8.76991 12.81 9.31991 12.86C11.1199 13.03 12.5599 14.46 12.7399 16.25C12.7799 16.66 12.4799 17.03 12.0699 17.07C12.0499 17.08 12.0199 17.08 11.9999 17.08Z" fill={color} />
        </svg>
    )
}


