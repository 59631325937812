import {api} from "@/shared/api/api"
import {applySnapshot, flow, getSnapshot, types} from "mobx-state-tree"


const TaxpayersModel = types.model('UserListModel', {
    totalItems: types.number,
    itemsPerPage: types.number,
    totalPages: types.number,
    currentPage: types.number,
    loading: types.boolean,
    currentTaxpayer: types.frozen(),
    taxpayers: types.array(types.frozen())
}).actions(self => ({
    fetchAll: flow(function* (data?, params?) {
        self.loading = true;
        try {
            const response = yield api.getTaxpayersList(data, params)
            self.totalItems = response.totalItems
            self.currentPage = response.currentPage
            self.totalPages = response.totalPages
            self.taxpayers = response.taxpayers || []
            self.itemsPerPage = response.itemsPerPage
        }catch (e) {
            throw e
        }finally {
            self.loading = false;
        }
    }),
    fetchById: flow(function* (id: number | string) {
        self.loading = true;
        try {
            const data = yield api.getTaxpayerById(id)
            self.currentTaxpayer = data
            return data

        }catch (e) {
            throw e
        }finally {
            self.loading = false;
        }

    }),
    deleteTaxpayer: flow(function* (id: number) {
        const response = yield api.deleteTaxpayer(id)
        return response
    }),
    blockUnblock: flow(function* (orgId: number, status: boolean, blockReason: string) {
        return yield api.blockUnblockTaxpayer(orgId, status ? "ENABLE" : "DISABLE", blockReason)
    }),
})).actions(self => {
    let initialState = {}
    return {
        afterCreate: () => {
            initialState = getSnapshot(self)
        },
        reset: () => {
            applySnapshot(self, initialState)
        }
    }
})

export const TaxpayersStore = TaxpayersModel.create({
    taxpayers: [],
    currentPage: 1,
    itemsPerPage: 15,
    totalItems: 0,
    totalPages: 0,
    currentTaxpayer: {},
    loading: true,
})
