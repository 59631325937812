import {useDeleteScroll} from '@/shared/hooks';
import {IModalProps} from '@/shared/types/ModalProps';
import classNames from 'classnames';
import { forwardRef} from 'react'
import Styles from './Modal.module.scss'


export const Modal = forwardRef<HTMLDivElement, IModalProps>(
    ({children, onClose, className, clickOutside = true}, ref) => {

        useDeleteScroll()

        return (
            <>
                <div className={classNames(Styles.wrapper)} onClick={(event) => {
                    if (event.target === event.currentTarget && clickOutside) {
                        if (onClose) onClose()
                    }
                }}>
                    <div ref={ref} className={classNames(Styles.modal, className)}>
                        {children}
                    </div>
                </div>
            </>

        )
    }
)
