import {SearchFolderIcon} from '@/assets/icons/SearchFolderIcon';
import classNames from 'classnames';
import React, {FC} from 'react'


interface ISearchNoResult {
    searchQuery?: string;
    className?: string;
}

export const SearchNoResult: FC<ISearchNoResult> = ({className, searchQuery}) => {
    return (
        <div className={classNames('flex column a-center pt-80 c_first_color', className)}>
            <SearchFolderIcon/>
            <div className='mt-32 fs-20'>По
                {
                    searchQuery ? <>запросу <span className='fw-500'>“{searchQuery}”</span></> : " вашему запросу"
                } ничего не найдено
            </div>
        </div>
    )
}
