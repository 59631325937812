import {PageTitle} from '@/shared/ui/PageTitle'
import {BreadCrumb, BreadCrumbItem} from '@/shared/ui/BreadCumb'
import {Button} from '@/shared/ui/Buttons'
import {Card} from '@/shared/ui/Card'
import {Checkbox} from '@/shared/ui/Checkbox'
import classNames from 'classnames'
import React, {useEffect, useMemo, useState} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import Styles from './KkmHistoryEditsPage.module.scss'
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from '@tanstack/react-table'
import {TableView} from '@/shared/ui/Tables'
import {useSelect} from '@/shared/hooks'
import {Select} from '@/shared/ui/Select'
import {KkmStore} from "@/entities/Kkm";
import {getSnapshot} from "mobx-state-tree";
import {observer} from "mobx-react-lite";
import {formatDate} from "@/shared/lib";
import {showToast} from "@/shared/lib";
import {billingStatus as billingStatusStatic} from "@/shared/static/kkm";
import {formatDateUnix} from "@/shared/lib/formatDate";


interface IStatusTable {
    statusCode: string;
    status: string;
    updatedAt: string;
    user: string;
}

export interface IStatusQuery {
    id: number;
    guid: string;
    createdAt: string;
    status: number;
    receivedAsyncAt?: string
}

interface IEdit {
    id: number;
    fieldName: string;
    fieldValue: string;
    updatedAt: string;
}

const columnStatusHelper = createColumnHelper<IStatusTable>()
const columnStatusQueryHelper = createColumnHelper<IStatusQuery>()
const columnsEditHelper = createColumnHelper<IEdit>()

export const KkmHistoryEditsPage = observer(() => {


        const location = useLocation()
        const {id} = useParams()
        const Kkm = KkmStore.currentKkm
        useEffect(() => {
            KkmStore.fetchById(id as string)
            KkmStore.fetchBreadCrumbs(id as string)
            KkmStore.fetchInisHistoryList({kkmId: id, page: 1, size: 3})
            KkmStore.fetchChangesList({kkmId: id})
            KkmStore.fetchBillingHistory(+(id as string))
            KkmStore.fetchRegistrationStatusHistory(+(id as string))
        }, [id])

        const {
            options,
            getLabelOption,
            handleChangeOption
        } = useSelect(
            [...billingStatusStatic]
        )

        useEffect(() => {
            handleChangeOption(Kkm?.billingStatus)
        }, [Kkm, handleChangeOption])

        const changeStatus = async () => {
            const statusId = options.find(item => item.checked)?.id as number
            await showToast(async () => {
                return await KkmStore.changeBillingStatus(Number(id), statusId)
            })
        }
        const columnsStatus = useMemo(() => [
            columnStatusHelper.accessor('statusCode', {
                header: 'Код статуса',
            }),
            columnStatusHelper.accessor('status', {
                header: 'Статус'
            }),
            columnStatusHelper.accessor('updatedAt', {
                header: 'Дата изменения',
                sortingFn: (a, b) => formatDateUnix(b.original.updatedAt) - formatDateUnix(a.original.updatedAt)
            }),
            columnStatusHelper.accessor('user', {
                header: 'Пользователь'
            }),

        ], [])

        const columnsStatusQuery = useMemo(() => [
            columnStatusQueryHelper.accessor('id', {
                header: 'ID запроса',
                cell: ({getValue}) => <Link className='table-link' to={`/kkm/${id}/history/inis`}>{getValue()}</Link>
            }),
            columnStatusQueryHelper.accessor('guid', {
                header: 'GUID запроса'
            }),
            columnStatusQueryHelper.accessor('createdAt', {
                header: 'Дата создания ',
                cell: ({getValue}) => formatDate(getValue()),
                sortingFn: (a, b) => formatDateUnix(b.original.createdAt) - formatDateUnix(a.original.createdAt)
            }),
            columnStatusQueryHelper.accessor('status', {
                header: 'Статус'

            }),
            columnStatusQueryHelper.accessor('receivedAsyncAt', {
                header: 'Дата обработки ответа',
                cell: ({getValue}) => formatDate(getValue() as string)

            }),
        ], [id]);

        const columnsEdit = useMemo(() => [
            // columnsEditHelper.display({
            //     id: 'id',
            // }),
            columnsEditHelper.accessor('fieldValue', {
                header: 'Значение'
            }),
            columnsEditHelper.accessor('fieldName', {
                header: 'Поле'
            }),
            columnsEditHelper.accessor('updatedAt', {
                header: 'Дата изменения',
                cell: ({getValue}) => formatDate(getValue()),
                sortingFn: (a, b) => formatDateUnix(b.original.updatedAt) - formatDateUnix(a.original.updatedAt)
            }),
        ], []);

        const [tableStatusBillingSorting, setTableStatusBillingSorting] = useState<SortingState>([])
        const tableStatusBilling = useReactTable({
            data: getSnapshot(KkmStore.billingHistoryList),
            columns: columnsStatus,
            state: {
                sorting: tableStatusBillingSorting,
            },
            onSortingChange: setTableStatusBillingSorting,
            getSortedRowModel: getSortedRowModel(),
            getCoreRowModel: getCoreRowModel(),
        })

        const [tableStatusRegSorting, setTableStatusRegSorting] = useState<SortingState>([])
        const tableStatusReg = useReactTable({
            data: getSnapshot(KkmStore.registrationHistoryList),
            columns: columnsStatus,
            state: {
                sorting: tableStatusRegSorting,
            },
            onSortingChange: setTableStatusRegSorting,
            getSortedRowModel: getSortedRowModel(),
            getCoreRowModel: getCoreRowModel(),
        })

        const [tableStatusQuerySorting, setTableStatusQuery] = useState<SortingState>([])

        const tableStatusQuery = useReactTable({
            data: getSnapshot(KkmStore.initHistoryList),
            columns: columnsStatusQuery,
            state: {
                sorting: tableStatusQuerySorting
            },
            onSortingChange: setTableStatusQuery,
            getCoreRowModel: getCoreRowModel(),
            getSortedRowModel: getSortedRowModel(),
        })

        const [tableEditsSorting, setTableEdits] = useState<SortingState>([])
        const tableEdits = useReactTable({
            data: getSnapshot(KkmStore.changeList),
            columns: columnsEdit,
            state: {
                sorting: tableEditsSorting,
            },
            onSortingChange: setTableEdits,
            getSortedRowModel: getSortedRowModel(),
            getCoreRowModel: getCoreRowModel(),
        })
        return (
            <>
                <Card className='mb-12'>
                    <div className={classNames('py-30 px-27')}>
                        <BreadCrumb className='mb-30'>
                            <BreadCrumbItem to='/taxpayers'>Налогоплательщики</BreadCrumbItem>
                            <BreadCrumbItem
                                to={`/taxpayers/${KkmStore.breadCrumbs?.id}`}>{KkmStore.breadCrumbs?.title}</BreadCrumbItem>
                            <BreadCrumbItem to={`/kkm/${id}`}>ККМ</BreadCrumbItem>
                            <BreadCrumbItem to={location.pathname}>История изменений</BreadCrumbItem>
                        </BreadCrumb>
                        <PageTitle className='mb-30'>
                            История изменений ККМ
                        </PageTitle>
                        <div className={Styles.setStatusRow}>
                            <div className={classNames('label', Styles.label)}>
                                Изменить биллинг статус:
                            </div>
                            <div className={Styles.selectWrap}>
                                <Select clickOutside label={getLabelOption()} contentClassName={Styles.selectContent}>
                                    {
                                        options.map(item =>
                                            <li key={item.id} className={classNames('option', item.checked && 'active')}>
                                                <label>
                                                    <Checkbox
                                                        onChange={async () => {
                                                            handleChangeOption(item.id)
                                                        }}
                                                        className='mr-10'
                                                        checked={item.checked}
                                                    />
                                                    {item.label}
                                                </label>
                                            </li>
                                        )
                                    }
                                </Select>
                            </div>
                            <Button onClick={changeStatus} className={classNames(Styles.button)}>
                                Изменить
                            </Button>
                        </div>
                    </div>
                </Card>
                <Card className='mb-12'>
                    <h3 className='pt-18 pb-18 pl-27 c_first_color fs-18 fw-400'>
                        Биллинг статус
                    </h3>
                    <TableView className='table-head-text-left table-left' tableInstance={{...tableStatusBilling}}/>
                </Card>
                <Card className='mb-12'>
                    <h3 className='pt-18 pb-18 pl-27 c_first_color fs-18 fw-400'>
                        Регистрационный статус
                    </h3>
                    <TableView className='table-head-text-left table-left' tableInstance={{...tableStatusReg}}/>
                </Card>
                <Card className='mb-12'>
                    <h3 className='pt-18 pb-18 pl-27 c_first_color fs-18 fw-400'>
                        Статус запросов в ИНИС
                    </h3>
                    <TableView className='table-head-text-left table-left' tableInstance={{...tableStatusQuery}}/>
                </Card>
                <Card>
                    <h3 className='pt-18 pb-18 pl-27 c_first_color fs-18 fw-400'>
                        Изменения
                    </h3>
                    <TableView className='table-head-text-left table-left' tableInstance={{...tableEdits}}/>
                </Card>
            </>

        )
    }
)
