import { instance } from "./instanceAxios"




export const settingsServices = {
    getAllSettings: async () => {
        const response = await instance.get('/consul/getAllConfigs')
        return response.data
    },
    getSettingsByKeyOrValue: async (data: string) => {
        const response = await instance.post('/consul/getConfig', {
            key: data,
            value: data,
        })
        return response.data
    },
    saveSetting: async (key: string, value: string) => {
        const response = await instance.post('/consul/changeConfig', {
            key,
            value,
        })
        return response.data
    }
}