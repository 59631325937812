import { ModalSuccess } from '@/shared/ui/Modal/ModalSuccess'
import { WarningMessage } from '@/shared/ui/WarningMessage/WarningMessage'
import { IModalProps } from '@/shared/types/ModalProps'
import React, { FC, useState } from 'react'
import { GeneralDeleteModal } from '../Generals/GeneralDeleteModal'


export const DeleteBusinessProcessesModal: FC<IModalProps> = ({ onClose }) => {
    const [successDeleteUser, setSuccessDeleteUser] = useState<Boolean>(false)
    return (
        <>
            {successDeleteUser ?
                <ModalSuccess onClose={onClose} className='py-3'>
                    Бизнес-процесс удален!
                </ModalSuccess>
                :
                <GeneralDeleteModal onClose={onClose} onConfirmation={() => setSuccessDeleteUser(true)}>
                    <div className='fw-500 fs-18 mb-38 c_first_color'>
                        Вы действительно хотите удалить этот процесс?
                    </div>
                    <WarningMessage>
                        <div className='shrink-0'>
                            <div>
                                Данный процесс будет удален из базы Системы!
                            </div>
                            <div>
                                В дальнейшем запуск процесса не будет возможен!
                            </div>

                        </div>
                    </WarningMessage>
                    <div className='mt-13 mb-46 c_first_color '>
                        После нажатия кнопки «Удалить» отмена станет невозможна
                    </div>
                </GeneralDeleteModal>
            }

        </>

    )
}

