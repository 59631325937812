import { useEffect } from 'react'

export const useDeleteScroll = (): void => {
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "unset";
        }
    }, [])
}
