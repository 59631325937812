import {instance} from './instanceAxios';

export interface IGetIbdListParams {
    guid?: string;
    sendDateFrom?: string;
    sendDateTo?: string;
}

export interface IIbd {
    guid: string;
    status: string;
    sentAt: string;
    receivedAt: string;
    handledAt: string;
}

export interface IGetIbdListResponse {
    totalItems: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
    ibdList: IIbd[]
}

export const ibdServices = {
    getIbdList: async (params: IGetIbdListParams, {
        page = 1,
        size = 10
    }) => instance.post<IGetIbdListResponse>('/ibd/list', params, {
        params: {
            size,
            page
        }
    }).then(response => response.data)
}
