import { FC } from 'react'
import { IconProp } from './iconType'

export const LogoutIcon: FC<IconProp> = ({ color }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.75986 1.73002H8.88986C13.3299 1.73002 15.4699 3.48002 15.8399 7.40002C15.8799 7.81002 15.5799 8.18002 15.1599 8.22002C14.7599 8.26002 14.3799 7.95002 14.3399 7.54002C14.0499 4.40002 12.5699 3.23002 8.87986 3.23002H8.74986C4.67986 3.23002 3.23986 4.67002 3.23986 8.74002L3.23986 15.26C3.23986 19.33 4.67986 20.77 8.74986 20.77H8.87986C12.5899 20.77 14.0699 19.58 14.3399 16.38C14.3899 15.97 14.7399 15.66 15.1599 15.7C15.5799 15.73 15.8799 16.1 15.8499 16.51C15.5099 20.49 13.3599 22.27 8.88986 22.27H8.75986C3.84986 22.27 1.74986 20.17 1.74986 15.26L1.74986 8.74002C1.74986 3.83002 3.84986 1.73002 8.75986 1.73002Z" fill={color} />
            <path d="M8.99988 11.25L20.3799 11.25C20.7899 11.25 21.1299 11.59 21.1299 12C21.1299 12.41 20.7899 12.75 20.3799 12.75L8.99988 12.75C8.58988 12.75 8.24988 12.41 8.24988 12C8.24988 11.59 8.58988 11.25 8.99988 11.25Z" fill={color} />
            <path d="M18.1499 7.89997C18.3399 7.89997 18.5299 7.96997 18.6799 8.11997L22.0299 11.47C22.3199 11.76 22.3199 12.24 22.0299 12.53L18.6799 15.88C18.3899 16.17 17.9099 16.17 17.6199 15.88C17.3299 15.59 17.3299 15.11 17.6199 14.82L20.4399 12L17.6199 9.17997C17.3299 8.88997 17.3299 8.40997 17.6199 8.11997C17.7599 7.96997 17.9599 7.89997 18.1499 7.89997Z" fill={color} />
        </svg>
    )
}


