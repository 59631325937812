import React, { FC } from "react";
import s from "./VariablesList.module.scss";
import cn from "classnames";

interface IProps {
  list: {
    variable: string;
    description: string;
  }[];
}

export const VariablesList: FC<IProps> = ({ list }) => {
  return (
    <div className={cn("flex")}>
      <div className={cn(s.item, s.left)}>
        {list.map((item) => (
          <div key={item.variable} className={"mb-4 fs-14 c_first_color"}>
            {`{${item.variable}}`}
          </div>
        ))}
      </div>
      <div className={cn(s.item, "pl-50")}>
        {list.map((item) => (
          <div key={item.variable} className={"mb-4 fs-14 c_first_color"}>
            {item.description}
          </div>
        ))}
      </div>
    </div>
  );
};
