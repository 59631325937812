import { FC } from 'react'
import Styles from './Logo.module.scss'
import logoImage from '@/assets/img/logo.svg'
import { Link } from 'react-router-dom';
import classNames from 'classnames'
interface LogoProps {
    className?: string;
    show: boolean;
}

export const Logo: FC<LogoProps> = ({ className, show }) => {
    return (
        <Link to="/">
            <div className={classNames(Styles.logo, className)}>
                {/*<img src={logoImage} alt="logo" />*/}
                {
                    show &&
                    <div className={Styles.text}>
                        <div className={Styles.title}>
                            Мониторинг
                        </div>
                        <div className={Styles.subTitle}>
                            оператор фискальных данных
                        </div>
                    </div>
                }


            </div >
        </Link>
    )
}


