import { observer } from 'mobx-react-lite';
import React, { FC } from 'react'
import { Navigate } from 'react-router-dom';
import { rootStore } from './store/rootStore'


interface IProtectedRoute {
    children: React.ReactElement;
}

export const ProtectedRoute: FC<IProtectedRoute> = observer(({ children }: any) => {
    if (!rootStore.token) {
        return <Navigate to="/login" replace />;
    }
    return children;
}

)
