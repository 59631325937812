import {RolesStore} from '@/store/RolesStroe'
import {IModalProps} from '@/shared/types/ModalProps'
import {ModalSuccess} from "@/shared/ui/Modal";
import React, {useState, FC} from 'react'
import {GeneralDeleteModal} from '../Generals/GeneralDeleteModal'


export const DeleteRoleModal: FC<IModalProps> = ({onClose, id, fetchData}) => {
    const [successDeleteRole, setSuccessDeleteUser] = useState<boolean>(false)

    const deleteRole = async () => {
        await RolesStore.deleteRole(id as number)
        if (fetchData) {
            fetchData()
        }
        setSuccessDeleteUser(true)
    }
    return (
        <>
            {successDeleteRole ?
                <ModalSuccess onClose={onClose} className='py-3'>
                    Удалено!
                </ModalSuccess>
                :
                <GeneralDeleteModal onClose={onClose} onConfirmation={deleteRole}>
                    <div className='fw-500 fs-18 mb-47'>
                        Вы действительно хотите удалить эту роль?
                    </div>
                </GeneralDeleteModal>
            }
        </>
    )
}
