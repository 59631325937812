import { FC } from 'react'
import { IconProp } from './iconType'
export const SmsIcon: FC<IconProp> = ({ color }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 21.25H7C3.35 21.25 1.25 19.15 1.25 15.5V8.5C1.25 4.85 3.35 2.75 7 2.75H17C20.65 2.75 22.75 4.85 22.75 8.5V11.5C22.75 11.91 22.41 12.25 22 12.25C21.59 12.25 21.25 11.91 21.25 11.5V8.5C21.25 5.64 19.86 4.25 17 4.25H7C4.14 4.25 2.75 5.64 2.75 8.5V15.5C2.75 18.36 4.14 19.75 7 19.75H12C12.41 19.75 12.75 20.09 12.75 20.5C12.75 20.91 12.41 21.25 12 21.25Z" fill={color} />
            <path d="M11.9998 12.87C11.1598 12.87 10.3098 12.61 9.65978 12.08L6.52978 9.57996C6.20978 9.31996 6.14978 8.84997 6.40978 8.52997C6.66978 8.20997 7.13977 8.15997 7.45977 8.40997L10.5898 10.91C11.3498 11.52 12.6398 11.52 13.3998 10.91L16.5298 8.40997C16.8498 8.14997 17.3198 8.19997 17.5798 8.52997C17.8398 8.84997 17.7898 9.32996 17.4598 9.57996L14.3298 12.08C13.6898 12.61 12.8398 12.87 11.9998 12.87Z" fill={color} />
            <path d="M15.8201 21.78C15.4401 21.78 15.0801 21.64 14.8201 21.38C14.5101 21.07 14.3701 20.62 14.4401 20.15L14.6301 18.8C14.6801 18.45 14.8901 18.03 15.1401 17.78L18.68 14.24C19.16 13.76 19.6301 13.51 20.1401 13.46C20.7601 13.4 21.38 13.66 21.96 14.24C22.54 14.82 22.8 15.43 22.74 16.06C22.69 16.56 22.43 17.04 21.96 17.52L18.4201 21.06C18.1701 21.31 17.75 21.52 17.4 21.57L16.05 21.76C15.97 21.77 15.9001 21.78 15.8201 21.78ZM20.31 14.95C20.3 14.95 20.29 14.95 20.28 14.95C20.14 14.96 19.95 15.09 19.74 15.3L16.2001 18.84C16.1701 18.87 16.12 18.97 16.12 19.01L15.9401 20.26L17.1901 20.08C17.2301 20.07 17.33 20.02 17.36 19.99L20.9 16.45C21.11 16.23 21.24 16.05 21.2501 15.91C21.2701 15.71 21.07 15.47 20.9 15.3C20.74 15.14 20.51 14.95 20.31 14.95Z" fill={color} />
            <path d="M20.9201 18.2499C20.8501 18.2499 20.7801 18.2399 20.7201 18.2199C19.4001 17.8499 18.3501 16.7999 17.9801 15.4799C17.8701 15.0799 18.1001 14.6699 18.5001 14.5499C18.9001 14.4399 19.3101 14.6699 19.4201 15.0699C19.6501 15.8899 20.3001 16.5399 21.1201 16.7699C21.5201 16.8799 21.7501 17.2999 21.6401 17.6999C21.5501 18.0299 21.2501 18.2499 20.9201 18.2499Z" fill={color} />
        </svg>

    )
}

