import {api} from '@/shared/api/api'
import {flow, types} from 'mobx-state-tree'

const KkmModel = types.model('KkmModel', {
    totalItems: types.number,
    itemsPerPage: types.number,
    totalPages: types.number,
    currentPage: types.number,
    currentKkm: types.frozen(),
    loading: types.boolean,
    breadCrumbs: types.frozen(),
    operationList: types.model({
        list: types.array(types.frozen()),
        totalItems: types.number,
        itemsPerPage: types.number,
        totalPages: types.number,
        currentPage: types.number,
    }),
    transaction: types.frozen(),
    kkmList: types.array(types.frozen()),
    changeList: types.array(types.frozen()),
    shiftList: types.model({
        list: types.array(types.frozen()),
        totalItems: types.number,
        itemsPerPage: types.number,
        totalPages: types.number,
        currentPage: types.number,
    }),
    state: types.maybeNull(types.frozen()),
    initHistoryList: types.array(types.frozen()),
    billingHistoryList: types.array(types.frozen()),
    registrationHistoryList: types.array(types.frozen()),
    lastShift: types.array(types.frozen()),
    ticket: types.frozen(),
}).actions(self => ({
    fetchAll: flow(function* (data?, params?) {
        if (!data) {
            self.totalItems = 0
            self.itemsPerPage = 15
            self.totalItems = 0
            self.totalPages = 0
            self.kkmList.length = 0
            return

        }
        try {
            self.loading = true
            const result = yield api.getKkmList(data, params)
            self.totalItems = result.totalItems
            self.itemsPerPage = result.itemsPerPage
            self.totalPages = result.totalPages
            self.currentPage = result.currentPage
            self.kkmList = result.kkmList
            return result.kkmList
        } catch (e) {
            throw e
        } finally {
            self.loading = false
        }

    }),
    fetchById: flow(function* (id: string) {
        self.loading = true
        try {
            const data = yield api.getKkmById(id)
            self.currentKkm = data
            return data
        } catch (e) {
            throw e
        }finally {
            self.loading = false
        }


    }),
    fetchChangesList: flow(function* (params) {
        const data = yield api.getKkmChangeList(params)
        self.changeList = data.kkmFieldsHistoryList
    }),
    fetchInisHistoryList: flow(function* (params) {
        const data = yield api.getKkmInisHistoryList(params)
        self.initHistoryList = data
    }),
    clear() {
        self.kkmList.splice(0, self.kkmList.length)
        self.transaction = {}
        self.operationList.list.splice(0, self.operationList.list.length)
        self.loading = false
        self.currentPage = 1
        self.itemsPerPage = 15
        self.totalItems = 0
        self.totalPages = 0
    },
    fetchShiftListById: flow(function* (kkm_id: number, params) {
        self.shiftList = yield api.getShiftList(kkm_id, params)
    }),
    fetchOperationListById: flow(function* (kkm_id: number, shift_number: number, params: any) {
        self.operationList = yield api.getOperationList(kkm_id, shift_number, params)
    }),
    fetchLastShift: flow(function* (kkmId: number) {
        const response = yield  api.getLastShift(kkmId)
        self.lastShift = [response] as any
    }),
    fetchTransactionById: flow(function* (transaction_id: string, transaction_date: string) {
        const response = yield api.getTransactionById(transaction_id, transaction_date)
        self.transaction = response
    }),
    fetchBreadCrumbs: flow(function* (kkmId: string) {
        const response = yield  api.getTaxpayerByIdKkm(kkmId)
        self.breadCrumbs = response
    }),
    kkmChange: flow(function* (id: number, kgdId: string, manufactureYear: number, serialNum: string, model: string) {
        const response = yield api.changeKkm(id, kgdId, manufactureYear, serialNum, model)
        return response
    }),
    fetchBillingHistory: flow(function* (kkmId: number) {
        const response = yield api.getBillingHistoryList(kkmId)
        self.billingHistoryList = response.kkmStatusHistoryList
    }),
    fetchRegistrationStatusHistory: flow(function* (kkmId: number) {
        const response = yield api.getRegistrationStatusHistory(kkmId)
        self.registrationHistoryList = response.kkmStatusHistoryList
    }),
    fetchTicket: flow(function* (transaction_id: string, transaction_date: string) {
        const response = yield api.getTicket(transaction_id, transaction_date)
        const obj = {
            requestBody: JSON.parse(response.requestBody),
            responseBody: JSON.parse(response.responseBody)
        }
        self.ticket = obj
    }),
    blockUnblock: flow(function* (inactiveReasonText: string, kkmId: number, isActive: boolean) {
        return yield api.blockUnblockKkm({
            inactiveReasonText,
            kkmId,
            isActive,
        })
    }),
    blockUnblockTraffic: flow(function* (kkmId: number, isActive: boolean) {
        return yield api.blockUnblockTrafficKkm({kkmId, isActive})
    }),
    fetchState: flow(function* (kkmId: number) {
        self.state = yield api.getStateKkm(kkmId)
    }),
    changeRegistrationStatus: flow(function* (kkmId: number, statusId: number) {
        return yield api.changeRegistrationStatusKkm(kkmId, statusId)
    }),
    changeBillingStatus: flow(function* (kkmId: number, statusId: number) {
        return yield api.changeBillingStatusKkm(kkmId, statusId)
    })
}))

export const KkmStore = KkmModel.create({
    loading: false,
    kkmList: [],
    currentPage: 1,
    itemsPerPage: 15,
    totalItems: 0,
    totalPages: 0,
    shiftList: {
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 10,
        currentPage: 1,
        list: []
    },
    operationList: {
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 10,
        currentPage: 1,
        list: []
    }
})
