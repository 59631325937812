import React, { FC } from 'react'
import { IModalProps } from '@/shared/types/ModalProps'
import { Modal } from './Modal'
import Styles from './Modal.module.scss'
import classNames from 'classnames'
import { SuccessIcon } from '@/assets/icons/SuccessIcon'
import { useAutoCloseModal } from '@/shared/hooks'


interface IModalSuccess extends IModalProps {
    wrapperClassName?: string;
}

export const ModalSuccess: FC<IModalSuccess> = ({ children, onClose, className, wrapperClassName }) => {
    useAutoCloseModal(onClose)

    return (
        <Modal onClose={onClose} className={classNames(Styles.success, wrapperClassName)}>
            <div className={classNames('flex column a-center', className)}>
                <SuccessIcon />
                <div className={classNames(Styles.message, 'mt-11')}>
                    {children}
                </div>
            </div>
        </Modal >
    )
}
