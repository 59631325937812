import { FC } from 'react'
import classNames from 'classnames';
import Styles from './Card.module.scss'

interface CardProps {
  className?: string;
  children?: React.ReactNode;
  style?: object;
  onClick?: () => void;
}

export const Card: FC<CardProps> = ({ className, children, style, onClick }) => {
  return (
    <div className={classNames(Styles.card, className)} style={style} onClick={onClick}>
      {children}
    </div>
  )
}

