import { FC } from 'react'
import { IconProp } from './iconType'
export const DecoderIcon: FC<IconProp> = ({ color }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.54 21.94C10.31 21.94 10.09 21.84 9.94003 21.64L8.93002 20.29C8.72002 20.01 8.44005 19.85 8.14005 19.83C7.84004 19.81 7.54002 19.95 7.30002 20.2C5.85002 21.75 4.75003 21.62 4.22003 21.42C3.68003 21.21 2.77002 20.52 2.77002 18.3V7.04001C2.77002 2.60001 4.05002 1.25 8.24002 1.25H15.79C19.98 1.25 21.26 2.60001 21.26 7.04001V11.3C21.26 11.71 20.92 12.05 20.51 12.05C20.1 12.05 19.76 11.71 19.76 11.3V7.04001C19.76 3.43001 19.13 2.75 15.79 2.75H8.24002C4.90002 2.75 4.27002 3.43001 4.27002 7.04001V18.3C4.27002 19.35 4.53002 19.93 4.77002 20.02C4.95002 20.09 5.44004 19.99 6.20004 19.18C6.75004 18.6 7.47003 18.29 8.22003 18.33C8.96003 18.37 9.66004 18.76 10.13 19.39L11.15 20.74C11.4 21.07 11.33 21.54 11 21.79C10.85 21.9 10.69 21.94 10.54 21.94Z" fill={color} />
            <path d="M18.2 22.15C16.02 22.15 14.25 20.38 14.25 18.2C14.25 16.02 16.02 14.25 18.2 14.25C20.38 14.25 22.15 16.02 22.15 18.2C22.15 20.38 20.38 22.15 18.2 22.15ZM18.2 15.75C16.85 15.75 15.75 16.85 15.75 18.2C15.75 19.55 16.85 20.65 18.2 20.65C19.55 20.65 20.65 19.55 20.65 18.2C20.65 16.85 19.55 15.75 18.2 15.75Z" fill={color} />
            <path d="M21.9999 22.7499C21.8099 22.7499 21.6199 22.6799 21.4699 22.5299L20.4699 21.5299C20.1799 21.2399 20.1799 20.7599 20.4699 20.4699C20.7599 20.1799 21.2399 20.1799 21.5299 20.4699L22.5299 21.4699C22.8199 21.7599 22.8199 22.2399 22.5299 22.5299C22.3799 22.6799 22.1899 22.7499 21.9999 22.7499Z" fill={color} />
            <path d="M16 7.75H8C7.59 7.75 7.25 7.41 7.25 7C7.25 6.59 7.59 6.25 8 6.25H16C16.41 6.25 16.75 6.59 16.75 7C16.75 7.41 16.41 7.75 16 7.75Z" fill={color} />
            <path d="M15 11.75H9C8.59 11.75 8.25 11.41 8.25 11C8.25 10.59 8.59 10.25 9 10.25H15C15.41 10.25 15.75 10.59 15.75 11C15.75 11.41 15.41 11.75 15 11.75Z" fill={color} />
        </svg>

    )
}

