import { DangerIcon } from '@/assets/icons/DangerIcon';
import { Modal } from '@/shared/ui/Modal';
import { useAutoCloseModal } from '@/shared/hooks';
import React, { FC } from 'react'

interface IProps {
    onClose: () => void;
    title?: string;
    subtitle?: string;
}

export const ResponseErrorModal: FC<IProps> = ({ onClose, title, subtitle }) => {

    useAutoCloseModal(onClose)

    return (
        <Modal onClose={onClose} className="py-40 px-38">
            <div className="flex column a-center">
                <div className='mb-7'>
                    <DangerIcon width='50' height='50' />
                </div>
                {
                    title && <div className='c_first_color fs-18 fw-500 mb-7'>
                        {title}
                    </div>
                }
                {
                    subtitle &&
                    <div className='c_first_color fs-18'>
                        {subtitle}
                    </div>
                }

            </div>
        </Modal>
    )
}
