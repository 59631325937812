import { FC } from 'react'
import { IconProp } from './iconType'
export const RolesIcon: FC<IconProp> = ({ color }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.9604 21.32C15.7704 21.32 15.5804 21.25 15.4304 21.1L13.9104 19.58C13.6204 19.29 13.6204 18.81 13.9104 18.52C14.2004 18.23 14.6804 18.23 14.9704 18.52L15.9604 19.51L18.4704 17C18.7604 16.71 19.2404 16.71 19.5304 17C19.8204 17.29 19.8204 17.77 19.5304 18.06L16.4904 21.1C16.3404 21.25 16.1504 21.32 15.9604 21.32Z" fill={color} />
            <path d="M12.1602 11.62C12.1302 11.62 12.1102 11.62 12.0802 11.62C12.0302 11.61 11.9602 11.61 11.9002 11.62C9.00019 11.53 6.8102 9.25 6.8102 6.44C6.8002 5.06 7.34019 3.76 8.32019 2.78C9.30019 1.8 10.6002 1.25 11.9902 1.25C14.8502 1.25 17.1802 3.58 17.1802 6.44C17.1802 9.25 14.9902 11.52 12.1902 11.62C12.1802 11.62 12.1702 11.62 12.1602 11.62ZM11.9902 2.75C11.0002 2.75 10.0802 3.14 9.3802 3.83C8.6902 4.53 8.3102 5.45 8.3102 6.43C8.3102 8.43 9.87019 10.05 11.8602 10.11C11.9202 10.1 12.0502 10.1 12.1802 10.11C14.1502 10.02 15.6802 8.41 15.6802 6.43C15.6802 4.41 14.0202 2.75 11.9902 2.75Z" fill={color} />
            <path d="M11.9902 22.5601C9.95016 22.5601 8.02016 22.0301 6.56016 21.0501C5.17016 20.1201 4.41016 18.8501 4.41016 17.4801C4.41016 16.1101 5.18016 14.8501 6.56016 13.9301C9.55016 11.9301 14.4102 11.9301 17.4002 13.9301C17.7402 14.1601 17.8402 14.6301 17.6102 14.9701C17.3802 15.3201 16.9102 15.4101 16.5702 15.1801C14.0802 13.5201 9.88016 13.5201 7.39016 15.1801C6.43016 15.8201 5.91016 16.6301 5.91016 17.4801C5.91016 18.3301 6.43016 19.1601 7.39016 19.8001C8.60016 20.6101 10.2302 21.0501 11.9802 21.0501C12.3902 21.0501 12.7302 21.3901 12.7302 21.8001C12.7302 22.2101 12.4002 22.5601 11.9902 22.5601Z" fill={color} />
        </svg>
    )
}

