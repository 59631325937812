import React, { FC } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/ru';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material'
import classNames from 'classnames';
import Styles from './DateFilter.module.scss'
import { Dayjs } from 'dayjs';
import { CalendarIcon } from '@/assets/icons/CalendarIcon';

interface IDateFilter {
    value: Dayjs | null;
    setValue: (value: Dayjs | null) => void;
    className?: string;
}

export const DateFilter: FC<IDateFilter> = ({ value, setValue, className }) => {
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
                <DatePicker
                    components={{
                        OpenPickerIcon: CalendarIcon
                    }}
                    inputFormat='гггг-мм-дд'
                    className={classNames(Styles.datePicker, className)}
                    value={value}
                    onChange={(newValue) => setValue(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        </>

    )
}
