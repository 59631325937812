import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {PageTitle} from '@/shared/ui/PageTitle'
import {Button} from '@/shared/ui/Buttons'
import {Card} from '@/shared/ui/Card'
import {InputSearchIcon} from '@/shared/ui/Inputs'
import classNames from 'classnames'
import Styles from './RolesPage.module.scss'
import {TableView} from '@/shared/ui/Tables'
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from '@tanstack/react-table'
import {EyeIcon} from '@/assets/icons/EyeIcon'
import {ContextMenu, ContextMenuItem} from '@/shared/ui/Menu'
import {TrashIcon} from '@/assets/icons/TrashIcon'
import {EditIcon} from '@/assets/icons/EditIcon'
import {CardRoleModal} from '@/modals/Role/CardRoleModal'
import {DeleteRoleModal} from '@/modals/Role/DeleteRoleModal'
import {TypeModal} from '@/modals/Role/types'
import theme from '@/shared/themes'
import {ButtonRefresh} from '@/shared/ui/Buttons'
import {IRole} from '@/shared/types/GlobalTypes'
import {RolesStore} from '@/store/RolesStroe'
import {getSnapshot} from 'mobx-state-tree'
import {observer} from 'mobx-react-lite'
import {useSearchParams} from 'react-router-dom'
import {PageNoData} from '@/shared/ui/PageNoData'
import {SearchNoResult} from '@/shared/ui/SearchNoResult'
import {Paginate} from '@/shared/ui/Paginate'
import {PermissionsStore} from '@/store/PermissionsStore'
import {Preload} from '@/shared/ui/Preload'
import {formatDate} from "@/shared/lib";

const columnHelper = createColumnHelper<IRole>()

export const RolesPage: FC = observer(() => {

        const [searchParams, setSearchParams] = useSearchParams()
        const page = searchParams.get('page')
        const size = searchParams.get('size')
        const searchQueryParam = searchParams.get("search")
        const searchInput = useRef<HTMLInputElement>(null)
        const [sorting, setSorting] = useState<SortingState>([])
        const fetchData = useCallback(() =>
                RolesStore.fetchAll({name: searchQueryParam}, {page, size})
            , [size, page, searchQueryParam])

        useEffect(() => {
            PermissionsStore.fetchAllSections()
        }, [])
        useEffect(() => {
            if (searchInput.current) {
                setSearchQuery(searchQueryParam || "")
            }
        }, [searchInput, searchQueryParam])
        useEffect(() => {
            fetchData()
            if (Number(size) > 100) {
                setSearchParams(params => {
                    params.set("size", "10")
                    return params
                })
            }
        }, [size, page, searchQueryParam, fetchData])


        /*modals*/
        const [selectedRoleId, setSelectedRoleId] = useState<null | number>(null)
        const [cardRoleModal, setCardRoleModal] = useState<boolean>(false)
        const [deleteRoleModal, setDeleteRoleModal] = useState<boolean>(false)
        const [currentTypeModal, setCurrentTypeModal] = useState<TypeModal>(TypeModal.CREATE)
        /* */
        // const [data, setData] = useState(rootStore.roles)
        const [searchQuery, setSearchQuery] = useState("")

        const handleSearch = () => {
            const searchQuery = searchInput.current?.value
            setSearchParams({
                search: searchQuery || ""
            })
        }
        const onPaginate = (event: React.ChangeEvent<unknown>, page: Number) => {
            setSearchParams(params => {
                params.set("page", page.toString())
                return params
            })
        }

        const onChangeSelectSize = (size: number) => {
            setSearchParams(params => {
                params.set("size", size.toString())
                return params
            })
        }

        const handleReset = () => {
            if (searchInput.current) {
                searchInput.current.value = ""
                setSearchQuery("")
            }
            setSearchParams({})
        }
        const openCardModal = (type: TypeModal, id?: number): void => {
            setSelectedRoleId((state) => id ? id : state)
            setCurrentTypeModal(type)
            setCardRoleModal(true)
        }

        const isReadOnly = useCallback((id: number): React.ReactElement => {
                return (
                    <div className='flex j-center pr-15'>
                        {
                            RolesStore.role(id)?.basic
                                ?
                                <div onClick={() => openCardModal(TypeModal.VIEW, id)}>
                                    <EyeIcon/>
                                </div>
                                :
                                <ContextMenu>
                                    <ContextMenuItem onClick={() => openCardModal(TypeModal.VIEW, id)}>
                                        <div className="flex a-center">
                                            <div className='flex a-center mr-10'>
                                                <EyeIcon color={theme.firstColor}/>
                                            </div>
                                            <span>
                    Просмотр роли
                  </span>
                                        </div>
                                    </ContextMenuItem>
                                    <ContextMenuItem onClick={() => openCardModal(TypeModal.EDIT, id)}>
                                        <div className="flex a-center">
                                            <div className="flex a-center mr-10">
                                                <EditIcon/>
                                            </div>
                                            <span>
                    Редактировать роль
                  </span>
                                        </div>
                                    </ContextMenuItem>
                                    <ContextMenuItem onClick={() => {
                                        setDeleteRoleModal(true)
                                        setSelectedRoleId(id)
                                    }
                                    }>
                                        <div className="flex a-center">
                                            <div className="flex a-center mr-10">
                                                <TrashIcon color={theme.deleteColor} width='15' height='16'/>
                                            </div>
                                            <div className='c_danger_color'>
                                                Удалить роль
                                            </div>
                                        </div>

                                    </ ContextMenuItem>
                                </ContextMenu>
                        }
                    </div>

                )
            }
            , [])

        const columns = useMemo(() =>
                [
                    columnHelper.accessor('createdDate', {
                        header: 'Дата создания',
                        cell: ({getValue}) => formatDate(getValue())
                    }),
                    columnHelper.accessor('name', {
                        header: 'Название роли'
                    }),
                    columnHelper.accessor('author', {
                        header: 'Автор',
                        cell: ({getValue}) => `${getValue().firstName} ${getValue().lastName}`
                    }),
                    columnHelper.accessor('basic', {
                        header: 'Категория',
                        cell: ({getValue}) => getValue() ? 'Базовая роль' : 'Пользователськая роль'
                    }),
                    columnHelper.accessor('code', {
                        header: 'Код роли'
                    }),
                    columnHelper.display({
                        id: 'id',
                        cell: (props) => isReadOnly(props.row.original.id)
                    })
                ]
            , [isReadOnly])

        const tableInstance = useReactTable({
            data: getSnapshot(RolesStore.roles),
            columns,
            state:{
              sorting
            },
            onSortingChange: setSorting,
            getSortedRowModel: getSortedRowModel(),
            getCoreRowModel: getCoreRowModel()
        })

        return (
            <>
                <Card className='py-30 pl-27 pr-24 mb-12'>
                    <PageTitle className='mb-30'>
                        Конструктор ролей
                    </PageTitle>
                    <div className="flex j-between a-center">
                        <InputSearchIcon
                            id='search-input'
                            ref={searchInput}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className={classNames('input w-70')}
                            placeholder='Поиск роли'>
                        </InputSearchIcon>
                        <div className="flex mr-20">
                            <Button className={classNames(Styles.searchButton)} onClick={handleSearch}>
                                Найти
                            </Button>
                        </div>
                        <ButtonRefresh onClick={handleReset}/>
                        <div className="flex grow j-end">
                            <Button onClick={() => openCardModal(TypeModal.CREATE)} className={classNames(Styles.button)}>
                                <div className="flex j-center a-center">
                                    <span className='mr-15 fs-20 plus'>+</span>
                                    <span>Создать роль</span>
                                </div>
                            </Button>
                        </div>

                    </div>
                </Card>
                {
                    !RolesStore.roles.length && !searchInput.current?.value
                        ?
                        <PageNoData/> :
                        !RolesStore.roles.length && searchInput.current?.value ?
                            <SearchNoResult searchQuery={searchInput.current?.value}/> :
                            <Card>
                                <TableView tableInstance={{...tableInstance}}
                                           className={classNames('table-border-radius table-left')}/>
                            </Card>
                }
                {
                    RolesStore.roles.length ?
                        <Paginate
                            onChange={onPaginate}
                            currentPage={RolesStore.currentPage}
                            countPages={RolesStore.totalPages}
                            size={size}
                            onChangeSelectSize={onChangeSelectSize}
                        /> : ''
                }
                {
                    cardRoleModal &&
                    <CardRoleModal fetchData={fetchData} id={selectedRoleId} type={currentTypeModal} onClose={() => {
                        setSelectedRoleId(null);
                        setCardRoleModal(false)
                    }}/>
                }

                {
                    deleteRoleModal &&
                    <DeleteRoleModal id={selectedRoleId} onClose={() => {
                        setDeleteRoleModal(false)
                        setSelectedRoleId(null)
                    }}/>
                }
                {
                    RolesStore.loading && <Preload/>
                }
            </>


        )
    }
)
