
const theme = {
    name: 'main',
    mainColor: '#00ADEF',
    mainHoverColor: '#077AB3',
    mainPressColor: '#064A6B',
    mainLinkColor: '#758EA8',
    bgColor: '#F1F3F9',
    deleteColor: '#D94040',
    deleteHoverColor: '#811D1D',
    deletePressColor: '#4E0404',
    doneColor: '#4AB568',
    firstColor: '#4A4A4A',
    secondColor: '#A5A5A5',
    thirdColor: '#E6E7E9',
    fourthColor: '#F9F9F9',
    fifthColor: '#FFFFFF',
}
export default theme;
