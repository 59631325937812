import {useAutosizeTextarea} from '@/shared/hooks';
import classNames from 'classnames';
import React, {FC, useRef} from 'react';
import Styles from './Styles.module.scss';
import {InputError} from "@/shared/ui/Inputs/InputError";

interface IProps {
    value?: string;
    onChange?: (value: string) => void;
    className?: string;
    error?: string;
    placeholder?: string;
    onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
}

export const Textarea: FC<IProps> = ({
                                         value,
                                         onChange,
                                         className,
                                         error,
                                         placeholder,
                                         onBlur
                                     }) => {
    const refTextarea = useRef<HTMLTextAreaElement | null>(null);
    useAutosizeTextarea(refTextarea.current, value)
    return (
        <>
            <textarea onBlur={onBlur} placeholder={placeholder} value={value} ref={refTextarea}
                      onChange={(event) => onChange && onChange(event.target.value)}
                      className={classNames(Styles.textarea, className)}/>
            {
                error && <InputError error={error}/>
            }
        </>
    )
}

