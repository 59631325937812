import theme from '@/shared/themes'
import { FC } from 'react'

interface IRefreshIcon {
    color?: string;
}

export const RefreshIcon: FC<IRefreshIcon> = ({ color = theme.secondColor }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 18.9583C5.66669 18.9583 2.15002 15.4333 2.15002 11.1083C2.15002 6.78333 5.66669 3.25 10 3.25C10.8917 3.25 11.7584 3.375 12.5917 3.63333C12.925 3.73333 13.1084 4.08333 13.0084 4.41667C12.9084 4.75 12.5584 4.93333 12.225 4.83333C11.5167 4.61667 10.7667 4.5 10 4.5C6.35836 4.5 3.40002 7.45833 3.40002 11.1C3.40002 14.7417 6.35836 17.7 10 17.7C13.6417 17.7 16.6 14.7417 16.6 11.1C16.6 9.78333 16.2167 8.51667 15.4917 7.43333C15.3 7.15 15.375 6.75833 15.6667 6.56667C15.95 6.375 16.3417 6.45 16.5334 6.74167C17.4 8.03333 17.8584 9.54167 17.8584 11.1083C17.85 15.4333 14.3334 18.9583 10 18.9583Z" fill={color} />
            <path d="M13.4417 5.0583C13.2667 5.0583 13.0917 4.9833 12.9667 4.84164L10.5583 2.07497C10.3333 1.81664 10.3583 1.41664 10.6167 1.19164C10.875 0.966637 11.275 0.991637 11.5 1.24997L13.9083 4.01664C14.1333 4.27497 14.1083 4.67497 13.85 4.89997C13.7417 5.0083 13.5917 5.0583 13.4417 5.0583Z" fill={color} />
            <path d="M10.6333 7.10834C10.4417 7.10834 10.25 7.01667 10.125 6.85C9.92501 6.575 9.98335 6.18334 10.2583 5.975L13.0667 3.925C13.3417 3.71667 13.7333 3.78334 13.9417 4.05834C14.15 4.33334 14.0833 4.725 13.8083 4.93334L11 6.99167C10.8917 7.075 10.7667 7.10834 10.6333 7.10834Z" fill={color} />
        </svg>

    )
}

