import {IModalProps} from "@/shared/types/ModalProps";


export enum TypeModal {
    CREATE = 'Создание новой роли',
    EDIT = 'Редактирование роли',
    VIEW = 'Карточка роли'
}


export interface AccordionItem {
    id: number,
    nameRu: string,
    nameKz: string,
}

export interface AccordionRootItem extends AccordionItem {
    expanded: boolean,
    permissions: AccordionChildItem[]
}

export interface AccordionChildItem {
    checked: boolean;
    id: number,
    code: string,
    name: string,
    description: string;
    relatedPermissions: number[];
}

export interface IRoleModalProps extends IModalProps {
    type: TypeModal;
    fetchData: () => void;
}
