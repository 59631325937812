import theme from "@/shared/themes";
import { makeStyles } from "@mui/styles";

export const useStylesAccordion = makeStyles({
    MuiAccordionroot: {
        "&.MuiAccordion-root:before": {
            backgroundColor: "white"
        },
        fontSize: "14px",
        marginBottom: 2,
        boxShadow: 'none',
        padding: 0,
    },
    MuiAccordionExpanded: {
        margin: "0 !important",
    },
    MuiAccordionSummaryRoot: {
        border: `1px solid ${theme.thirdColor}`,
        borderRadius: 5,
        height: 40,
        minHeight: 'initial !important',
    },
    MuiAccordionSummaryContent: {
        padding: 10,
    },
    MuiAccordionDetailsRoot: {
        padding: "12px 24px 12px 24px",

    }

}
)
