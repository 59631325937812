import {FC, useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {Input, InputPassword} from '@/shared/ui/Inputs'
import {Modal, ModalHeader} from '@/shared/ui/Modal'
import {IModalProps} from '@/shared/types/ModalProps'
import {NewUserIcon} from '@/assets/icons/NewUserIcon'
import {Button} from '@/shared/ui/Buttons'
import {ModalSuccess} from '@/shared/ui/Modal'
import passfather from 'passfather'
import Styles from './Styles.module.scss'
import {Checkbox} from '@/shared/ui/Checkbox'
import classNames from 'classnames'
import {Select} from '@/shared/ui/Select'
import {useSelect} from '@/shared/hooks'
import {UsersStore} from '@/store/UsersStore.'
import {RolesStore} from '@/store/RolesStroe'
import {toast} from 'react-toastify'
import axios from 'axios'
import {IErrorMessage} from '@/shared/api/instanceAxios'
import {autorun} from 'mobx'

export enum TypeModal {
    CREATE = 'Добавление нового пользователя',
    EDIT = 'Редактирование данных пользователя',
    VIEW = 'Карточка пользователя'
}


interface ICardUserProps extends IModalProps {
    type?: null | TypeModal,
    onEdit: () => void;
    fetchData: () => void;
}

export const CardUserModal: FC<ICardUserProps> = ({onClose, type, id, onEdit, fetchData}) => {


    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        control,
        formState: {
            errors,
        },
    } = useForm({
        mode: 'onBlur'
    })

    const status = useSelect([])

    useEffect(() => {
        RolesStore.fetchListForSelect()
    }, [])

    useEffect(() => {
        if (id) {
            status.setOptions([
                {
                    id: 1,
                    checked: !UsersStore.user(id)?.blocked,
                    label: 'Активен'
                },
                {
                    id: 2,
                    checked: UsersStore.user(id)?.blocked,
                    label: 'Деактивен'
                }
            ])
        }
    }, [id])


    const {
        getLabelOption,
        handleChangeOption,
        options,
        setOptions
    } = useSelect([])

    useEffect(() => {
        autorun(() => {
            setOptions([...RolesStore.rolesForSelect?.map(item => {
                return {
                    id: item.id,
                    label: item.name,
                    checked: item.id === UsersStore.user(id)?.role?.id
                }
            })])
        })
    }, [])

    useEffect(() => {
        if (id) {
            setValue('password', UsersStore.user(id).password)
            setValue('fullName', UsersStore.user(id).fullName)
            setValue('phone', UsersStore.user(id).phone)
            setValue('email', UsersStore.user(id).email)
        } else {
            setValue('password', passfather({
                numbers: true,
                uppercase: true,
                lowercase: true,
                symbols: true,
                length: 8
            }))
        }
    }, [])

    useEffect(() => {
        if (id) {
            setValue('role', options.find(role => role.checked)?.id)
        }
    }, [options])


    const onSubmit = async (data: any) => {
        try {
            const fullName = data.fullName.trim()
            const obj = {
                ...data,
                role: RolesStore.rolesForSelect.find(item => item.id === (options.find(item => item.checked)?.id as number)),
                fullName,
                blocked: status.options.find(item => item.checked)?.id === 2
            }
            if (!getValues('password')) delete obj.password
            if (id) {
                await UsersStore.editUser(id, obj)
            } else {
                await UsersStore.addUser(obj)
            }
            fetchData()
            setSuccessAddUser(true)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const response = await e.response?.data as IErrorMessage
                toast.error(response?.details?.join(" "), {
                    position: 'bottom-right'
                })
            }
        }
    }

    /***Modals***/
    const [successAddUser, setSuccessAddUser] = useState(false)

    return (
        <>
            {
                successAddUser ?
                    <ModalSuccess className='pt-8 pb-28 c_first_color' onClose={onClose}>
                        {id ? "Изменения сохранены!" : "Пользователь добавлен!"}
                    </ModalSuccess>
                    :
                    <Modal className={classNames(Styles.modal)} onClose={onClose}>
                        <ModalHeader title={type as string} onClose={onClose}>
                            <NewUserIcon/>
                        </ModalHeader>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="modal-form">
                                <label className='label'>
                                    <span>Автор</span>
                                    <Input color='disabled' disabled className='--fluid'
                                           defaultValue={UsersStore.user(id)?.author}/>
                                </label>
                                <label className='label'>
                                    <span>ФИО {type !== TypeModal.VIEW && <span className='required'>*</span>}</span>
                                    <Input
                                        className='--fluid'
                                        {
                                            ...register('fullName', {
                                                required: {
                                                    value: true,
                                                    message: 'Это поле обязательно для заполнения'
                                                },
                                            })
                                        }
                                        disabled={type === TypeModal.VIEW}
                                        error={errors.fullName?.message as string}
                                        placeholder='Фамилия Имя Отчество пользователя'/>
                                </label>
                                <div className="flex">
                                    <label
                                        className={classNames("label mr-12", type !== TypeModal.VIEW ? "column" : "grow")}>
                                        <span>Адрес e-mail {(type === TypeModal.CREATE) &&
                                            <span className='required'>*</span>}</span>
                                        <Input
                                            {
                                                ...register('email', {
                                                    required: {
                                                        value: true,
                                                        message: 'Это поле обязательно для заполнения'
                                                    },
                                                    pattern: {
                                                        value: /^[_a-z0-9-\+-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i,
                                                        message: 'Введите корректный адрес электронной почты'
                                                    },
                                                })
                                            }
                                            disabled={type === TypeModal.VIEW || type === TypeModal.EDIT}
                                            error={errors.email?.message as string}
                                            className='--fluid'
                                            type='email'
                                            placeholder='Придумайте логин или укажите e-mail'/>
                                    </label>
                                    {
                                        type !== TypeModal.VIEW &&
                                        <label className='label column'>
                                        <span>Пароль {type === TypeModal.CREATE &&
                                            <span className='required'>*</span>}</span>
                                            <InputPassword
                                                {
                                                    ...register('password', {
                                                        required: {
                                                            value: type === TypeModal.CREATE,
                                                            message: 'Это поле обязательно для заполнения'
                                                        },
                                                        pattern: {
                                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s]).{6,}/,
                                                            message: 'Пароль должен состоять хотя бы из 6 символов и содержать минимум одну цифру, одну заглавную и одну строчную буквы(англ.), один спец символ'
                                                        }
                                                    })
                                                }
                                                visible={true}
                                                error={errors.password?.message as string}
                                                placeholder='Придумайте надежный пароль'/>
                                        </label>

                                    }

                                </div>
                                <div className="flex">
                                    <label className='label column mr-12'>
                                        <span>Номер телефона {type !== TypeModal.VIEW &&
                                            <span className='required'>*</span>}</span>
                                        <Input
                                            disabled={type === TypeModal.VIEW}
                                            masked
                                            mask="+9 (999) 999-99-99"
                                            maskPlaceholder=""
                                            placeholder='+7 (---) --- -- --'
                                            {
                                                ...register('phone', {
                                                    required: {
                                                        value: true,
                                                        message: 'Это поле обязательно для заполнения'
                                                    },
                                                    minLength: {
                                                        value: 18,
                                                        message: 'Введите корректный номер телефона'
                                                    }
                                                })
                                            }
                                            error={errors.phone?.message as string}
                                        />

                                    </label>
                                    <label className="label column">
                                        <span>Роль {type !== TypeModal.VIEW &&
                                            <span className='required'>*</span>}</span>
                                        <Controller
                                            control={control}
                                            name="role"
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "Это поле обязательно для заполнения"
                                                }
                                            }}
                                            render={({field, fieldState}) =>
                                                <Select
                                                    forbidOpening={type === TypeModal.VIEW}
                                                    contentClassName={Styles.selectContent}
                                                    className={Styles.select}
                                                    label={getLabelOption()}
                                                    onBlur={field.onBlur}
                                                    ref={field.ref}
                                                    closeOnSelect={false}
                                                    error={fieldState.error?.message}
                                                    clickOutside>
                                                    {
                                                        options.map(item =>
                                                            <li key={item.id}
                                                                className={classNames('option', item.checked && 'active')}>
                                                                <label>
                                                                    <Checkbox
                                                                        onChange={() => {
                                                                            field.onChange(item.id)
                                                                            handleChangeOption(item.id)
                                                                        }}
                                                                        className='mr-10'
                                                                        checked={item.checked}
                                                                    />
                                                                    {item.label}
                                                                </label>
                                                            </li>
                                                        )
                                                    }
                                                </Select>
                                            }
                                        />

                                    </label>
                                </div>
                                {
                                    (type === TypeModal.VIEW || type === TypeModal.EDIT) &&
                                    <label className="label">
                                        <span>Статус</span>
                                        <Select
                                            forbidOpening={type === TypeModal.VIEW}
                                            contentClassName={Styles.selectContent}
                                            className="w-100"
                                            label={status.getLabelOption()}
                                            closeOnSelect={false}
                                            clickOutside>
                                            {
                                                status.options.map(item =>
                                                    <li key={item.id}
                                                        className={classNames('option', item.checked && 'active')}>
                                                        <label>
                                                            <Checkbox
                                                                onChange={() => status.handleChangeOption(item.id)}
                                                                className='mr-10'
                                                                checked={item.checked}
                                                            />
                                                            {item.label}
                                                        </label>
                                                    </li>
                                                )
                                            }
                                        </Select>
                                    </label>
                                }

                                <div className="flex j-end">
                                    <Button color='cancel' className='mr-18 button' onClick={onClose}>
                                        Отмена
                                    </Button>
                                    {
                                        (type === TypeModal.EDIT || type === TypeModal.CREATE) &&
                                        <Button className='button' type='submit'>
                                            {
                                                type === TypeModal.EDIT ? 'Сохранить' : 'Добавить'

                                            }
                                        </Button>
                                    }
                                    {
                                        type === TypeModal.VIEW &&
                                        <Button onClick={onEdit} className='button' type='button'>
                                            Редактировать
                                        </Button>
                                    }

                                </div>

                            </div>
                        </form>
                    </Modal>
            }
        </>

    )
}
