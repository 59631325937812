import React, {FC, useMemo, useState} from 'react'
import {PageTitle} from '@/shared/ui/PageTitle'
import {Card} from '@/shared/ui/Card'
import classNames from 'classnames'
import {useSearchParams} from 'react-router-dom'
import {observer} from 'mobx-react-lite'
import {IIbd} from "@/shared/api/ibdServices";
import {SearchIbd} from "@/features/Ibd";
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from "@tanstack/react-table";
import {formatDate} from "@/shared/lib/formatDate";
import {getSnapshot} from "mobx-state-tree";
import {IbdStore} from "@/store/IbdStore";
import {PageNoData} from "@/shared/ui/PageNoData";
import {SearchNoResult} from "@/shared/ui/SearchNoResult";
import {TableView} from "@/shared/ui/Tables";
import style from './IbdPage.module.scss'
import {Preload} from "@/shared/ui/Preload";
import {Paginate} from "@/shared/ui/Paginate";

const columnHelper = createColumnHelper<IIbd>()
export const IbdPage: FC = observer(() => {
        const [searchParams, setSearchParams] = useSearchParams()
        const size = searchParams.get('size')
        const [sorting, setSorting] = useState<SortingState>([])
        const [searchInput, setSearchInput] = useState('')
        const onPaginate = (event: React.ChangeEvent<unknown>, page: Number) => {
            setSearchParams(param => {
                param.set("page", page.toString())
                return param
            })
        }

        const onChangeSelectSize = (size: number) => {
            setSearchParams(param => {
                param.set("size", size.toString())
                return param
            })
        }
        const columns = useMemo(() => [
            columnHelper.accessor('guid', {
                header: 'guid',
            }),
            columnHelper.accessor('status', {
                header: 'Статус',
            }),
            columnHelper.accessor('sentAt', {
                header: 'Дата отправки',
                cell: ({getValue}) => getValue() ? formatDate(getValue()) : ""
            }),
            columnHelper.accessor('receivedAt', {
                header: 'Дата получения ответа',
                cell: ({getValue}) => getValue() ? formatDate(getValue()) : ""
            }),
            columnHelper.accessor('handledAt', {
                header: 'Дата обработки ответа ',
                cell: ({getValue}) => getValue() ? formatDate(getValue()) : ""
            }),
        ], [])
        const tableInstance = useReactTable({
            columns,
            state: {
                sorting,
            },
            onSortingChange: setSorting,
            data: getSnapshot(IbdStore.ibdList),
            getCoreRowModel: getCoreRowModel(),
            getSortedRowModel: getSortedRowModel(),
        })
        return (
            <div>
                <Card className={classNames('pt-30 pr-32 pl-27 pb-23 mb-12')}>
                    <PageTitle className={classNames('mb-30')}>
                        Взаимодействие с IOFD
                    </PageTitle>
                    <SearchIbd setSearchInput={setSearchInput}/>
                </Card>
                {
                    (!searchInput && !IbdStore.ibdList.length)
                        ?
                        <PageNoData
                            message="Введите значение для поиска и найдите необходимую информацию о взаимодействии с IOFD"/> :
                        !IbdStore.ibdList.length && searchInput ?
                            <SearchNoResult searchQuery={searchInput || ''}/> :

                            <Card>
                                <TableView
                                    tableInstance={{...tableInstance}}
                                    className={classNames('table-border-radius', 'table-left', style.table)}/>
                            </Card>
                }
                {
                    IbdStore.ibdList?.length ?
                        <Paginate
                            onChange={onPaginate}
                            currentPage={IbdStore.currentPage}
                            countPages={IbdStore.totalPages}
                            size={size}
                            onChangeSelectSize={onChangeSelectSize}
                        /> : ''
                }
                {
                    IbdStore.loading && <Preload/>
                }
            </div>
        )
    }
)
