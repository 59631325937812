import React, {useMemo, useState} from 'react';
import {Card} from "@/shared/ui/Card";
import {TableView} from "@/shared/ui/Tables";
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from "@tanstack/react-table";
import {ITariff, TariffStore} from "@/entities/Tariff/model";
import {observer} from "mobx-react-lite";
import {getSnapshot} from "mobx-state-tree";
import s from './style.module.scss'
import cn from 'classnames'
import {Scrollbars} from "react-custom-scrollbars-2";

const columnHelper = createColumnHelper<ITariff>()
const TariffsTable = observer(() => {
    const [sorting, setSorting] = useState<SortingState>([])
    const COLUMNS = useMemo(() => [
        columnHelper.accessor("nameRu", {
            header: "Название RU"
        }),
        columnHelper.accessor("nameKz", {
            header: "Название KZ"
        }),
        columnHelper.accessor("tariffType", {
            header: "Тип"
        }),
        columnHelper.accessor("extId", {
            header: "Billing ID"
        }),
        columnHelper.accessor("connectionType", {
            header: "Тип подключения"
        }),
        columnHelper.accessor("periodPrice", {
            header: "Стоимость",
            cell: ({getValue, row}) => `${getValue()}₸ / ${row.original.periodUnit}`
        }),
        columnHelper.accessor("kkmModels", {
            header: "Модель ККМ"
        }),
    ], [])

    const tableInstance = useReactTable({
        data: getSnapshot(TariffStore.list),
        state: {
            sorting
        },
        onSortingChange: setSorting,
        columns: COLUMNS,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    })

    return (
        <Card>
            <Scrollbars autoHeight autoHeightMax="100%">
                <TableView tableInstance={{...tableInstance}} className={cn(s.table, 'table-border-radius table-left')}/>
            </Scrollbars>
        </Card>
    );
})

export default TariffsTable;
