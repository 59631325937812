import { PageTitle } from '@/shared/ui/PageTitle';
import { Card } from '@/shared/ui/Card/Card';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { Tabs } from '@/shared/ui/Tabs/Tabs';
import { useEffect, useState } from 'react';
import { TemplateList } from '@/pages/Sms/components/TemplateList';
import { SmsStore } from '@/store/SmsStore';
import { useNavigate, useSearchParams } from 'react-router-dom';

const tabs = ['Шаблоны писем', 'Рассылка'];

export const SmsPage = observer(() => {
	const [activeTab, setActiveTab] = useState(0);
	useEffect(() => {
		SmsStore.fetchTemplates();
	}, []);
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const activeTabParam = searchParams.get('activeTab');
	useEffect(() => {
		if (!activeTabParam) {
			setSearchParams((param) => {
				param.set('activeTab', '0');
				return param;
			});
		}
		setActiveTab(Number(activeTabParam));
	}, [activeTabParam, setSearchParams]);
	const getTemplateList = () => {
		if (!activeTab) {
			return SmsStore.templatesList.filter(item => item.code !== 'userNotification');
		} else {
			return SmsStore.templatesList.filter(item => item.code === 'userNotification');
		}
	};
	return (
		<>
			<Card className={classNames('pt-30 pr-32 pl-27 pb-30 mb-12')}>
				<PageTitle className='mb-30'>
					Шаблоны писем
				</PageTitle>
				<Tabs tabs={tabs} setTab={(value) => {
					setSearchParams((param) => {
						param.set('activeTab', String(value));
						return param;
					});
				}} activeTab={activeTab} />
			</Card>
			<div>
				<TemplateList list={getTemplateList()} onEdit={(code) => navigate(`/sms/edit-template/${code}`)} />
			</div>
		</>
	);
});
