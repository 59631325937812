import { FC } from 'react'
import Styles from './PageTitle.module.scss'
import classNames from 'classnames'

interface PageTitleProp {
    children: string | JSX.Element;
    className?: string;
}

export const PageTitle: FC<PageTitleProp> = ({ children, className }) => {
    return (
        <h2 className={classNames(Styles.title, className)}>
            {children}
        </h2>
    )
}
