import { flexRender, Table } from "@tanstack/react-table";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { SortIdentifier } from "./SortIdentifier";
import React from "react";

interface TableProps<T> {
    styles?: React.CSSProperties;
    tableInstance: Table<T>,
    className?: string;
}

export const TableView = observer(function <T>(props: TableProps<T>) {

    return (
        <>
            {
                <table style={props.styles} className={classNames(props.className)} >
                    <thead>
                        {
                            props.tableInstance.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                    {
                                        headerGroup.headers.map(header => (

                                                <th key={header.id}>
                                                <div onClick={header.column.getToggleSortingHandler()} className={classNames('sorted-cell')}>
                                                    {
                                                            <span>
                                                            {
                                                                flexRender(
                                                                    header.column.columnDef.header,
                                                                    header.getContext()
                                                                )
                                                            }

                                                        </span>
                                                    }
                                                    <SortIdentifier status={header.column.getIsSorted()} />
                                                </div>
                                            </th>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </thead>
                    <tbody>
                        {
                            props.tableInstance.getRowModel().rows.map(row => (
                                <tr key={row.id}>
                                    {
                                        row.getVisibleCells().map(cell => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table >
            }
        </>

    )
})
