import React, { useEffect, useState } from 'react';
import { Card } from '@/shared/ui/Card';
import cn from 'classnames';
import { BreadCrumb, BreadCrumbItem } from '@/shared/ui/BreadCumb';
import { PageTitle } from '@/shared/ui/PageTitle';
import { VariablesList } from '@/pages/Sms/components/VariablesList';
import { MessageForm } from '@/pages/Sms/components/MessageForm';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { SmsStore } from '@/store/SmsStore';
import { VariablesListEdit } from '@/pages/Sms/components/VariablesListEdit';
import { toast } from 'react-toastify';
import { RecipientsNewsLetter } from './components/RecipientsNewsLetter';
import { ModalSuccess } from '@/shared/ui/Modal';


const TemplateEdit = observer(() => {
	const { code } = useParams();
	const [files, setFiles] = useState<File[]>([]);
	const navigate = useNavigate();
	useEffect(() => {
		if (code) {
			SmsStore.fetchTemplate(code);
		}
	}, [code]);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const handleChange = async () => {
		try {
			await SmsStore.saveChanges();
			await SmsStore.fetchTemplate(code as string);
			setShowSuccessModal(true);
			setTimeout(() => navigate('/sms?activeTab=0'), 3000);
		} catch (e) {
			toast.error('Что-то пошло не так!', {
				position: 'bottom-right',
			});
		}

	};
	const getVariableList = (params: any) => {
		try {
			const obj = JSON.parse(params);
			return Object.keys(obj).map((item) => ({
				variable: item,
				description: obj[item],
			}));
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	return (
		<>
			<Card className={cn('py-30 px-27')}>
				<BreadCrumb>
					<BreadCrumbItem to='/sms'>Шаблоны писем</BreadCrumbItem>
					<BreadCrumbItem to={`/sms/edit-template/${code}`}>
						{SmsStore.currentTemplate?.title}
					</BreadCrumbItem>
				</BreadCrumb>
				<PageTitle className='mb-30'>
					{`Редактирование шаблона  ${
						code === 'userNotification' ? 'массовой рассылки' : 'шаблона письма'
					}
`}
				</PageTitle>
				<div className={cn('fs-14 fw-500 c_first_color mb-10')}>
					Допустимо использовать следующие переменные для подстановки:
				</div>
				{SmsStore.currentTemplate?.params && (
					<VariablesList
						list={getVariableList(SmsStore.currentTemplate.params)}
					/>
				)}
			</Card>
			<MessageForm isRecipients={code === 'userNotification'}
									 files={files}
									 setFiles={setFiles}
									 setEditorState={value => SmsStore.changeBodyTemplate(value)}
									 changeIsHtmlBody={value => SmsStore.changeIsBodyHtml(value)}
									 editorState={SmsStore.currentTemplate?.body} subject={SmsStore.currentTemplate?.subject}
									 setSubject={value => SmsStore.changeSubject(value)}
									 isHtmlBody={SmsStore.currentTemplate?.isHtmlBody} />
			{code !== 'userNotification'
				? (
					<VariablesListEdit
						code={code as string}
						onSave={handleChange}
						list={SmsStore.currentTemplate?.headers || []}
						onChange={(index, value, name) => SmsStore.setHeader(index, value, name)}
					/>
				)
				: <RecipientsNewsLetter onSend={(to) => SmsStore.send(to, files)} />}
			{
				showSuccessModal &&
				<ModalSuccess onClose={() => setShowSuccessModal(false)}>
					<span className={cn('fs-12 c_first_color')}>Изменения сохранены!</span>
				</ModalSuccess>
			}
		</>
	);
});

export default TemplateEdit;
