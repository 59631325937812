import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import classNames from "classnames";
import styles from "@/pages/Ibd/IbdPage.module.scss";
import {Input} from "@/shared/ui/Inputs";
import DatePicker from "@/shared/ui/DatePicker/DatePicker";
import {Button, ButtonRefresh} from "@/shared/ui/Buttons";
import Styles from "@/pages/GeneralStyles.module.scss";
import {useSearchParams} from "react-router-dom";
import {IbdStore} from "@/store/IbdStore";
import moment from "moment";
import {format} from "@/shared/lib/formatDate";
import {observer} from "mobx-react-lite";

interface IProps {
    setSearchInput: (value: string) => void;
}

export const SearchIbd: FC<IProps> = observer(({setSearchInput}) => {
        const [searchParams, setSearchParams] = useSearchParams()
        const page = searchParams.get('page')
        const size = searchParams.get('size')
        const guid = searchParams.get('guid')
        const dt_from = searchParams.get('dt_from')
        const dt_to = searchParams.get('dt_to')
        const [sendDateFrom, setSendDateFrom] = useState<Date | null>(null);
        const [sendDateTo, setSendDateTo] = useState<Date | null>(null);
        const guidRef = useRef<HTMLInputElement>(null)
        useEffect(() => {
            if (guid && guidRef.current) {
                guidRef.current.value = guid
            }
            if (dt_from) {
                setSendDateFrom(new Date(moment(dt_from, 'DD-MM-YYYY').format('YYYY-MM-DD')))
            }
            if (dt_to) {
                setSendDateTo(new Date(moment(dt_to, 'DD-MM-YYYY').format('YYYY-MM-DD')))
            }
        }, [dt_from, dt_to, guid]);
        useEffect(() => {
            if (moment(sendDateTo).isBefore(sendDateFrom)) {
                setSendDateFrom(new Date(moment(sendDateTo).format('YYYY-MM-DD')))
            }
        }, [sendDateFrom, sendDateTo]);
        const handleSearch = async () => {
            if ((guidRef.current?.value && (sendDateFrom && sendDateTo)) || (!guidRef.current?.value && sendDateFrom && sendDateTo) || guidRef.current?.value) {
                setSearchParams(params => {
                    params.set('guid', guidRef.current?.value as string)
                    params.set('dt_from', sendDateFrom ? moment(sendDateFrom).format(format) : '')
                    params.set('dt_to', sendDateTo ? moment(sendDateTo).format(format) : '')
                    return params
                })
                setSearchInput(guidRef.current?.value as string)
            }
        }
        const fetchData = useCallback(() => {
            if ((guid && (dt_from && dt_to)) || (!guid && dt_from && dt_to) || guid) {
                setSearchInput(guid as string)
                IbdStore.fetchAll({
                    guid: guid as string || undefined,
                    sendDateFrom: dt_from ? moment(dt_from, format).startOf('day').utc().format(format) : undefined,
                    sendDateTo: dt_to ? moment(dt_to, format).startOf('day').utc().format(format) : undefined,
                }, {
                    page: Number(page) || undefined,
                    size: Number(size) || undefined,
                })
            }
        }, [dt_from, dt_to, guid, page, setSearchInput, size])
        useEffect(() => {
            fetchData()
        }, [fetchData]);
        const handleReset = () => {
            setSearchInput('')
            setSearchParams([])
            setSendDateTo(null)
            setSendDateFrom(null)
            if (guidRef.current) {
                guidRef.current.value = ''
            }
            IbdStore.reset()
        }
        return (
            <div className="flex a-center mb-15">
                <div className={classNames(styles.inputGuide, 'mr-20')}>
                    <Input ref={guidRef}
                           placeholder="Укажите GUID"/>
                </div>
                <div className={classNames(styles.datePicker, 'mr-20')}>
                    <DatePicker maxDate={sendDateTo} dateFormat={'dd.MM.yyyy'} value={sendDateFrom}
                                onChange={value => setSendDateFrom(value as Date)}
                                placeholder="Время отправки с"/>
                </div>
                <div className={classNames(styles.datePicker, 'mr-20')}>
                    <DatePicker dateFormat={'dd.MM.yyyy'} value={sendDateTo}
                                onChange={value => setSendDateTo(value as Date)}
                                placeholder="Время отправки по"/>
                </div>
                <Button onClick={handleSearch} className={classNames(Styles.searchButton, 'mr-20')}>
                    Найти
                </Button>
                <ButtonRefresh onClick={handleReset}/>
            </div>
        );
    }
)
