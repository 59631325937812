import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {PageTitle} from '@/shared/ui/PageTitle'
import {Button} from '@/shared/ui/Buttons'
import {ButtonRefresh} from '@/shared/ui/Buttons'
import {Card} from '@/shared/ui/Card'
import classNames from 'classnames'
import Styles from './BusinessProcessesPage.module.scss'
import {InputSearchIcon} from '@/shared/ui/Inputs'
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from '@tanstack/react-table'
import {ContextMenu, ContextMenuItem} from '@/shared/ui/Menu'
import theme from "@/shared/themes"
import {TrashIcon} from '@/assets/icons/TrashIcon'
import {EditIcon} from '@/assets/icons/EditIcon'
import {TableView} from '@/shared/ui/Tables'
import {DeleteBusinessProcessesModal} from '@/modals/BusinessProcesses/DeleteBusinessProcessesModal'
import {CardBusinessProcessesModal, TypeModal} from '@/modals/BusinessProcesses/CardBusinessProcessesModal'
import {BusinessProcessesStore} from '@/store/BusinessProcesses'
import {getSnapshot} from 'mobx-state-tree'
import {observer} from 'mobx-react-lite'
import {DownloadFileIcon} from '@/assets/icons/DownloadFileIcon'
import {Preload} from '@/shared/ui/Preload'
import {useSearchParams} from 'react-router-dom'
import {SearchNoResult} from "@/shared/ui/SearchNoResult";
import {Paginate} from "@/shared/ui/Paginate";
import {formatDate} from "@/shared/lib";
import {formatDateUnix} from "@/shared/lib/formatDate";
import {getValue} from "@testing-library/user-event/dist/utils";

interface IBusinessProcess {
    id: number;
    createdAt: string;
    name: string;
    type: string;
    createdBy: string;
    bpmnFileContent: string;
    modifiedAt: string | null;
}


const columnHelper = createColumnHelper<IBusinessProcess>()


export const BusinessProcessesPage = observer(() => {

        const [sorting, setSorting] = useState<SortingState>([])
        const [searchParams, setSearchParams] = useSearchParams()
        const page = searchParams.get('page')
        const size = searchParams.get('size')
        const searchQueryParam = searchParams.get("search")
        const [deleteSuccessModal, setDeleteSuccessModal] = useState(false)

        const [cardModal, setCardModal] = useState(false)
        const [typeModal, setTypeModal] = useState<null | TypeModal>(null)
        const searchInput = useRef<HTMLInputElement>(null)

        useEffect(() => {
            if (searchInput.current) {
                searchInput.current.value = searchQueryParam || ""
            }
        }, [searchInput])
        const fetchData = useCallback(() => {
                return BusinessProcessesStore.fetchAll({
                    type: searchQueryParam,
                    page,
                    size,
                })
            }
            , [size, page, searchQueryParam])


        useEffect(() => {
            fetchData()
            if (Number(size) > 100) {
                setSearchParams(params => {
                    params.set("size", "10")
                    return params
                })
            }
        }, [size, page, searchQueryParam, fetchData])


        const openModal = (type: TypeModal, id?: number) => {
            if (id) {
                BusinessProcessesStore.fetchById(id)
            }
            setTypeModal(type)
            setCardModal(true)

        }

        const deleteBp = () => {
            setDeleteSuccessModal(true)
        }

        const downloadFileBp = (content: string) => {
            const element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + content);
            element.setAttribute('download', 'file.bpmn');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }


        const handleChange = () => {
            setSearchParams({
                search: searchInput?.current?.value || ''
            })
        }

        const onPaginate = (event: React.ChangeEvent<unknown>, page: Number) => {
            setSearchParams(params => {
                params.set("page", page.toString())
                return params
            })
        }

        const onChangeSelectSize = (size: number) => {
            setSearchParams(params => {
                params.set("size", size.toString())
                return params
            })
        }

        const handleReset = () => {
            if (searchInput.current) {
                searchInput.current.value = ""
            }
            setSearchParams({})
        }

        const columns = useMemo(() =>
                [
                    columnHelper.accessor("modifiedAt", {
                        header: "Дата/время записи",
                        sortingFn: (a, b) => formatDateUnix(b.original.modifiedAt) - formatDateUnix(a.original.modifiedAt),
                        cell: ({
                                   getValue,
                                   row
                               }) => getValue() ? formatDate(getValue() as string) : formatDate(row.original.createdAt)
                    }),
                    columnHelper.accessor("name", {
                        header: "Наименование БП"
                    }),
                    columnHelper.accessor("type", {
                        header: "Тип БП"
                    }),
                    columnHelper.accessor("createdBy", {
                        header: "Автор БП"
                    }),
                    columnHelper.display({
                        id: 'id',
                        enableSorting: false,
                        cell: (props) =>
                            <ContextMenu>
                                <ContextMenuItem onClick={() => openModal(TypeModal.EDIT, props.row.original.id)}>
                                    <div className="flex a-center">
                                        <div className="flex a-center mr-10">
                                            <EditIcon/>
                                        </div>
                                        <span>
                                    Редактировать БП
                                </span>
                                    </div>
                                </ContextMenuItem>
                                <ContextMenuItem onClick={() => downloadFileBp(props.row.original.bpmnFileContent)}>
                                    <div className="flex a-center">
                                        <div className="flex a-center mr-10">
                                            <DownloadFileIcon/>
                                        </div>
                                        <span>
                                    Выгрузить файл
                                </span>
                                    </div>
                                </ContextMenuItem>
                                <ContextMenuItem onClick={deleteBp}>
                                    <div className="flex a-center">
                                        <div className="flex a-center mr-10">
                                            <TrashIcon color={theme.deleteColor} width='15' height='16'/>
                                        </div>
                                        <div className='c_danger_color'>
                                            Удалить БП
                                        </div>
                                    </div>
                                </ ContextMenuItem>
                            </ContextMenu>
                    })

                ]
            , [])

        const tableInstance = useReactTable({
            columns,
            state:{
              sorting
            },
            onSortingChange: setSorting,
            data: getSnapshot(BusinessProcessesStore.bpmnProcesses),
            getCoreRowModel: getCoreRowModel(),
            getSortedRowModel: getSortedRowModel()
        })

        return (
            <div>

                <Card className={classNames('pt-30 pr-32 pl-27 pb-23 mb-12')}>
                    <PageTitle className={classNames('mb-30')}>
                        Бизнес-процессы
                    </PageTitle>
                    <div className="flex j-between a-center">
                        <InputSearchIcon
                            id='search-input'
                            className={classNames('input w-70')}
                            ref={searchInput}
                            placeholder='Поиск по типу бизнес-процесса'/>
                        <div className="flex mr-10">
                            <Button onClick={handleChange} className={classNames(Styles.searchButton)}>
                                Найти
                            </Button>
                        </div>
                        <ButtonRefresh onClick={handleReset}/>
                        <div className="flex grow j-end">
                            <Button className={classNames(Styles.button)} onClick={() => openModal(TypeModal.CREATE)}>
                                <div className="flex j-center a-center">
                                    <span className='mr-15 fs-20 plus'>+</span>
                                    <span>Создать БП</span>
                                </div>
                            </Button>
                        </div>

                    </div>
                </Card>
                {
                    BusinessProcessesStore.bpmnProcesses.length ?
                        <Card>

                            <TableView tableInstance={{...tableInstance}}
                                       className={classNames('table-border-radius', 'table-text-left', "table-left")}/>
                        </Card> :
                        <SearchNoResult searchQuery={searchInput?.current?.value}/>

                }

                {
                    deleteSuccessModal &&
                    <DeleteBusinessProcessesModal onClose={() => setDeleteSuccessModal(false)}/>
                }
                {
                    cardModal &&
                    <CardBusinessProcessesModal fetchData={() => fetchData()}
                                                type={typeModal} onClose={() => setCardModal(false)}/>
                }
                {
                    BusinessProcessesStore.bpmnProcesses.length ?
                        <Paginate
                            onChange={onPaginate}
                            currentPage={BusinessProcessesStore.currentPage}
                            countPages={BusinessProcessesStore.totalPages}
                            size={size}
                            onChangeSelectSize={onChangeSelectSize}
                        /> : ''
                }
                {
                    BusinessProcessesStore.loading && <Preload/>
                }
            </div>
        )
    }
)
