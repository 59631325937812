import React, { FC, useEffect, useState } from 'react';
import { Modal, ModalHeader } from '@/shared/ui/Modal';
import classNames from 'classnames';
import { Button } from '@/shared/ui/Buttons';
import { IModalProps } from '@/shared/types/ModalProps';
import { observer } from 'mobx-react-lite';
import styles from './ShowListRecipientsModal.module.scss';
import { Checkbox } from '@/shared/ui/Checkbox';

interface IProps extends IModalProps {
	emailList: string[];
	setEmailsList: (value: string) => void;
	onClose: () => void;
}

export const ShowListRecipientsModal: FC<IProps> = observer(
	({ onClose, emailList, setEmailsList }) => {
		const [emails, setEmails] = useState<{ email: string; checked: boolean }[]>([]);
		useEffect(() => {
			setEmails(emailList.map(item => ({
				email: item, checked: true,
			})));
		}, [emailList, setEmails]);
		const toggleCheckbox = (email: string) => {
			setEmails(emails.map(item => ({
				email: item.email, checked: email === item.email ? !item.checked : item.checked,
			})))
			;
		};
		return (
			<Modal onClose={onClose}>
				<ModalHeader title={'Просмотр списка получателей'} onClose={onClose} />
				<div className={classNames('flex column a-center')}>
					<div className={classNames(styles.list)}>
						{emails.map((item) =>
							<label className={classNames(styles.item)} key={item.email}>
								<Checkbox checked={item.checked} onChange={() => toggleCheckbox(item.email)} />
								<span>
									{item.email}
									</span>
							</label>,
						)}
					</div>
				</div>
				<div className='flex j-end'>
					<Button
						onClick={onClose}
						color='cancel'
						className={classNames('mr-18 button', styles.buttonContainer)}
					>
						Закрыть
					</Button>
					<Button className={classNames(styles.buttonContainer)} onClick={() => {
						onClose();
						setEmailsList(emails.filter(item => item.checked).map(item => item.email).join(','));
					}}>
						Сохранить
					</Button>
				</div>
			</Modal>
		);
	},
);
