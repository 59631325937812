import classNames from 'classnames'
import { FC } from 'react'
import Styles from './DotsMore.module.scss'

export const DotsMore: FC = () => {
    return (
        <div className={classNames('flex column', Styles.wrapper)}>
            <div className={classNames(Styles.item)}></div>
            <div className={classNames(Styles.item)}></div>
            <div className={classNames(Styles.item)}></div>
        </div>
    )
}
