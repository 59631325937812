import React, { FC, useEffect, useRef, useState } from 'react';
import { Button } from '@/shared/ui/Buttons';
import { Checkbox } from '@/shared/ui/Checkbox';
import { Dictionary } from '@/shared/ui/Dictionary';
import { Input } from '@/shared/ui/Inputs';
import { Modal, ModalHeader } from '@/shared/ui/Modal';
import { IModalProps } from '@/shared/types/ModalProps';
import Styles from './CardTradePointModal.module.scss';
import classNames from 'classnames';
import { Select } from '@/shared/ui/Select';
import { useSelect } from '@/shared/hooks';
import { observer } from 'mobx-react-lite';
import { TradePointStore } from '@/store/TradePointsStore';
import { useForm } from 'react-hook-form';
import { ModalSuccess } from '@/shared/ui/Modal';

export const CardTradePointModal: FC<IModalProps> = observer(({ onClose, id }) => {

	const tradePoint = TradePointStore.currentRetailPlace;

	const data = [
		{
			key: 'ID точки',
			value: id,
		},
		{
			key: 'Наименование места установки',
			value: tradePoint?.title,
		},
		{
			key: 'Адрес',
			value: tradePoint?.address?.fullAddress + ` ${tradePoint?.address?.housing || ''} ${tradePoint?.address?.house || ''} ${tradePoint?.address?.apartment ? `, ${tradePoint?.address?.apartment}` : ''}`,
		},
		{
			key: 'Фактический адрес',
			value: tradePoint?.address?.fullAddress + ` ${tradePoint?.address?.housing || ''} ${tradePoint?.address?.house || ''} ${tradePoint?.address?.apartment ? `, ${tradePoint?.address?.apartment}` : ''}`,
		},
	];

	const ref = useRef<HTMLDivElement>(null);


	const [isDelete, setIsDelete] = useState(false);
	const {
		setValue,
		register,
		handleSubmit,
		formState: {
			errors,
		},
	} = useForm({
		mode: 'onBlur',
	});
	useEffect(() => {

		TradePointStore.fetchTradePointById(String(id)).then((tradePoint) => {
			setValue('channelId', TradePointStore.currentRetailPlace.channelId);
			setIsDelete(Boolean(TradePointStore.currentRetailPlace.deletedAt));
			setOptions([
				{
					id: 0,
					label: 'Не подключен',
					checked: tradePoint?.vpnStatus === 0,
				},
				{
					id: 1,
					label: 'Ожидание',
					checked: tradePoint?.vpnStatus === 1,
				},
				{
					id: 2,
					label: 'Подключен',
					checked: tradePoint?.vpnStatus === 2,
				},
				{
					id: 3,
					label: 'Подключение невозможно',
					checked: tradePoint?.vpnStatus === 3,
				},
			]);
		});
	}, []);

	const {
		options,
		getLabelOption,
		setOptions,
		handleChangeOption,
	} = useSelect(
		[],
	);

	const [successModal, setSuccessModal] = useState(false);

	const submit = (data: any) => {
		TradePointStore.update({
			id: TradePointStore.currentRetailPlace.id,
			vpnStatus: options.find(item => item.checked)?.id,
			channelId: Number(data.channelId),
			isDelete: isDelete,
		}).then(() => setSuccessModal(true));
	};

	return (
		<Modal ref={ref} className={classNames(Styles.modal)} onClose={onClose}>
			<ModalHeader className='mb-35' title='Информация о торговой точке' onClose={onClose} />
			<Dictionary data={data} className='mb-23' />
			<form className='modal-form' onSubmit={handleSubmit(submit)}>
				<div className='mb-24'>
					<div className='mb-7 c_first_color fs-14'>
						Статус VPN
					</div>
					<Select
						clickOutside
						contentClassName={Styles.selectContent}
						className={Styles.statusVPN}
						label={getLabelOption()}>
						{
							options.map(item =>
								<li key={item.id} className={classNames('option', item.checked && 'active')}>
									<label>
										<Checkbox className='mr-11' onChange={() => handleChangeOption(item.id)}
															checked={item.checked} />
										<span>{item.label}</span>
									</label>
								</li>,
							)
						}
					</Select>
				</div>
				<div className='mb-24'>
					<div className='mb-7 c_first_color fs-14'>
						ID канала
					</div>
					<Input placeholder='Введите ID канала' {...register('channelId', {
						required: {
							value: true,
							message: 'Это поле обязательно для заполнения',
						},
						pattern: {
							value: /^[0-9]+$/,
							message: 'Это поле может содержать только цифры',
						},
					})}
								 error={errors.channelId?.message as string}
					/>
				</div>
				<label className='flex a-center fit-content pointer'>
					<Checkbox className='mr-11' checked={isDelete} onChange={() => setIsDelete(!isDelete)} />
					<span className='none-select c_first_color fs-16'>Удалена</span>
				</label>
				<div className='flex j-end mt-40'>
					<Button color='cancel' className='mr-18 button' onClick={onClose}>
						Отмена
					</Button>
					<Button className='button' type='submit'>
						Сохранить
					</Button>
				</div>
			</form>
			{
				successModal &&
				<ModalSuccess className='pt-8 pb-28' onClose={onClose}>
					Изменения сохранены!
				</ModalSuccess>
			}
		</Modal>
	);
});
