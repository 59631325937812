import React, {FC, RefObject, useCallback, useEffect, useState} from 'react';
import {Card} from "@/shared/ui/Card";
import classNames from "classnames";
import {PageTitle} from "@/shared/ui/PageTitle";
import Styles from "@/pages/GeneralStyles.module.scss";
import {InputSearch, RadioButton} from "@/shared/ui/Inputs";
import {Button, ButtonRefresh} from "@/shared/ui/Buttons";
import {useSearchParams} from "react-router-dom";
import {TaxpayersStore} from "@/entities/Taxpayer";

const searchFilters = [
    {
        id: '1',
        name: 'filter',
        value: 'iinBin',
        label: 'поиск по ИИН/БИН'
    },
    {
        id: '2',
        name: 'filter',
        value: 'email',
        label: 'поиск по e-mail'
    },
    {
        id: '3',
        name: 'filter',
        value: 'phone',
        label: 'поиск по телефону'
    },
    {
        id: '4',
        name: 'filter',
        value: 'name',
        label: 'поиск по наименованию организации'
    },
    {
        id: '5',
        name: 'filter',
        value: 'billingAccountNumber',
        label: 'поиск по номеру лицевого счета'
    },

]
interface IProps{
    searchInput: RefObject<HTMLInputElement>,
}
const SearchTaxpayer:FC<IProps> = ({searchInput}) => {
    const [currentFilter, setCurrentFilter] = useState<string>("")
    const [searchParams, setSearchParams] = useSearchParams()
    const searchQueryParam = searchParams.get("search")
    const filterParam = searchParams.get("filter")
    const size = searchParams.get('size')
    const page = searchParams.get("page")


    useEffect(() => {
        setCurrentFilter(filterParam || 'iinBin')
        if (searchInput.current) {
            searchInput.current.value = searchQueryParam || ""
        }
    }, [searchInput, searchQueryParam, filterParam])
    const returnFocus = () => {
        const $el = searchInput.current
        $el?.focus()
    }

    const handleReset = () => {
        if (searchInput.current) {
            searchInput.current.value = ''
        }
        setSearchParams({})
    }
    const handleSearch = () => {
        const searchQuery = searchInput.current?.value
        setSearchParams({
            search: searchQuery || '',
            filter: currentFilter || ''
        })
    }

    const handleClearSearchClick = () => {
        if (searchInput.current) {
            searchInput.current.value = ''
        }
        returnFocus()
    }
    const fetchData = useCallback(() =>
            TaxpayersStore.fetchAll(filterParam ? {[filterParam]: searchQueryParam?.trim()} : null, {page, size})
        , [size, page, searchQueryParam, filterParam])

    useEffect(() => {
        fetchData()
        if (Number(size) > 100) {
            setSearchParams(param => {
                param.set("size", "10")
                return param
            })
        }
    }, [size, page, fetchData])

    return (
        <Card className={classNames('pt-30 pr-32 pl-27 pb-23 mb-12')}>
            <PageTitle className={classNames('mb-30')}>
                Налогоплательщики
            </PageTitle>
            <div className={classNames(Styles.filterRow)}>
                {
                    searchFilters?.map((item, index) =>
                        <label
                            className={classNames('c_first_color', index !== searchFilters.length - 1 ? 'mr-24' : '')}
                            key={item.id}>
                            <RadioButton {...item} checked={item.value === currentFilter}
                                         onChange={(e) => setCurrentFilter(e.target.value)}/>
                            <span>
                                        {item.label}
                                    </span>
                        </label>
                    )
                }
            </div>
            <div className="flex a-center mb-15 gap-20">
                <InputSearch
                    ref={searchInput}
                    clearSearch={handleClearSearchClick}
                    className={classNames(Styles.searchInput)}>
                    <Button onClick={handleSearch} className={classNames(Styles.searchButton)}>
                        Найти
                    </Button>
                </InputSearch>
                <ButtonRefresh onClick={handleReset}/>
            </div>
        </Card>

    );
};

export default SearchTaxpayer;
