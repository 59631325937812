import { FC, useEffect, useState } from 'react';
import { LoginForm } from './LoginForm'
import { Input, InputPassword } from '@/shared/ui/Inputs'
import Styles from './Auth.module.scss'
import { Button } from '@/shared/ui/Buttons'
import { Link, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { Checkbox } from '@/shared/ui/Checkbox/Checkbox'
import { ToastContainer, toast } from 'react-toastify';
import { SubmitHandler, useForm } from 'react-hook-form'
import { rootStore } from '@/store/rootStore'
import { api } from '@/shared/api/api'
import axios from 'axios'
import { IErrorMessage } from '@/shared/api/instanceAxios'
export const Auth: FC = () => {

    const navigate = useNavigate()

    interface LoginFormField {
        email: string
        password: string,
        saveMe: boolean,
    }

    const {
        handleSubmit,
        formState: { errors },
        register,
    } = useForm<LoginFormField>({
        mode: 'onBlur'
    })

    const [error] = useState("")

    const onSubmit: SubmitHandler<LoginFormField> = async ({ email, password, saveMe }) => {
        try {
            // const result = await rootStore.signIn(data)
            const response = await api.signIn({ email, password })
            const { access_token, refresh_token } = response.data
            rootStore.signIn(saveMe, access_token, refresh_token)
            navigate('/')
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const response = await e.response?.data as IErrorMessage
                toast.error(response?.details?.join(" "), {
                    position: 'bottom-right'
                })
            }
        }

    }


    return (
        <div>
            <LoginForm
                className='px-50 py-51'
                title='Управление ОФД'
                subtitle='Пожалуйста, введите свои данные для входа в Систему'
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <label className={classNames(Styles.label, 'mt-34 mb-15')}>
                        <span className={Styles.captionInput} >Логин</span>

                        <Input
                            type='text'
                            {
                            ...register('email', {
                                required: {
                                    value: true,
                                    message: 'Заполните это поле',
                                }

                            })
                            }
                            error={errors.email?.message as string}
                            placeholder='Введите логин'
                        />
                    </label>
                    <label className={classNames(Styles.label, 'mb-13')}>
                        <span className={Styles.captionInput}>Пароль</span>
                        <InputPassword
                            {
                            ...register('password', {
                                required: {
                                    value: true,
                                    message: 'Заполните это поле',
                                }
                            })
                            }
                            error={errors.password?.message as string}
                            placeholder='Введите пароль'
                        />
                    </label>
                    <div className={classNames('flex j-between fs-12 mb-25', Styles.remember)}>
                        <label className={classNames(Styles.rememberLabel, 'flex a-center')}>
                            <Checkbox {
                                ...register('saveMe')
                            } />
                            <span>
                                Запомнить меня
                            </span>
                        </label>
                        <Link className={Styles.link} to='/login/reset'>Забыли пароль?</Link>
                    </div>
                    {
                        error &&
                        <div className='error'>
                            {error}
                        </div>
                    }
                    <Button type='submit'>
                        Войти
                    </Button>
                </form>
            </LoginForm>
            <ToastContainer />
        </div>
    )
}
