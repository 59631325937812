import {instance} from './instanceAxios';

export const rolesServices = {
    getRolesList: async (data?: any, params?: any) => {
        const response = await instance.post('/role/list', data, {
           params
        });
        return response.data
    },
    getRoleById: async (id: number) => {
        const response = await instance.get('/role/' + id);
        return response.data
    },
    deleteRole: async (id: number) => {
        const response = await instance.delete('/role/' + id);
        return response.data
    },
    createRole: async (data: any) => {
        const response = await instance.post('/role', data);
        return response.data
    },
    editRole: async (id: number, data: any) => {
        const response = await instance.put('/role/' + id, data)
        return response.data
    }
}
