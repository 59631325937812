import React, { FC } from 'react';
import cn from 'classnames';
import s from './TemplateList.module.scss';
import { EditIcon } from '@/assets/icons/EditIcon';
import { Card } from '@/shared/ui/Card';

interface IProps {
	title: string | null;
	description: string;
	onEdit: () => void;
}

const TemplateListItem: FC<IProps> = ({ title, description, onEdit }) => {
	return (
		<Card className={'mb-12'}>
			<div className={cn(s.container)}>
				<div className={cn(s.header)}>
					<span>{title}</span>
					<div onClick={onEdit} className={cn(s.icon)}><EditIcon color={'white'} /></div>
				</div>
				<div className={cn(s.body)}>{description}
				</div>
			</div>
		</Card>
	);
};

export default TemplateListItem;
