import React, {FC} from 'react'
import classNames from 'classnames'
import Styles from '../Info.module.scss'
import {Dictionary} from '@/shared/ui/Dictionary'
import {observer} from 'mobx-react-lite'
import {TaxpayersStore} from '@/entities/Taxpayer/model/TaxpayesStore'

export const TaxpayerInfo: FC = observer(() => {
        const data = [
            {
                key: 'ИИН/БИН',
                value: TaxpayersStore.currentTaxpayer?.iinBin || '',
                isFirst: true
            },
            {
                key: 'ОКЭД',
                value: TaxpayersStore?.currentTaxpayer?.oked || ''
            },
            {
                key: 'Юридический адрес',
                value: TaxpayersStore.currentTaxpayer?.legalAddress?.fullAddress || ''
            },
            {
                key: 'Фактический адрес',
                value: TaxpayersStore.currentTaxpayer?.actualAddress?.fullAddress || ''
            },
            {
                key: 'Биллинг-аккаунт',
                value: TaxpayersStore.currentTaxpayer?.billingAccountNumber || ''
            },
            {
                key: 'Баланс',
                value: TaxpayersStore.currentTaxpayer?.balance || ''
            },
            {
                key: 'Статус клиента',
                value: TaxpayersStore?.currentTaxpayer?.blockedAt ? 'Не активен' : 'Активен',
                status: !TaxpayersStore.currentTaxpayer?.blockedAt
            },
            {
                key: 'Ответственное лицо',
                value: TaxpayersStore.currentTaxpayer ? `${TaxpayersStore.currentTaxpayer?.responsiblePerson?.secondName || ''} ${TaxpayersStore.currentTaxpayer?.responsiblePerson?.firstName[0] || ''}.${TaxpayersStore.currentTaxpayer?.responsiblePerson?.lastName[0]}.` : ""
            },
            {
                key: 'Банк',
                value: TaxpayersStore.currentTaxpayer?.bank || ''
            },
            {
                key: 'БИК',
                value: TaxpayersStore.currentTaxpayer?.bic || ''
            },
            {
                key: 'Счет',
                value: TaxpayersStore.currentTaxpayer?.paymentAccount || ''
            },
            {
                key: 'Телефон',
                value: TaxpayersStore.currentTaxpayer?.phone || ''
            },
            {
                key: 'E-mail',
                value: TaxpayersStore.currentTaxpayer?.responsiblePersonEmail || ''
            }
        ]
        return (
            <div className={classNames("flex j-between wrap", Styles.fieldsRow)}>
                <div className="flex-item">
                    <Dictionary data={data.slice(0, 7)}/>
                </div>
                <div className="flex-item">
                    <Dictionary data={data.slice(7)}/>
                </div>
            </div>
        )
    }
)
