import { api } from "@/shared/api/api";
import { flow, types } from "mobx-state-tree";



const PermissionsModel = types.model({
    permissions: types.array(types.frozen()),
    sections: types.array(types.frozen())
}).actions((self) => ({
    fetchAllSections: flow(function* () {
        const data = yield api.getPermissionsSections()
        self.sections = data.sections
        return data.sections
    }),
    fetchAll: flow(function* () {
        const data = yield api.getPermissionsList()
        self.permissions = data.permissions
    })

}))

export const PermissionsStore = PermissionsModel.create({
    sections: [],
    permissions: [],
})
