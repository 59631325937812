import theme from '@/shared/themes';
import React, { FC, useState } from 'react'

interface IconProps {
    color?: string;
}

export const EyeIcon: FC<IconProps> = ({ color }) => {
    const defaultColor = theme.firstColor
    const [currentColor] = useState<string>(color || defaultColor)
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99995 10.8866C6.40661 10.8866 5.11328 9.59328 5.11328 7.99995C5.11328 6.40661 6.40661 5.11328 7.99995 5.11328C9.59328 5.11328 10.8866 6.40661 10.8866 7.99995C10.8866 9.59328 9.59328 10.8866 7.99995 10.8866ZM7.99995 6.11328C6.95995 6.11328 6.11328 6.95995 6.11328 7.99995C6.11328 9.03995 6.95995 9.88661 7.99995 9.88661C9.03995 9.88661 9.88661 9.03995 9.88661 7.99995C9.88661 6.95995 9.03995 6.11328 7.99995 6.11328Z" fill={currentColor} />
            <path d="M7.99997 14.0134C5.4933 14.0134 3.12664 12.5467 1.49997 10C0.793304 8.90003 0.793304 7.10669 1.49997 6.00003C3.1333 3.45336 5.49997 1.98669 7.99997 1.98669C10.5 1.98669 12.8666 3.45336 14.4933 6.00003C15.2 7.10003 15.2 8.89336 14.4933 10C12.8666 12.5467 10.5 14.0134 7.99997 14.0134ZM7.99997 2.98669C5.84664 2.98669 3.78664 4.28003 2.34664 6.54003C1.84664 7.32003 1.84664 8.68003 2.34664 9.46003C3.78664 11.72 5.84664 13.0134 7.99997 13.0134C10.1533 13.0134 12.2133 11.72 13.6533 9.46003C14.1533 8.68003 14.1533 7.32003 13.6533 6.54003C12.2133 4.28003 10.1533 2.98669 7.99997 2.98669Z" fill={currentColor} />
        </svg>

    )
}
