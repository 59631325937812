import theme from '@/shared/themes'
import React, { FC } from 'react'

export const SuccessIcon: FC = () => {
    return (
        <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.5 4.16667C14.0209 4.16667 4.66669 13.5208 4.66669 25C4.66669 36.4792 14.0209 45.8333 25.5 45.8333C36.9792 45.8333 46.3334 36.4792 46.3334 25C46.3334 13.5208 36.9792 4.16667 25.5 4.16667ZM35.4584 20.2083L23.6459 32.0208C23.3542 32.3125 22.9584 32.4792 22.5417 32.4792C22.125 32.4792 21.7292 32.3125 21.4375 32.0208L15.5417 26.125C14.9375 25.5208 14.9375 24.5208 15.5417 23.9167C16.1459 23.3125 17.1459 23.3125 17.75 23.9167L22.5417 28.7083L33.25 18C33.8542 17.3958 34.8542 17.3958 35.4584 18C36.0625 18.6042 36.0625 19.5833 35.4584 20.2083Z" fill={theme.doneColor} />
        </svg>

    )
}

