import React, { FC } from 'react'
import { TrashIcon } from '@/assets/icons/TrashIcon'
import { Button } from '@/shared/ui/Buttons'
import { Modal } from '@/shared/ui/Modal'
import { IModalProps } from '@/shared/types/ModalProps'
import Styles from './GeneralDeleteModal.module.scss'
import classNames from 'classnames'


interface IGeneralDeleteModalProps extends IModalProps {
    onConfirmation: () => void;
    blocked?: boolean;
}


export const GeneralDeleteModal: FC<IGeneralDeleteModalProps> = ({
    className,
    children,
    onConfirmation,
    onClose,
    blocked
}) => {
    return (
        <Modal className={classNames('wrapper-modal-delete', 'modal-form', Styles.modal, className)} onClose={onClose}>
            {children}
            <div className="flex j-end">
                <Button color='cancel' className='mr-18 button' onClick={onClose}>
                    Отмена
                </Button>
                <Button onClick={onConfirmation} color='danger' disabled={blocked !== undefined ? !blocked : false} className='button button-delete j-between'>
                    <div className="flex a-center j-center">
                        <div className="mr-8 flex a-center j-center">
                            <TrashIcon />
                        </div>
                        <span>
                            Удалить
                        </span>
                    </div>
                </Button>
            </div>
        </Modal>
    )
}
