import React, {FC, useEffect, useRef, useState} from 'react'
import {useForm} from 'react-hook-form'
import {Input} from '@/shared/ui/Inputs'
import {Modal, ModalHeader} from '@/shared/ui/Modal'
import {IModalProps} from '@/shared/types/ModalProps'
import {NewUserIcon} from '@/assets/icons/NewUserIcon'
import {Button} from '@/shared/ui/Buttons'
import classNames from 'classnames'
import Styles from './CardBusinessProcessesModal.module.scss'
import {FileUploadBP} from './FileUploadBP'
import {ModalSuccess} from '@/shared/ui/Modal'
import {ResponseErrorModal} from './ResponseErrorModal'
import {BusinessProcessesStore} from '@/store/BusinessProcesses'
import {observer} from 'mobx-react-lite'
import {rootStore} from '@/store/rootStore'
import axios from "axios";
import {toast} from "react-toastify";
import {IErrorMessage} from "@/shared/api/instanceAxios";
import {ConfirmModal} from "@/modals/General";

export enum TypeModal {
    CREATE = 'Создание нового бизнес-процесса',
    EDIT = 'Редактирование бизнес-процесса',
}


interface ICardProps extends IModalProps {
    type?: null | TypeModal,
    fetchData: () => void;
}

export const CardBusinessProcessesModal: FC<ICardProps> = observer(({onClose, type, fetchData}) => {

        const [uploadModal, setUploadModal] = useState(false)
        const [selectedFile, setSelectedFile] = useState<null | File>(null)
        const [replaceFileModal, setReplaceFileModal] = useState(false)
        const [successModal, setSuccessModal] = useState(false)
        const [progress, setProgress] = useState(0)
        const [errorUploading, setErrorUploading] = useState(false)
        const [responseErrorModal, setResponseErrorModal] = useState(false)
        const [responseErrorMessage, setResponseErrorMessage] = useState<string | undefined>("Указанный тип бизнес-процесса существует в базе данных!")
        const currentBP = BusinessProcessesStore.currentBP
        const onHandlerUpload = (e: any) => {
            if (e.target.files[0]) {
                setSelectedFile(e.target.files[0])
                setValue("nameFile", e.target.files[0].name)
            }
        }
        const {
            register,
            handleSubmit,
            setValue,
            formState: {
                errors,
            },
        } = useForm({
            mode: 'onBlur'
        })

        const getError = () => {
            const error = responseErrorMessage?.split("!")
            const obj = {
                title: '',
                subtitle: '',
            }
            obj.title = error ? error[0]?.trim() + '!' : ''
            obj.subtitle = error?.length === 2 ? error[1]?.trim() : ''
            return obj
        }

        useEffect(() => {
            if (currentBP) {
                setValue("typeBP", currentBP.type)
                setValue("nameBP", currentBP.name)
                setValue("nameFile", currentBP.fileName)
            }

        }, [currentBP])

        useEffect(() => {
            return () => {
                BusinessProcessesStore.clearCurrentBP()
            }
        }, [])
        const ref = useRef<null | HTMLInputElement>(null)


        const handleClickFileInput = () => {
            if (selectedFile) {
                setReplaceFileModal(true)
            } else {
                ref.current?.click()
            }
        }


        const onSubmit = async (data: any) => {
            try {
                const formData = new FormData()
                formData.append("type", data.typeBP)
                formData.append("user", rootStore?.me.id)
                formData.append("name", data.nameBP)
                if (selectedFile) {
                    formData.append("file", selectedFile)
                    setUploadModal(true)

                }
                if (!currentBP && selectedFile) {
                    await BusinessProcessesStore.createBusinessProcess(formData, (progress) => setProgress(progress), selectedFile)
                } else {
                    await BusinessProcessesStore.uploadBusinessProcess(formData, (progress) => setProgress(progress))
                }
                setUploadModal(false)
                fetchData()
                setSuccessModal(true)
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    setErrorUploading(true)
                    const response = await e.response?.data as IErrorMessage
                    if (response.details) {
                        toast.error(response?.details?.join(" "), {
                            position: 'bottom-right'
                        })
                    } else {
                        setResponseErrorMessage(response as string)
                        setResponseErrorModal(true)
                    }

                }
            }

        }

        /***Modals***/

        return (
            <>

                <Modal className={classNames(Styles.modal)} onClose={onClose}>
                    <ModalHeader title={type as string} onClose={onClose}>
                        <NewUserIcon/>
                    </ModalHeader>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="modal-form">
                            <label className='label'>
                                <span>Автор</span>
                                <Input color='disabled' disabled className='--fluid'
                                       defaultValue={`${rootStore.me?.firstName} ${rootStore.me?.lastName}`}/>
                            </label>
                            <label className='label'>
                                <span>Тип БП {type === TypeModal.CREATE && <span className='required'>*</span>}</span>
                                <Input
                                    {
                                        ...register('typeBP', {
                                            required: {
                                                value: true,
                                                message: 'Это поле обязательно для заполнения'
                                            },
                                        })
                                    }
                                    error={errors.typeBP?.message as string}
                                    color={type === TypeModal.EDIT ? 'disabled' : undefined}
                                    disabled={type === TypeModal.EDIT}
                                    className='--fluid'
                                    placeholder='Введите тип только латинскими буквами'/>
                            </label>
                            <label className='label'>
                                <span>Наименование <span className='required'>*</span></span>
                                <Input
                                    {
                                        ...register('nameBP', {
                                            required: {
                                                value: true,
                                                message: 'Это поле обязательно для заполнения'
                                            },
                                        })
                                    }
                                    error={errors.nameBP?.message as string}
                                    className='--fluid'
                                    placeholder='Введите наименование БП'/>
                            </label>
                            <label className='label'>
                                <span>Файл <span className='required'>*</span></span>
                                <div className="flex">
                                    <Input
                                        {
                                            ...register('nameFile', {
                                                required: {
                                                    value: true,
                                                    message: 'Загрузите файл'
                                                },
                                            })
                                        }
                                        disabled
                                        error={errors.nameFile?.message as string}
                                        className='--fluid mr-20'
                                        placeholder='Загрузите файл в формате .bpmn'/>
                                    <input accept='.bpmn' type="file" ref={ref} onChange={onHandlerUpload}
                                           className={Styles.fileInput}/>
                                    <Button onClick={handleClickFileInput} className={Styles.buttonUpload}>
                                        Загрузить
                                    </Button>
                                </div>
                            </label>
                            <div className="flex j-end">
                                <Button color='cancel' className='mr-18 button' onClick={onClose}>
                                    Отмена
                                </Button>
                                <Button className='button' type='submit'>
                                    {
                                        type === TypeModal.CREATE ? 'Создать' : 'Сохранить'
                                    }
                                </Button>
                            </div>

                        </div>
                    </form>

                </Modal>
                {
                    uploadModal && <FileUploadBP progress={progress} error={errorUploading} file={selectedFile as File}
                                                 onClose={() => setUploadModal(false)}/>
                }
                {
                    replaceFileModal &&
                    <ConfirmModal acceptButtonText="Заменить" message="Заменить файл?" onReject={() => setReplaceFileModal(false)} onAccept={() => {
                        setReplaceFileModal(false)
                        if (ref.current) ref.current.value = ""
                        setValue("nameFile", "")
                        setSelectedFile(null)
                    }}/>
                }
                {
                    successModal &&
                    <ModalSuccess wrapperClassName={Styles.successModal} onClose={onClose}>
                        <div className='c_first_color fs-18 shrink-0'>
                            {currentBP ? 'Бизнес-процесс успешно изменен' : 'Бизнес-процесс успешно создан!'}
                        </div>
                    </ModalSuccess>
                }
                {
                    responseErrorModal && <ResponseErrorModal title={getError().title}
                                                              subtitle={getError().subtitle}
                                                              onClose={() => setResponseErrorModal(false)}/>
                }
            </>

        )
    }
)
