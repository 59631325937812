import {FC, useEffect} from "react";
import {Select} from "@/shared/ui/Select";
import {useSelect} from "@/shared/hooks";
import Styles from './PaginateSelect.module.scss';
import classNames from "classnames";

interface IProps {
    onChange: (value: number) => void;
    value: number;
}

export const PaginateSelect: FC<IProps> = ({onChange, value}) => {
    const {options, handleChangeOption, getLabelOption, setOptions} = useSelect([
        {
            id: 1,
            label: 10,
            checked: true,
        },
        {
            id: 2,
            label: 15,
            checked: false,
        },
        {
            id: 3,
            label: 25,
            checked: false,
        },
        {
            id: 4,
            label: 50,
            checked: false,
        },
        {
            id: 5,
            label: 100,
            checked: false,
        },
    ])

    useEffect(() => {

        setOptions(state => {
            let found = false;
            const newOptions = state.map(option => {
                option.checked = false
                if (option.label === value) {
                    option.checked = true
                    found = true
                }
                return option
            })

            if (!found) newOptions[0].checked = true
            return newOptions
        })

    }, [value, setOptions])

    return <Select
        closeOnSelect
        className={Styles.wrap}
        inputClassName={Styles.input}
        contentClassName={Styles.selectContent}
        clickOutside
        label={`Показать: ${getLabelOption()}`}>
        {
            options.map(item =>
                <li
                    className={classNames("pointer flex j-center py-12 px-25 c_first_color fs-14 option", (getLabelOption() === item.label) && Styles.active)}
                    key={item.id}
                    onClick={() => {
                        handleChangeOption(item.id)
                        onChange(item.label as number)
                    }}>{item.label}</li>)
        }
    </Select>
}
