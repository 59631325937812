import { api } from "@/shared/api/api"
import { flow, types } from "mobx-state-tree"

const UsersOrgModel = types.model({
    loading: types.boolean,
    totalItems: types.number,
    itemsPerPage: types.number,
    totalPages: types.number,
    currentPage: types.number,
    organisationUsersList: types.array(types.frozen())
}).actions((self) => ({
    fetchAll: flow(function* (params?: any) {
        self.loading = true;
        try {
            const data = yield api.getAllOrg(params);
            self.totalItems = data.totalItems
            self.itemsPerPage = data.itemsPerPage
            self.totalPages = data.totalPages
            self.currentPage = data.currentPage
            self.organisationUsersList = data.organisationUsersList
        }catch (e) {
            throw e
        }finally {
            self.loading = false;
        }

    })
}))

export const UsersOrgStore = UsersOrgModel.create({
    loading: false,
    totalItems: 0,
    itemsPerPage: 0,
    totalPages: 0,
    currentPage: 0,
    organisationUsersList: []
})
