import {api} from '@/shared/api/api';
import {flow, types} from 'mobx-state-tree'

export const RecoveryPasswordModel = types.model('RecoveryPasswordModel', {
    emailRecovery: types.maybeNull(types.string),
    codeWaiting: types.boolean,
    code: types.maybeNull(types.string),
}).actions(self => ({
    getCodePassReset: flow(function* (email: string) {
        const response = yield api.getCodePassReset(email)
        if (response.status === 'OK') {
            self.codeWaiting = true;
            self.emailRecovery = email
        }
        return response
    }),
    setCode(code: string) {
        self.code = code;
    },
    sendNewPassword: flow(function* (newPassword: string) {
        const response = yield api.sendNewPassword(self.emailRecovery as string, self.code as string, newPassword)
        if (response.status === 'OK') {
            self.codeWaiting = false;
            self.emailRecovery = null;
            self.code = null
        }
        return response
    })
}))
