import {BusinesProcessesIcon} from "@/assets/icons/menu/BusinesProcessesIcon"
import {ContentIcon} from "@/assets/icons/menu/ContentIcon"
import {DecoderIcon} from "@/assets/icons/menu/DecoderIcon"
import {IbdIcon} from "@/assets/icons/menu/IbdIcon"
import {KkmIcon} from "@/assets/icons/menu/KkmIcon"
import {MapIcon} from "@/assets/icons/menu/MapIcon"
import {PresentionChartIcon} from "@/assets/icons/menu/PresentionChartIcon"
import {RolesIcon} from "@/assets/icons/menu/RolesIcon"
import {SettingsIcon} from "@/assets/icons/menu/SettingsIcon"
import {SmsIcon} from "@/assets/icons/menu/SmsIcon"
import {TariffsIcon} from "@/assets/icons/menu/TariffsIcon"
import {TaxpayersIcon} from "@/assets/icons/menu/TaxpayersIcon"
import {TradePointsIcon} from "@/assets/icons/menu/TradePointsIcon"
import {UsersIcon} from "@/assets/icons/menu/UsersIcon"
import {ServiceAccount} from "@/assets/icons/menu/ServiceAccount"

const menuItem = [
    {
        name: "Налогоплательщики",
        path: "/taxpayers",
        icon: TaxpayersIcon,
        code: "READ_LIST_TAXPAYER"
    },
    {
        name: "ККМ",
        path: "kkm",
        icon: KkmIcon,
    },
    {
        name: "IOFD",
        path: "ibd",
        alt: 'ИБД',
        icon: IbdIcon,
    },
    {
        name: "Содержимое",
        path: "content",
        icon: ContentIcon,
    },
    {
        name: "Зоны",
        path: "map",
        icon: MapIcon,
    },
    {
        name: "Настройки",
        path: "settings",
        icon: SettingsIcon,
    },
    {
        name: "Шаблоны писем",
        path: "sms",
        icon: SmsIcon,
    },
    // {
    //     name: "Тарифы",
    //     path: "tariffs",
    //     icon: TariffsIcon,
    // },
    {
        name: "Пакетные задания",
        path: "presentionchart",
        icon: PresentionChartIcon,
    },
    {
        name: "Дешифратор",
        path: "decoder",
        icon: DecoderIcon,
    },
    {
        name: "Торговые точки",
        path: "tradepoints",
        icon: TradePointsIcon,
    },
    {
        name: "Пользователи",
        path: "users",
        icon: UsersIcon,
    },
    {
        name: "Бизнес процессы",
        alt: 'Конструктор бизнес процессов',
        path: "businessProcesses",
        icon: BusinesProcessesIcon,
    },
    {
        name: "Служебный аккаунт",
        path: "service_account",
        icon: ServiceAccount,
    },
    {
        name: "Роли",
        alt: 'Конструктор роли',
        path: "roles",
        icon: RolesIcon,
    },

]

export default menuItem;
