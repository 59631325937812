import { FC } from 'react'
import Styles from './MenuList.module.scss'
import menuItems from '@/shared/static/menuItems'
import { MenuListItem } from './MenuListItem'
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { rootStore } from '@/store/rootStore';

interface MenuListProps {
    show: boolean;
}

export const MenuList: FC<MenuListProps> = observer(({ show }) => {

    return (
        <menu className={classNames(Styles.menu)}>
            {
                menuItems.filter(item => {
                    return Boolean(rootStore.menu.find(_item => item.name === _item.nameRu || item.alt === _item.nameRu))
                }).map(item =>
                    <MenuListItem
                        show={show}
                        path={item.path}
                        name={item.name}
                        key={item.name}
                        icon={item.icon}
                    />
                )
            }
        </menu>
    )
})
