import theme from '@/shared/themes'
import React from 'react'

export const ExpandAllIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6666 10.7933V5.20659C14.6666 2.77992 13.22 1.33325 10.7933 1.33325L5.21329 1.33325C2.77996 1.33325 1.33329 2.77992 1.33329 5.20659V10.7866C1.33329 13.2133 2.77996 14.6599 5.20662 14.6599H10.7933C13.22 14.6666 14.6666 13.2199 14.6666 10.7933ZM7.64663 9.85992L5.29329 7.50659C5.19329 7.40659 5.14663 7.27992 5.14663 7.15325C5.14663 7.02659 5.19329 6.89992 5.29329 6.79992C5.48663 6.60659 5.80663 6.60659 5.99996 6.79992L7.99996 8.79992L9.99996 6.79992C10.1933 6.60659 10.5133 6.60659 10.7066 6.79992C10.9 6.99325 10.9 7.31325 10.7066 7.50659L8.35329 9.85992C8.15996 10.0599 7.83996 10.0599 7.64663 9.85992Z" fill={theme.secondColor} />
        </svg>

    )
}

