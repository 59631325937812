import {api} from "@/shared/api/api";
import {flow, types} from "mobx-state-tree";
import {PaginationModel} from "./PaginationModel";

const BusinessProcessesModel = types.model({
    totalItems: types.number,
    itemsPerPage: types.number,
    totalPages: types.number,
    currentPage: types.number,
    loading: types.boolean,
    bpmnProcesses: types.array(types.frozen()),
    currentBP: types.maybeNull(types.frozen()),
}).actions(self => ({
    fetchAll: flow(function* (params?) {
        try {
            self.loading = true;
            const r = yield api.getBusinessProcessesList(params);
            self.totalItems = r.totalItems
            self.itemsPerPage = r.itemsPerPage
            self.currentPage = r.currentPage
            self.totalPages = r.totalPages
            self.bpmnProcesses = r.bpmnProcesses || []
        } catch (e) {
            throw e
        } finally {
            self.loading = false;
        }
    }),
    createBusinessProcess: flow(function* (data, setProgress: (progress: number) => void, file: File) {
        yield api.createBusinessProcess(data, setProgress, file)
    }),
    uploadBusinessProcess: flow(function* (data, setProgress: (progress: number) => void, file?: File) {
        yield api.uploadBusinessProcess(data, setProgress, file)
    }),
    fetchById: flow(function* (id: number) {
        const response = yield api.getBusinessProcessById(id)
        self.currentBP = response
    }),
    clearCurrentBP:() => {
        self.currentBP = null
    }
}))


export const BusinessProcessesStore = BusinessProcessesModel.create({
    loading: false,
    bpmnProcesses: [],
    currentPage: 1,
    itemsPerPage: 15,
    totalItems: 0,
    totalPages: 0,
})

