import { SortIdentiferIcon } from "@/assets/icons/SortIdentiferIcon"
import theme from "@/shared/themes";
import classNames from "classnames"
import { FC } from "react"
import Styles from './SortIdentifier.module.scss'

interface IdentiferProps {
    status: string | boolean;
}

export const SortIdentifier: FC<IdentiferProps> = ({ status }) => {
    return (
        <>
            {
                <div className={classNames(Styles.group)}>
                    <div className={classNames(Styles.control)}>
                        <SortIdentiferIcon color={status === 'desc' ? theme.firstColor : theme.secondColor} />
                    </div>
                    <div className={classNames(Styles.control, Styles.down)}>
                        <SortIdentiferIcon color={status === 'asc' ? theme.firstColor : theme.secondColor} />
                    </div>
                </div>
            }
        </>
    )

}
