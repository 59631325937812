import {api} from '@/shared/api/api'
import {types, flow} from 'mobx-state-tree'

export const TradePointModel = types.model({
    totalItems: types.number,
    itemsPerPage: types.number,
    totalPages: types.number,
    currentPage: types.number,
    currentRetailPlace: types.frozen(),
    loading: types.boolean,
    retailPlaces: types.array(types.frozen()),//Список по организации
    retailPlacesData: types.array(types.frozen()),//Общий список
}).actions(self => ({
    fetchTradePointsByOrgId: flow(function* (id: string, params?: any) {
        self.loading = true
        try {
            const response = yield api.getTradePointsByOrgId(id, params)
            self.retailPlaces = response.retailPlaces || []
            self.itemsPerPage = response.itemsPerPage
            self.currentPage = response.currentPage
            self.totalItems = response.totalItems
            self.totalPages = response.totalPages
        } catch (e) {
            throw e
        } finally {
            self.loading = false
        }
    }),
    clearRetailPlacesData: () => {
        self.retailPlacesData.splice(0, self.retailPlacesData.length)
    },
    fetchAll: flow(function* (data?: any, params?: any) {
        self.loading = true
        try {
            const response = yield api.getTradePointsList(data, params)
            self.totalItems = response.totalItems
            self.currentPage = response.currentPage
            self.totalPages = response.totalPages
            self.retailPlacesData = response.retailPlacesData || JSON.parse("[]")
            self.itemsPerPage = response.itemsPerPage
        } catch (e) {
            throw e
        } finally {
            self.loading = false;
        }

    }),
    fetchTradePointById: flow(function* (id: string) {
        self.loading = true
        try {
            const data = yield api.getTradePointById(id)
            self.currentRetailPlace = data
            return data
        } catch (e) {
            throw e
        } finally {
            self.loading = false
        }

    }),
    update: flow(function* (data: any) {
        try {
            yield api.updateTradePoint(data)
            self.currentRetailPlace.vpnStatus = data.vpnStatus
            self.currentRetailPlace.channelId = data.channelId
            // self.currentRetailPlace. = data.isDelete
        } catch (e) {
            throw e
        } finally {
            self.loading = false
        }
    })
})).views(self => ({
    tradePoint(id: number) {
        return self.retailPlaces.find(item => item.id === id)
    }
}))

export const TradePointStore = TradePointModel.create({
    loading: false,
    retailPlaces: [],
    totalItems: 0,
    totalPages: 0,
    currentPage: 0,
    itemsPerPage: 0,
})
