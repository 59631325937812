import { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Input, InputSearch } from '@/shared/ui/Inputs';
import { Button } from '@/shared/ui/Buttons';
import { Checkbox } from '@/shared/ui/Checkbox';
import { Card } from '@/shared/ui/Card';
import { SmsStore } from '@/store/SmsStore';
import { observer } from 'mobx-react-lite';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
	createColumnHelper,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import { ITaxpayer } from '@/shared/types/GlobalTypes';
import { TableView } from '@/shared/ui/Tables';
import { api } from '@/shared/api/api';
import s from './RecipientsNewsLetter.module.scss';
import { taxpayersServices } from '@/shared/api/taxpayersServices';
import { smsService } from '@/shared/api/smsService';
import { ResultUploadModalExcel } from '@/modals/Sms/ResultUploadModalExcel';
import { ModalSuccess } from '@/shared/ui/Modal';
import { toast } from 'react-toastify';
import FileUploadButton from '@/assets/icons/FileUploadButton';
import { autorun } from 'mobx';
import { ShowListRecipientsModal } from '@/modals/Sms/ShowListRecipientsModal';
import { IErrorMessage } from '@/shared/api/instanceAxios';
import axios from 'axios';

const pattern =
	/^\s*([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})\s*(,\s*[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})*\s*$/;
const columnHelper = createColumnHelper<ITaxpayer>();

interface IProps {
	onSend: (to: string) => Promise<any>;
}

export const RecipientsNewsLetter: FC<IProps> = observer(({ onSend }) => {
	const [fileExcel, setFileExcel] = useState<File | null>(null);
	const [checkedOrgEmails, setCheckedOrgEmails] = useState<string>('');
	const [allOrg, setAllOrg] = useState(false);
	const [search, setSearch] = useState('');
	const [sorting, setSorting] = useState<SortingState>([]);
	const [data, setData] = useState<ITaxpayer[]>([]);
	const { code } = useParams();
	const [showListRecipients, setShowListRecipients] = useState(false);
	const navigate = useNavigate();
	const [dirtySearch, setDirtySearch] = useState(false);
	useEffect(() => {
		autorun(() => {
			const to = SmsStore.currentTemplate?.headers.find((item) =>
				item.name === 'to');
			if (to) {
				setCheckedOrgEmails(to?.value);
			}
		});
	}, []);
	const isValidEmails = () => pattern.test(checkedOrgEmails.trim()) || !checkedOrgEmails.length;
	const handleCheckOrg = (email: string) => {
		if (!isValidEmails()) return;
		const emailArr = checkedOrgEmails.split(',').map((item) => item.trim()).filter((item) => item.trim());

		if (!emailArr.some((item) => item === email)) {
			emailArr.push(email);
		} else {
			const index = emailArr.findIndex((item) => item === email);
			emailArr.splice(index, 1);
		}
		setCheckedOrgEmails(emailArr.join(', '));
	};
	const isOrgChecked = (email: string) => {
		const arr = checkedOrgEmails.split(',').map((item) => item.trim());
		return arr.some((item) => item === email);
	};
	const columns = [
		columnHelper.display({
			id: 'id',
			cell: (props) => (
				<Checkbox
					checked={isOrgChecked(props.row.original.responsiblePersonEmail)}
					onChange={() =>
						handleCheckOrg(props.row.original.responsiblePersonEmail)}
				/>
			),
		}),
		columnHelper.accessor('iinBin', {
			header: 'ИИН/БИН',
			cell: ({ getValue, row }) => {
				return (
					<Link
						className={classNames('table-link')}
						to={`/taxpayers/${row.original.id}`}
					>
						{getValue()}
					</Link>
				);
			},
		}),
		columnHelper.accessor('title', {
			header: 'Организации',
		}),
		columnHelper.accessor('responsiblePersonEmail', {
			header: 'E-mail',
		}),
	];

	const tableInstance = useReactTable({
		columns,
		data,
		state: {
			sorting,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	});

	const handleSearch = async () => {
		try {
			const response = await api.getTaxpayerByIin(search);
			setData(response);
			setDirtySearch(true);
		} catch (e) {
			if (axios.isAxiosError(e)) {
				const response = await e.response?.data as IErrorMessage;
				toast.error(response?.details?.join(' ') || response.message, {
					position: 'bottom-right',
				});
			}
		}
	};

	const toggleAllOrg = async () => {
		if (allOrg) {
			setCheckedOrgEmails('');
			setFileExcel(null);
		} else {
			const response = await taxpayersServices.getAllEmailsTaxpayers();
			setCheckedOrgEmails(response.emails);
		}
		setAllOrg(!allOrg);
	};
	const [resultImportExcel, setResultImportExcel] = useState<any>({
		validEmails: [],
		invalidEmails: [],
	});
	const [showResultUploadExcelModal, setShowResultUpladExcelModal] = useState(
		false,
	);

	const handleFileInputChange = async (e: any) => {
		const file = e.target.files[0];
		setFileExcel(file);
		try {
			if (file) {
				const formData = new FormData();
				formData.append('file', file);
				const response = await smsService.emailsImportFromExcel(formData) as { validEmails: string[] };
				setResultImportExcel(response);
				setShowResultUpladExcelModal(true);
				setCheckedOrgEmails(() => response.validEmails.join(', '));
			}
		} catch (e) {
			toast.error('Произошла неизвестная ошибка, обратитесь к администратору', {
				position: 'bottom-right',
			});
		}

	};
	const fileInputRef = useRef<HTMLInputElement>(null);
	return (
		<Card className={cn('mt-10 py-30 px-27')}>
			<input
				style={{ display: 'none' }}
				type='file'
				ref={fileInputRef}
				onChange={handleFileInputChange}
			/>
			<div className={cn('c_first_color fs-16 fw-500 mb-30')}>
				Заголовки
			</div>
			<div className={cn('flex a-center mb-15')}>
				<div className={cn('grow flex a-center')}>
					<InputSearch
						refresh={() => {
							setSearch('');
							setData([]);
						}}
						withRefresh
						clearSearch={() => setSearch('')}
						className={cn('grow')}
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						placeholder='поиск получателей по ИИН/БИН организации'
					>
						<Button
							disabled={allOrg}
							className={cn(s.searchButton, 'mr-13')}
							onClick={handleSearch}
						>
							Найти
						</Button>
					</InputSearch>

				</div>
				<div className={cn('flex grow a-center j-end')}>
					<label className={cn('flex a-center mr-20 pointer')}>
						<Checkbox checked={allOrg} onChange={toggleAllOrg} />
						<span className={cn('c_first_color ml-5')}>
              Выбрать все организации
            </span>
					</label>
					<div>
						<Button
							onClick={() => {
								fileInputRef.current?.click();
							}}
							disabled={allOrg}
							className={cn(s.uploadButton)}
						>
							<div className='flex a-center j-center'>
								<FileUploadButton />
								<span className={cn('ml-8')}>
								Загрузить файл
								</span>
							</div>
						</Button>
					</div>
				</div>
			</div>
			{
				Boolean(data.length) || dirtySearch && <div className={'mb-30'}>Всего найдено: {data.length}</div>}
			<div className='flex j-end'>
				{fileExcel && <span className={cn('c_done_color')}>{fileExcel.name}</span>}
			</div>
			{data.length
				? (
					<>
						<TableView
							tableInstance={{ ...tableInstance }}
							className={classNames('table-letter', 'table-left')}
						/>{' '}
					</>
				)
				: ''}
			<div className='flex mb-7'>
				<div className='c_first_color grow'>
					Имя
				</div>
				<div className='c_first_color grow'>
					Значение
				</div>
			</div>
			{SmsStore.currentTemplate?.headers.map((item, index) => (
				<div className='flex mt-7 a-start'>
					<Input onChange={e => {
						SmsStore.setHeader(index, item.value, e.target.value);
					}} disabled={item.name === 'to'} className={cn('grow')} value={item.name} />
					<div className='flex grow a-start ml-12'>
						<div className={cn('grow')}>
							<Input
								onClick={() => setShowListRecipients(true)}
								className={cn('grow')}
								value={item.name === 'to' ? checkedOrgEmails : item.value}
								error={item.name === 'to' ? (isValidEmails() ? '' : 'Поле заполненно не корректно') : undefined}
							/>
						</div>
					</div>
				</div>
			))}
			<div className='flex mt-40'>
				<div className='flex mr-18'>
					<Button onClick={() => {
						SmsStore.fetchTemplate(code as string);
						if (fileInputRef.current) {
							fileInputRef.current.value = '';
						}
						setFileExcel(null);
						navigate(-1);
					}} className={'px-35'} color={'cancel'}>
						Отмена
					</Button>
				</div>
				<div className='flex'>
					<Button disabled={!isValidEmails()} className={'px-24'} onClick={async () => {
						try {
							await onSend(checkedOrgEmails);
							toast.success('Рассылка успешно отправлена', {
								position: 'bottom-right',
							});
							setTimeout(() => navigate('/sms?activeTab=1'), 3000);
						} catch (e) {
							if (axios.isAxiosError(e)) {
								const response = await e.response?.data as IErrorMessage;
								toast.error(response?.details?.join(' '), {
									position: 'bottom-right',
								});
							}
						}
					}}>
						Отправить
					</Button>
				</div>
			</div>
			{(showResultUploadExcelModal && resultImportExcel?.invalidEmails.length) &&
				(
					<ResultUploadModalExcel
						onClose={() => setShowResultUpladExcelModal(false)}
						validEmails={resultImportExcel?.validEmails || []}
						invalidEmails={resultImportExcel?.invalidEmails || []}
					/>
				)
			}
			{
				showListRecipients &&
				<ShowListRecipientsModal emailList={checkedOrgEmails.split(',')}
																 setEmailsList={(value: string) => setCheckedOrgEmails(value)}
																 onClose={() => setShowListRecipients(false)} />
			}
			{
				(showResultUploadExcelModal && !resultImportExcel?.invalidEmails?.length) &&
				<ModalSuccess onClose={() => setShowResultUpladExcelModal(false)}>
					<span className={cn('fs-12 c_first_color')}>
						Все файлы успешно загружены
					</span>
				</ModalSuccess>}
		</Card>
	);
});
