import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom';
import Styles from './MenuListItem.module.scss'
import classNames from 'classnames'
import { IconProp } from '@/assets/icons/menu/iconType';
import theme from '@/shared/themes';

interface MenuItemProps {
    name: string;
    path: string;
    icon: FC<IconProp>;
    show: boolean;
}


export const MenuListItem: FC<MenuItemProps> = ({ name, path, icon, show }) => {
    const match = useLocation().pathname.includes(path)
    return (
        <Link
            className={classNames(Styles.menuLink, match ? Styles.active : "")}
            to={path} >
            <div className={classNames(Styles.icon)} data-tip={!show ? name : ''}>
                {
                    icon({ color: match ? theme.fifthColor : theme.mainLinkColor })
                }
            </div>
            {show &&
                <span className={classNames(Styles.name)}>{name}</span>

            }
        </Link >
    )

}
