import { instance } from "./instanceAxios"

export const usersServices = {
    getUsersList: async (data?: any, params?: any) => {
        const response = await instance.post("/user/list", data, {
            params,
        })
        return response.data
    },
    createUser: async (user: any) => {
        const response = await instance.post('/user', user)
        return response.data
    },
    editUser: async (id: number, user: any) => {
        const response = await instance.put(`/user/${id}`, user)
        return response.data
    },
    deleteUser: async (id: number) => {
        const response = await instance.delete(`/user/${id}`)
        return response.data
    },
    userSetActivate: async (user: any, status: boolean) => {
        const response = await instance.get(`/user/${user}/${status}`)
        return response.data
    },
    getUserById: async (id: number) => {
        const response = await instance.post(`/user/${id}`)
        return response.data
    },
    getUserDetails: async (id: number) => {
        const response = await instance.get(`/users/${id}`)
        return response.data
    }

}

