import React from 'react'
import Styles from './Preload.module.scss'
import { Player } from '@lottiefiles/react-lottie-player'
import animation from '@/assets/animations/preloadAnimation.json'
export const Preload = () => {
    return (
        <div className={Styles.wrap}>
            <div className={Styles.container}>
                <Player src={animation} loop autoplay className={Styles.preload} />
                <div className="c_first_color">
                    Подождите, пожалуйста, идет загрузка...
                </div>
            </div>
        </div>
    )
}
