import { FC } from 'react'

interface Prop {
    color: string;
}

export const SortIdentiferIcon: FC<Prop> = ({ color }) => {
    return (
        <svg width="8.36" height="4.64" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.43147 4.64282H5.15022L8.49889 4.64282C9.07193 4.64282 9.35844 3.93812 8.95254 3.52501L5.86055 0.378137C5.36511 -0.126092 4.55928 -0.126092 4.06384 0.378137L2.88793 1.57492L0.971845 3.52501C0.571914 3.93812 0.858432 4.64282 1.43147 4.64282Z" fill={color} />
        </svg>
    )
}


