import { FC } from 'react'
import Styles from './Button.module.scss'
import classNames from 'classnames'
import { ButtonProps } from './ButtonPropsType'


export const Button: FC<ButtonProps> = ({ children, onClick, className, disabled, color, isIcon, style, type = "button", tip }) => {
    const getColor = () => {
        if (!color) {
            return Styles.simple
        }
        return Styles[color]
    }
    return (
        <button
            type={type}
            style={style}
            className={classNames(Styles.button, getColor(), className, isIcon ? Styles.icon : "")}
            disabled={disabled}
            data-tip={tip}
            onClick={onClick} >
            {children}
        </button >
    )
}
