import { FC, useEffect } from 'react'
import { SidePanel } from '@/components/Layouts/SidePanel/SidePanel'
import { Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'
import Styles from './General.module.scss'
import { ToastContainer } from 'react-toastify'
import { rootStore } from '@/store/rootStore'
import menuItems from '@/shared/static/menuItems'
import { autorun } from 'mobx'

export const MainLayout: FC = () => {
    const match = useMatch("/")
    const location = useLocation()
    const navigate = useNavigate()
    useEffect(() => {

        autorun(() => {
            const path = menuItems.find(item => {
                return Boolean(rootStore.menu.find(_item => {
                    return (item.name === _item.nameRu || item?.alt === _item.nameRu)
                }))
            })?.path
            if (match && path) navigate(path)
        })
    }, [match])

    useEffect(() => {
        ReactTooltip.rebuild()
    }, [location.pathname])

    return (
        <div className='main-container'>
            <SidePanel />
            <div className="page">
                <Outlet />
            </div>
            <ReactTooltip arrowColor={"rgba(0,0,0,0)"} border={false} className={classNames(Styles.toolTip)} />
            <ToastContainer />
        </div>
    )
}
