import {instance} from './instanceAxios'

const BASE_URL = '/auth'

export const authServices = {
    signIn: async (data: any) => {
        return await instance.post(`${BASE_URL}/sign-in`, data)
    },
    checkRecoveryCode: async (email: string, code: string) => {
        return await instance.get(`${BASE_URL}/check-recovery-code/${email}/${code}`)
    },
    logout: async (refreshToken: string) => {
        const response = await instance.post(`${BASE_URL}/logout`, { refreshToken })
        return response.data
    },

    getCodePassReset: async (email: string) => {
        const response = await instance.get(`${BASE_URL}/send-recovery-code`, {
            params: { email }
        })
        return response.data
    },

    sendNewPassword: async (email: string, code: string, newPassword: string) => {
        const response = await instance.post('/auth/set-new-password', {
            email,
            code,
            newPassword
        })
        return response.data
    },
    getMe: async () => {
        const response = await instance.get(`${BASE_URL}/me`)
        return response.data
    }
}


