import React, { FC } from 'react'

interface IDangerIcon {
    width?: string;
    height?: string;
}

export const DangerIcon: FC<IDangerIcon> = ({ width = "30", height = "31" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.2 20.3615L19.2 5.96155C18.125 4.02405 16.6375 2.96155 15 2.96155C13.3625 2.96155 11.875 4.02405 10.8 5.96155L2.79997 20.3615C1.78747 22.199 1.67497 23.9615 2.48747 25.349C3.29997 26.7365 4.89997 27.499 6.99997 27.499H23C25.1 27.499 26.7 26.7365 27.5125 25.349C28.325 23.9615 28.2125 22.1865 27.2 20.3615ZM14.0625 11.7115C14.0625 11.199 14.4875 10.774 15 10.774C15.5125 10.774 15.9375 11.199 15.9375 11.7115V17.9615C15.9375 18.474 15.5125 18.899 15 18.899C14.4875 18.899 14.0625 18.474 14.0625 17.9615V11.7115ZM15.8875 22.599C15.825 22.649 15.7625 22.699 15.7 22.749C15.625 22.799 15.55 22.8365 15.475 22.8615C15.4 22.899 15.325 22.924 15.2375 22.9365C15.1625 22.949 15.075 22.9615 15 22.9615C14.925 22.9615 14.8375 22.949 14.75 22.9365C14.675 22.924 14.6 22.899 14.525 22.8615C14.45 22.8365 14.375 22.799 14.3 22.749C14.2375 22.699 14.175 22.649 14.1125 22.599C13.8875 22.3615 13.75 22.0365 13.75 21.7115C13.75 21.3865 13.8875 21.0615 14.1125 20.824C14.175 20.774 14.2375 20.724 14.3 20.674C14.375 20.624 14.45 20.5865 14.525 20.5615C14.6 20.524 14.675 20.499 14.75 20.4865C14.9125 20.449 15.0875 20.449 15.2375 20.4865C15.325 20.499 15.4 20.524 15.475 20.5615C15.55 20.5865 15.625 20.624 15.7 20.674C15.7625 20.724 15.825 20.774 15.8875 20.824C16.1125 21.0615 16.25 21.3865 16.25 21.7115C16.25 22.0365 16.1125 22.3615 15.8875 22.599Z" fill="#F9703E" />
        </svg>
    )
}



