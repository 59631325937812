import React, {FC} from 'react'
import {NoDataIcon} from '@/assets/icons/NoDataIcon'
import Styles from './PageNoData.module.scss'
import classNames from 'classnames'

interface Props {
    message?: string;
}

export const PageNoData: FC<Props> = ({message}) => {
    return (
        <div className={classNames("flex column a-center", Styles.wrapper)}>
            <div className={Styles.content}>
                <div className='mb-18'>
                    <NoDataIcon/>
                </div>
                <div className={classNames(Styles.title, "mb-18")}>
                    Здесь пока ничего нет
                </div>
                {
                    message && <div className={Styles.subtitle}>
                        {message}
                    </div>
                }

            </div>
        </div>
    )
}
