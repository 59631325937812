import { instance } from "./instanceAxios"

export const tradePointsServices = {
    getTradePointsByOrgId: async (orgId: string, params?: any) => {
        const response = await instance.get(`/retail-place/list/${orgId}`, {
            params,
        })
        return response.data
    },
    getTradePointsList: async (data: any, params?: any) => {
        const response = await instance.post("/retail-place/list", {
            ...data,
        }, {
            params,
        })
        return response.data
    },
    getTradePointById: async (id: string) => {
        const response = await instance.get(`/retail-place/${id}`)
        return response.data
    },
    updateTradePoint: async (data: any) =>{
        const response = await instance.put('/retail-place/update',data)
        return response.data
    }
}