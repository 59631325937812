import {useCallback, useState} from 'react'
interface ISelectItem {
    id: number;
    label: string | number;
    checked: boolean;
}

interface IOptions {
    noSelectedMessage?: string;
}


export const useSelect = (options: ISelectItem[], option?: IOptions) => {
    const [data, setOptions] = useState(options)
    const getLabelOption = () => data.find(item => item.checked)?.label || option?.noSelectedMessage || ''
    const handleChangeOption = useCallback((id: number) => {
        setOptions( state =>
            [
                ...state.map(item => {
                    const obj = { ...item }
                    obj.checked = item.id === id
                    return obj
                })
            ]
        )
    },[])



    return {
        options: data,
        handleChangeOption,
        getLabelOption,
        setOptions
    }

}
