import React, {FC} from 'react'
import theme from '@/shared/themes'
export const SearchFolderIcon: FC = () => {
    return (
        <svg width="135" height="111" viewBox="0 0 135 111" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M122.631 11.6335H15.9004C11.7876 11.6335 8.4541 14.967 8.4541 19.0799V33.9725H122.631V11.6335Z" fill={theme.mainLinkColor} />
            <path d="M122.631 7.44633C122.631 3.33347 119.298 0 115.185 0C104.688 0 85.7646 0 76.268 0C72.7062 0 69.4149 1.90874 67.6477 5.00145C64.7163 10.1345 60.5786 17.3748 60.5786 17.3748H122.631V7.44633Z" fill={theme.mainLinkColor} />
            <path d="M8.4541 18.6108H122.631V23.235H8.4541V18.6108Z" fill={theme.mainLinkColor} />
            <path d="M130.991 32.9401C131.386 30.0956 130.529 27.2189 128.645 25.0545C126.759 22.8876 124.029 21.644 121.157 21.644C95.8491 21.644 35.236 21.644 9.9284 21.644C7.05659 21.644 4.32627 22.8876 2.43987 25.0545C0.555947 27.2189 -0.300381 30.0956 0.0942741 32.9401C2.61113 51.0595 7.49593 86.2261 9.7472 102.439C10.4298 107.346 14.6245 111 19.5813 111H111.504C116.461 111 120.655 107.346 121.338 102.439C123.589 86.2261 128.474 51.0595 130.991 32.9401Z" fill="#CADCF0" />
            <path d="M0.928263 38.9468C1.11442 36.8792 1.94345 34.9035 3.33095 33.31C5.21487 31.1456 7.94519 29.902 10.817 29.902H120.268C123.14 29.902 125.87 31.1456 127.754 33.31C129.142 34.9035 129.971 36.8792 130.157 38.9468L130.991 32.9401C131.386 30.0956 130.529 27.2189 128.645 25.0545C126.759 22.8876 124.029 21.644 121.157 21.644C95.8491 21.644 35.236 21.644 9.9284 21.644C7.05659 21.644 4.32627 22.8876 2.43987 25.0545C0.555947 27.2189 -0.300381 30.0956 0.0942741 32.9401L0.928263 38.9468Z" fill={theme.fourthColor} />
            <path d="M54.3757 86.2434C54.3757 83.5031 52.1542 81.2792 49.4114 81.2792C43.9036 81.2792 35.0648 81.2792 29.5546 81.2792C26.8143 81.2792 24.5903 83.5031 24.5903 86.2434V91.2076C24.5903 93.9503 26.8143 96.1718 29.5546 96.1718H49.4114C52.1542 96.1718 54.3757 93.9503 54.3757 91.2076C54.3757 89.6116 54.3757 87.8419 54.3757 86.2434Z" fill={theme.mainColor} />
            <path d="M91.6046 39.6145C77.2208 39.6145 65.5425 51.2928 65.5425 65.6767C65.5425 80.0605 77.2208 91.7388 91.6046 91.7388C105.988 91.7388 117.667 80.0605 117.667 65.6767C117.667 51.2928 105.988 39.6145 91.6046 39.6145ZM91.6046 47.0608C101.878 47.0608 110.22 55.4032 110.22 65.6767C110.22 75.9501 101.878 84.2925 91.6046 84.2925C81.3312 84.2925 72.9888 75.9501 72.9888 65.6767C72.9888 55.4032 81.3312 47.0608 91.6046 47.0608Z" fill={theme.mainColor} />
            <path d="M105.179 83.6745L128.332 106.828C129.787 108.282 132.145 108.282 133.6 106.828C135.052 105.376 135.052 103.015 133.6 101.563L110.446 78.4099C108.992 76.9579 106.634 76.9579 105.179 78.4099C103.727 79.862 103.727 82.2225 105.179 83.6745Z" fill={theme.mainColor} />
        </svg>

    )
}

