import axios from 'axios';
import { IErrorMessage } from '@/shared/api/instanceAxios';
import { toast } from 'react-toastify';

export const showToast = async (callback: any, successMessage?: string | null) => {
	try {
		const response = await callback();
		toast.success(response.message || successMessage, {
			position: 'bottom-right',
		});
		return true;
	} catch (e) {
		if (axios.isAxiosError(e)) {
			const response = await e.response?.data as IErrorMessage;
			toast.error(response?.details?.join(' ') || response.message, {
				position: 'bottom-right',
			});
		}
		return false;
	}
};
