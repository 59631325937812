import {instance} from './instanceAxios'

export const businessProcessesServices = {
    getBusinessProcessesList: async (params: any) => {
        const response = await instance.get('/bpmn-process/list', {
            params,
        })
        return response.data
    },
    createBusinessProcess: async (data: any, setProgress: (progress: number) => void, file: File) => {
        const response = await instance.post('/bpmn-process/create', data, {
            onUploadProgress: (e) => {
                setProgress((e.loaded * 100) / file.size)
            }
        })
        return response.data
    },
    uploadBusinessProcess: async (data: any, setProgress: (progress: number) => void, file?: File) => {
        const response = await instance.post('/bpmn-process/update', data, {
            onUploadProgress: (e) => {
                if (file) setProgress((e.loaded * 100) / file.size)
            }
        })
        return response.data
    },
    getBusinessProcessById: async (id: number) =>{
        const response = await  instance.get(`/bpmn-process/${id}`)
        return response.data
    }
}
