import classNames from 'classnames';
import {observer} from 'mobx-react-lite';
import React, {FC} from 'react'
import Styles from './Dictionary.module.scss'

interface IDictionary {
    key?: string | number;
    isFirst?: boolean;
    value: string | null | undefined | number;
    status?: boolean;
    isNotVisible?: boolean
}


interface IDictionaryProps {
    data: IDictionary[],
    className?: string;
}

export const Dictionary: FC<IDictionaryProps> = observer(
    ({data, className}) => {
        return (
            <>
                {
                    data.filter(item => !item.isNotVisible).map((item) =>
                        <div key={item.key || item.value}
                             className={classNames(item.isFirst ? Styles.keyFirst : Styles.itemWrapper, className)}>
                            {item.key && <span className={classNames(Styles.key)}>{item.key}:</span>}
                            <span
                                className={classNames(Styles.value, item.status ? Styles.active : '')}> {item.value}</span>
                        </div>
                    )
                }
            </>
        )
    }
)
