import {useState, FC, useMemo, useEffect, useRef, useCallback} from 'react'
import {Card} from '@/shared/ui/Card'
import classNames from 'classnames'
import {PageTitle} from '@/shared/ui/PageTitle'
import {Button} from '@/shared/ui/Buttons'
import {InputSearchIcon} from '@/shared/ui/Inputs'
import {SearchIcon} from '@/assets/icons/SearchIcon.'
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from '@tanstack/react-table'
import {TableView} from '@/shared/ui/Tables'
import {observer} from 'mobx-react-lite'
import {getSnapshot} from 'mobx-state-tree'
import {CardUserModal, TypeModal} from '@/modals/User/CardUserModal'
import {DeleteUserModal} from '@/modals/User/DeleteUserModal'
import {ContextMenu, ContextMenuItem} from '@/shared/ui/Menu'
import {EyeIcon} from '@/assets/icons/EyeIcon'
import {TrashIcon} from '@/assets/icons/TrashIcon'
import {EditIcon} from '@/assets/icons/EditIcon'
import theme from '@/shared/themes'
import {ButtonRefresh} from '@/shared/ui/Buttons'
import {SearchNoResult} from '@/shared/ui/SearchNoResult'
import {PageNoData} from '@/shared/ui/PageNoData'
import {UsersStore} from '@/store/UsersStore.'
import {IUser} from '@/shared/types/GlobalTypes'
import {useSearchParams} from 'react-router-dom'
import {Paginate} from '@/shared/ui/Paginate'
import {Preload} from '@/shared/ui/Preload'
import {formatDate} from "@/shared/lib";
import {formatDateUnix} from "@/shared/lib/formatDate";

const columnHelper = createColumnHelper<IUser>()

export const UsersPage: FC = observer(() => {

    const [searchParams, setSearchParams] = useSearchParams()
    const page = searchParams.get('page')
    const size = searchParams.get('size')
    const searchQueryParam = searchParams.get("search"
    )
    const [sorting,setSoring] = useState<SortingState>([])
    const searchInput = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (searchInput.current) {
            searchInput.current.value = searchQueryParam || ""
        }
    }, [searchInput, searchQueryParam])

    /** **/
    const fetchData = useCallback(() => {
            return UsersStore.fetchAll({
                fullName: searchQueryParam
            }, {page, size})
        }
        , [size, page, searchQueryParam])

    useEffect(() => {
        fetchData()
        if (Number(size) > 100) {
            setSearchParams(param => {
                param.set("size", "10")
                return param
            })
        }
    }, [size, page, setSearchParams, fetchData])

    const [typeModal, setTypeModal] = useState<null | TypeModal>(null)
    const [showModal, setShowModal] = useState(false)
    const [currentUserId, setCurrentUserId] = useState<null | number>(null)

    const openCardModal = (type: TypeModal, userId?: number) => {
        setTypeModal(type)
        setShowModal(true)
        if (userId) setCurrentUserId(userId)
    }

    const closeCardModal = () => {
        setShowModal(false)
        setCurrentUserId(null)
        setTypeModal(null)
    }

    const onPaginate = (event: React.ChangeEvent<unknown>, page: Number) => {
        setSearchParams(param => {
            param.set("page", page.toString())
            return param
        })
    }

    const onChangeSelectSize = (size: number) => {
        setSearchParams(param => {
            param.set("size", size.toString())
            return param
        })
    }

    const handleSearch = () => {
        const searchQuery = searchInput.current?.value
        setSearchParams({
            search: searchQuery || ""
        })
    }

    const handleReset = () => {
        setSearchParams({})
        if (searchInput.current) {
            searchInput.current.value = ""
        }
    }

    const [deleteUserModal, setDeleteUserModal] = useState<boolean>(false)


    const [currentUser, setCurrentUser] = useState<number | null>(null)

    /*creating table */
    const columns = useMemo(() =>
            [
                columnHelper.accessor('createdDate', {
                    header: 'Дата создания',
                    cell: ({getValue}) => formatDate(getValue()),
                    sortingFn: (a,b) =>{
                        return formatDateUnix(b.original.createdDate) - formatDateUnix(a.original.createdDate);
                    }
                }),
                columnHelper.accessor('role', {
                    header: 'Роль',
                    cell: ({getValue}) => getValue()?.name
                }),
                columnHelper.accessor('fullName', {
                    header: 'ФИО'
                }),
                columnHelper.accessor('blocked', {
                    header: 'Статус',
                    cell: ({getValue}) => <span
                        className={classNames(!getValue() && 'c_done_color')}>{!getValue() ? 'Активный' : 'Деактивен'}</span>
                }),
                columnHelper.display({
                    id: 'id',
                    enableSorting: false,
                    cell: (props) =>
                        <ContextMenu>
                            <ContextMenuItem onClick={() => openCardModal(TypeModal.VIEW, props.row.original.id)}>
                                <div className="flex a-center">
                                    <div className='flex a-center mr-10'>
                                        <EyeIcon color={theme.firstColor}/>
                                    </div>
                                    <span>
                                    Просмотр карточки
                                </span>
                                </div>
                            </ContextMenuItem>
                            <ContextMenuItem onClick={() => openCardModal(TypeModal.EDIT, props.row.original.id)}>
                                <div className="flex a-center">
                                    <div className="flex a-center mr-10">
                                        <EditIcon/>
                                    </div>
                                    <span>
                                    Редактировать карточку
                                </span>
                                </div>
                            </ContextMenuItem>
                            <ContextMenuItem onClick={() => {
                                setCurrentUser(props.row.original.id)
                                setDeleteUserModal(true)
                            }
                            }>
                                <div className="flex a-center">
                                    <div className="flex a-center mr-10">
                                        <TrashIcon color={theme.deleteColor} width='15' height='16'/>
                                    </div>
                                    <div className='c_danger_color'>
                                        Удалить пользователя
                                    </div>
                                </div>

                            </ ContextMenuItem>
                        </ContextMenu>

                })
            ]
        , [])

    const tableInstance = useReactTable({
        columns,
        data: getSnapshot(UsersStore.users),
        state:{
            sorting
        },
        onSortingChange: setSoring,
        getSortedRowModel: getSortedRowModel(),
        getCoreRowModel: getCoreRowModel(),
    })


    return (
        <>
            <Card className={classNames('py-30 px-27 mb-12')}>
                <PageTitle className={classNames('mb-30')}>
                    Пользователи
                </PageTitle>
                <div className="flex a-center">
                    <InputSearchIcon
                        ref={searchInput}
                        className={classNames('input')}
                        id="search-input"
                        placeholder='Введите ФИО для поиска'>
                        <Button onClick={handleSearch} isIcon className='search-button'>
                            <SearchIcon color={theme.fifthColor}/>
                        </Button>
                    </InputSearchIcon>
                    <ButtonRefresh className='mr-32' onClick={handleReset}/>
                    <Button onClick={() => openCardModal(TypeModal.CREATE)} className={classNames('button')}>
                        <div className="flex j-center a-center">
                            <span className='mr-15 fs-20 plus'>+</span>
                            <span>Добавить пользователя</span>
                        </div>
                    </Button>
                </div>
            </Card>
            {
                !UsersStore.users.length && !searchInput.current?.value
                    ?
                    <PageNoData/> :
                    !UsersStore.users.length && searchInput.current?.value ?
                        <SearchNoResult searchQuery={searchInput.current?.value}/> :
                        <Card>
                            <TableView tableInstance={{...tableInstance}}
                                       className={classNames('table-border-radius table-left')}/>
                        </Card>
            }
            {
                deleteUserModal &&
                <DeleteUserModal fetchData={fetchData} userId={currentUser} onClose={() => setDeleteUserModal(false)}/>
            }
            {
                UsersStore.users.length ?
                    <Paginate
                        onChange={onPaginate}
                        currentPage={UsersStore.currentPage}
                        countPages={UsersStore.totalPages}
                        size={size}
                        onChangeSelectSize={onChangeSelectSize}
                    /> : ''
            }
            {
                showModal &&
                <CardUserModal fetchData={fetchData} id={currentUserId} type={typeModal}
                               onEdit={() => setTypeModal(TypeModal.EDIT)}
                               onClose={closeCardModal}/>
            }
            {
                UsersStore.loading && <Preload/>
            }
        </>

    )
})
