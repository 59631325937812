import { instance } from '@/shared/api/instanceAxios';

interface ITemplateList {
	code: string;
	title: string;
	subject: string;
}

const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_HOST_BACKEND_SMS_DEV : process.env.REACT_APP_HOST_BACKEND_SMS_PROD;
export const smsService = {
		getTemplateList: async () =>
			instance.get<ITemplateList[]>('/letter/list', {
				baseURL,
			}).then((response) => response.data),
		getTemplateData: async (code: string) =>
			instance.get('/letter/data', {
				baseURL,
				params: {
					code,
				},
			}).then((response) => response.data),
		changeTemplate: async (code: string, data: any) =>
			instance.put('/letter/update', data, {
				baseURL,
				params: {
					code,
				},
			}),
		emailsImportFromExcel: (data: FormData) =>
			instance.post('/letter/import-excel', data, {
				baseURL,
			}).then((response) => response.data),
		sendLetter: (data: FormData) => instance.post('/letter/send/email', data, {
			baseURL,
		}),

	}
;
