import React from "react";

export const ArrowIcon = () => (
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.71136 5.5H5.9926H2.64393C2.0709 5.5 1.78438 6.20471 2.19028 6.61781L5.28228 9.76469C5.77771 10.2689 6.58354 10.2689 7.07898 9.76469L8.25489 8.5679L10.171 6.61781C10.5709 6.20471 10.2844 5.5 9.71136 5.5Z"
      fill="#758EA8"
    />
  </svg>
);
