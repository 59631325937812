import React from 'react'

export const SuccessUploadIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0003 1.6665C5.40866 1.6665 1.66699 5.40817 1.66699 9.99984C1.66699 14.5915 5.40866 18.3332 10.0003 18.3332C14.592 18.3332 18.3337 14.5915 18.3337 9.99984C18.3337 5.40817 14.592 1.6665 10.0003 1.6665ZM13.9837 8.08317L9.25866 12.8082C9.14199 12.9248 8.98366 12.9915 8.81699 12.9915C8.65033 12.9915 8.49199 12.9248 8.37533 12.8082L6.01699 10.4498C5.77533 10.2082 5.77533 9.80817 6.01699 9.5665C6.25866 9.32484 6.65866 9.32484 6.90033 9.5665L8.81699 11.4832L13.1003 7.19984C13.342 6.95817 13.742 6.95817 13.9837 7.19984C14.2253 7.4415 14.2253 7.83317 13.9837 8.08317Z" fill="#4AB568" />
        </svg>
    )
}



