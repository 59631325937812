import { ModalSuccess } from '@/shared/ui/Modal/ModalSuccess'
import { IModalProps } from '@/shared/types/ModalProps'
import { observer } from 'mobx-react-lite';
import React, { FC, useState } from 'react'
import { GeneralDeleteModal } from '../Generals/GeneralDeleteModal'
import {ServiceAccountStore} from "@/store/ServiceAccountStore";
import Styles from './DeleteServiceAccountModal.module.scss'
interface IDeleteModalProps extends IModalProps {
    accountId: number | null;
}

export const DeleteServiceAccountModal: FC<IDeleteModalProps> = observer(({ onClose, accountId }) => {
    const [successDeleteUser, setSuccessDeleteUser] = useState<Boolean>(false)
    const handleAction = async () => {
        await ServiceAccountStore.deleteServiceAccount(accountId as number)
        setSuccessDeleteUser(true)
    }
    return (
        <>
            {successDeleteUser ?
                <ModalSuccess onClose={onClose} className='py-3 c_first_color'>
                    Служебный аккаунт удален!
                </ModalSuccess>
                :
                <GeneralDeleteModal onClose={onClose} onConfirmation={handleAction} className={Styles.wrapperModal}>
                    <div className='fw-500 fs-18 mb-47 c_first_color text-no-wrap'>
                        Вы действительно хотите удалить этот служебный аккаунт?
                    </div>
                </GeneralDeleteModal>
            }
        </>

    )
})

