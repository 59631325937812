import React, {FC, forwardRef} from 'react'
import {InputProps} from '@/shared/types/InputPropsType'
import classNames from 'classnames'
import Styles from './RadioButton.module.scss'


export const RadioButton: FC<InputProps> =
    forwardRef<HTMLInputElement, InputProps>(({
                                                  checked,
                                                  name,
                                                  id,
                                                  onChange,
                                                  value,
                                              }, ref) => {
            return (
                <div className={classNames(Styles.wrapper)}>
                    <input
                        className={classNames(Styles.radio)}
                        type="radio"
                        ref={ref}
                        name={name}
                        value={value}
                        id={id}
                        onChange={onChange}
                        checked={checked}/>
                    <div className={classNames(Styles.circle)}>
                        <div className={classNames(Styles.innerCircle)}></div>
                    </div>
                </div>
            )
        }
    )
