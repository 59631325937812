import React from 'react'

export const DownloadFileIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.1733 14.8332H5.82665C2.55332 14.8332 1.15332 13.4332 1.15332 10.1599V10.0732C1.15332 7.11319 2.31999 5.68652 4.93332 5.43985C5.19999 5.41985 5.45332 5.61985 5.47999 5.89319C5.50665 6.16652 5.30665 6.41319 5.02665 6.43985C2.93332 6.63319 2.15332 7.61985 2.15332 10.0799V10.1665C2.15332 12.8799 3.11332 13.8399 5.82665 13.8399H10.1733C12.8867 13.8399 13.8467 12.8799 13.8467 10.1665V10.0799C13.8467 7.60652 13.0533 6.61985 10.92 6.43985C10.6467 6.41319 10.44 6.17319 10.4667 5.89985C10.4933 5.62652 10.7267 5.41985 11.0067 5.44652C13.66 5.67319 14.8467 7.10652 14.8467 10.0865V10.1732C14.8467 13.4332 13.4467 14.8332 10.1733 14.8332Z" fill="#4A4A4A" />
            <path d="M8 10.4998C7.72667 10.4998 7.5 10.2731 7.5 9.99975V2.41309C7.5 2.13975 7.72667 1.91309 8 1.91309C8.27333 1.91309 8.5 2.13975 8.5 2.41309V9.99975C8.5 10.2731 8.27333 10.4998 8 10.4998Z" fill="#4A4A4A" />
            <path d="M10.2336 4.39964C10.1069 4.39964 9.98022 4.35297 9.88022 4.25297L8.00022 2.37297L6.12022 4.25297C5.92689 4.4463 5.60689 4.4463 5.41355 4.25297C5.22022 4.05964 5.22022 3.73964 5.41355 3.5463L7.64689 1.31297C7.84022 1.11964 8.16022 1.11964 8.35355 1.31297L10.5869 3.5463C10.7802 3.73964 10.7802 4.05964 10.5869 4.25297C10.4936 4.35297 10.3602 4.39964 10.2336 4.39964Z" fill="#4A4A4A" />
        </svg>

    )
}


