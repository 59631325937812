import React, { FC, useEffect, useState } from 'react'
import { FileUploadIcon } from '@/assets/icons/FileUploadIcon';
import { Modal } from '@/shared/ui/Modal'
import Styles from './FileUploadBP.module.scss'
import { CloseUploadIcon } from '@/assets/icons/CloseUploadIcon';
import classNames from 'classnames';
import { SuccessUploadIcon } from '@/assets/icons/SuccessUploadIcon';
import { RefreshIcon } from '@/assets/icons/RefreshIcon';
import theme from '@/shared/themes'
interface IFileUpload {
    onClose: () => void;
    file: File;
    progress: number;
    error: boolean;
}

export const FileUploadBP: FC<IFileUpload> = ({ onClose, file, progress, error }) => {

    const [clickOutside, setClickOutside] = useState(false)

    useEffect(() => {
        if (progress >= 100) {
            setClickOutside(true)
        }
    }, [progress])
    return (
        <Modal className={Styles.modal} onClose={onClose} clickOutside={clickOutside}>
            <div className={Styles.wrapper}>
                <div className="flex a-center mb-14">
                    <div className='mr-15'>
                        <FileUploadIcon />
                    </div>
                    <div>
                        <div className='fs-14 c_first_color'>
                            {
                                error ? "Не удалось загрузить файл" : file.name
                            }
                        </div>
                        <div className='fs-12'>
                            {
                                error ? "Попробуйте, пожалуйста, снова или загрузите другой" : `${Math.floor(file.size / 1000)} Кб`
                            }
                        </div>
                    </div>
                </div>
                <div className="flex a-center">
                    {
                        error ?
                            <div onClick={onClose} className='flex j-end grow a-center pointer'>
                                <div className={classNames(Styles.again, "mr-8")}>
                                    Попробовать снова
                                </div>
                                <div className='flex j-center a-center'>
                                    <RefreshIcon color={theme.mainLinkColor} />
                                </div>
                            </div> : <>
                                <progress className={classNames(Styles.progress, "mr-10")} value={progress} max={100} />
                                {
                                    progress < 100 ? <button onClick={onClose}><CloseUploadIcon /></button> :
                                        <SuccessUploadIcon />
                                }
                            </>
                    }

                </div>
            </div>
        </Modal>
    )
}
