import theme from '@/shared/themes'
import {FC} from 'react'

export const BreadCumpArrowIcon: FC = () => {
    return (
        <svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.999796 1L4.41895 4.5L0.999796 8" stroke={theme.secondColor} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}


