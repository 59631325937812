import React, { useState, forwardRef } from 'react'
import classNames from 'classnames'
import Styles from './Input.module.scss'
import { InputProps } from '@/shared/types/InputPropsType'
import { InputError } from './InputError'


interface InputPasswordProps extends InputProps {
    visible?: boolean;
}

export const InputPassword = forwardRef<HTMLInputElement, InputPasswordProps>(({ className,
    placeholder,
    onChange,
    error,
    visible,
    onBlur,
    name,
    value },
    ref) => {
    const [showPass, setShowPass] = useState<boolean>(visible || false)
    const [refs, setRefs] = useState<HTMLInputElement | null>(null)

    const handleVisible = (event: React.MouseEvent) => {
        setShowPass(!showPass)
        refs?.focus()
    }

    return (
        <div className={classNames(Styles.inputWrapper, typeof (className) != 'string' ? className?.wrapper : '')}>
            <input
                ref={(element) => {
                    setRefs(element)
                    return typeof ref === 'function' ? ref(element) : ""

                }}
                onBlur={onBlur}
                name={name}
                autoComplete="on"
                type={showPass ? 'text' : 'password'}
                onChange={onChange}
                value={value}
                placeholder={placeholder}
                className={classNames(Styles.input, error?.length ? Styles.error : "", typeof (className) === 'string' ? className : className?.input)} />
            {
                error?.length &&
                <InputError error={error} />

            }
            <button type='button' onClick={handleVisible} className={classNames(Styles.eye)}></button>
        </div >
    )
}
)
