import {PageTitle} from '@/shared/ui/PageTitle'
import {Paginate} from '@/shared/ui/Paginate'
import {Preload} from '@/shared/ui/Preload'
import {BreadCrumb, BreadCrumbItem} from '@/shared/ui/BreadCumb'
import {Card} from '@/shared/ui/Card'
import {TableView} from '@/shared/ui/Tables'
import {CardTradePointModal} from '@/modals/TradePoints/CardTradePointModal'
import {TaxpayersStore} from '@/entities/Taxpayer'
import {TradePointStore} from '@/store/TradePointsStore'
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from '@tanstack/react-table'
import classNames from 'classnames'
import {observer} from 'mobx-react-lite'
import {getSnapshot} from 'mobx-state-tree'
import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {useLocation, useParams, useSearchParams} from 'react-router-dom'

interface ITradePoint {
    id: number;
    title: string;
    address: {
        fullAddress: string;
    };
    vpnStatus: number;
    channelId: number;
}

const vpn = [
    {
        id: 0,
        label: "Не подключен",
    },
    {
        id: 1,
        label: "Ожидание",
    },
    {
        id: 2,
        label: "Подключен",
    },
    {
        id: 3,
        label: "Подключение невозможно",
    }
]

const columnHelper = createColumnHelper<ITradePoint>()

export const TaxpayerTradePointsPage: FC = observer(() => {
        const location = useLocation()
        const {id} = useParams()
        const [sorting, setSorting] = useState<SortingState>([])
        const [selectedId, setSelectedId] = useState<null | number>(null)
        const [searchParams, setSearchParams] = useSearchParams()
        const page = searchParams.get('page')
        const size = searchParams.get('size')
        const fetchData = useCallback(() =>
                TradePointStore.fetchTradePointsByOrgId(id as string, {page, size}),
            [size, page, id])

        useEffect(() => {
            TaxpayersStore.fetchById(id as string)
        }, [id])

        useEffect(() => {
            fetchData()
        }, [size, page, fetchData])

        const onPaginate = (event: React.ChangeEvent<unknown>) => {
            setSearchParams(param => {
                param.set("page", param.toString())
                return param
            })
        }

        const onChangeSelectSize = (size: number) => {
            setSearchParams(param => {
                param.set("size", size.toString())
                return param
            })
        }

        useEffect(() => {
            TaxpayersStore.fetchById(id as string)
        }, [id])


        /*modals*/
        const [cardTradePointModal, setCardTradePointModal] = useState<boolean>(false)

        const openModal = (id: number) => {
            setSelectedId(id)
            setCardTradePointModal(true)
        }

        const columns = useMemo(() => {
            return [
                columnHelper.accessor('id', {
                    header: 'ID точки',
                    cell: ({getValue}) => <span className='table-link'
                                                onClick={() => openModal(getValue())}>{getValue()}</span>
                }),
                columnHelper.accessor('title', {
                    header: 'Название'
                }),
                columnHelper.accessor('address', {
                    header: 'Адрес точки',
                    cell: ({getValue}) => getValue().fullAddress
                }),
                columnHelper.accessor('vpnStatus', {
                    header: 'Статус VPN',
                    cell: ({getValue}) => vpn.find(item => item.id === getValue())?.label
                }),
                columnHelper.accessor('channelId', {
                    header: 'ID канала'
                })
            ]
        }, [])

        const tableInstance = useReactTable({
            data: getSnapshot(TradePointStore.retailPlaces),
            columns,
            state:{
              sorting,
            },
            onSortingChange: setSorting,
            getSortedRowModel: getSortedRowModel(),
            getCoreRowModel: getCoreRowModel()
        })
        return (
            <>
                <Card className={classNames('pt-30')}>
                    <div className='pl-27'>
                        <BreadCrumb className='mb-30'>
                            <BreadCrumbItem to='/taxpayers'>
                                Налогоплательщики
                            </BreadCrumbItem>
                            <BreadCrumbItem to={`/taxpayers/${id}`}>
                                {TaxpayersStore.currentTaxpayer?.title}
                            </BreadCrumbItem>
                            <BreadCrumbItem to={location.pathname} active>
                                Торговые точки
                            </BreadCrumbItem>
                        </BreadCrumb>
                        <PageTitle className='mb-30'>
                            Торговые точки
                        </PageTitle>
                    </div>
                    <TableView tableInstance={{...tableInstance}} className={"table-left"}/>
                </Card>
                {
                    cardTradePointModal && <CardTradePointModal id={selectedId} onClose={() => {
                        setCardTradePointModal(false)
                        setSelectedId(null)
                    }}/>
                }
                {
                    TradePointStore.retailPlaces.length ?
                        <Paginate
                            onChange={onPaginate}
                            currentPage={TradePointStore.currentPage}
                            countPages={TradePointStore.totalPages}
                            size={size}
                            onChangeSelectSize={onChangeSelectSize}
                        /> : ''
                }
                {
                    TradePointStore.loading && <Preload/>
                }
            </>


        )
    }
)
