import {Modal, ModalHeader} from '@/shared/ui/Modal'
import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {IModalProps} from '@/shared/types/ModalProps'
import classNames from 'classnames'
import {TableView} from '@/shared/ui/Tables'
import Styles from './ReportListModal.module.scss'
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from '@tanstack/react-table'
import Scrollbars from 'react-custom-scrollbars-2'
import {Button} from '@/shared/ui/Buttons'
import {getSnapshot} from "mobx-state-tree";
import {KkmStore} from "@/entities/Kkm";
import {formatDate} from "@/shared/lib";
import {observer} from "mobx-react-lite";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Paginate} from "@/shared/ui/Paginate";
import {formatDateUnix} from "@/shared/lib/formatDate";

interface IReport {
    shiftNumber: number;
    startDate: string;
}

const reportColumnHelper = createColumnHelper<IReport>()


export const ReportListModal: FC<IModalProps> = observer(() => {
        const [searchParams, setSearchParams] = useSearchParams()
        const page = searchParams.get('page')
        const size = searchParams.get('size')
        const {id} = useParams()
        const {pathname} = useLocation()
        const navigate = useNavigate()
        const [sorting, setSorting] = useState<SortingState>([])
        const onClose = () => {
            navigate(pathname.replace("/shiftList", ""))
        }

        const fetchData = useCallback(() =>
                KkmStore.fetchShiftListById(Number(id), {page, size})
            , [size, page, id])


        useEffect(() => {
            fetchData()
            if (Number(size) > 100) {
                setSearchParams({
                    size: "10",
                })
            }
        }, [size, page, fetchData, setSearchParams])

        const onPaginate = (event: React.ChangeEvent<unknown>, page: Number) => {
            setSearchParams({
                page: page.toString(),
            })
        }
        const onChangeSelectSize = (size: number) => {
            setSearchParams({
                size: size.toString()
            })
        }

        const columnsReportList = useMemo(() => [
            reportColumnHelper.accessor('startDate', {
                header: 'Дата отчета',
                cell: ({getValue}) => formatDate(getValue()),
                sortingFn: (a, b) => formatDateUnix(b.original.startDate) - formatDateUnix(a.original.startDate)
            }),
            reportColumnHelper.accessor('shiftNumber', {
                header: 'Номер смены'
            }),
        ], [])
        const reportListTable = useReactTable({
            data: getSnapshot(KkmStore.shiftList.list) as IReport[] || [],
            columns: columnsReportList,
            state: {
                sorting
            },
            onSortingChange: setSorting,
            getSortedRowModel: getSortedRowModel(),
            getCoreRowModel: getCoreRowModel()
        })
        return (
            <Modal onClose={onClose}>
                <ModalHeader onClose={onClose} className={Styles.header} title='Список отчетов по сменам'/>
                <div className={classNames("c_first_color fs-14 mb-20")}>
                    Всего отчетов: {KkmStore.shiftList.totalItems}
                </div>
                <Scrollbars style={{height: 410}}>
                    <div className="table-container">
                        <TableView
                            className={classNames('table-border-radius table-head-text-left', Styles.reportTable)}
                            tableInstance={{...reportListTable}}/>
                    </div>
                </Scrollbars>
                {
                    KkmStore.shiftList.list.length ?
                        <Paginate
                            onChange={onPaginate}
                            currentPage={KkmStore.shiftList.currentPage}
                            countPages={KkmStore.shiftList.totalPages}
                            size={size}
                            onChangeSelectSize={onChangeSelectSize}
                        /> : ''
                }
                <div className="flex j-end">
                    <Button onClick={onClose} color='cancel' className={classNames('mr-18 button', Styles.closeButton)}>
                        Закрыть
                    </Button>
                </div>

            </Modal>
        )
    }
)
