import {forwardRef} from 'react'
import Styles from './InputSearch.module.scss'
import classNames from 'classnames'
import {Input} from './Input';
import {InputProps} from '@/shared/types/InputPropsType';
import {CloseSearchIcon} from '@/assets/icons/CloseSearchIcon';
import {ButtonRefresh} from '@/shared/ui/Buttons';
import {SearchIcon} from '@/assets/icons/SearchIcon.';

interface Props extends InputProps {
    clearSearch?: () => void;
    refresh?: () => void;
    withRefresh?: boolean;
    type?: 'number' | 'text';
    children?: React.ReactNode
}

export const InputSearch = forwardRef<HTMLInputElement, Props>(({
                                                                    className,
                                                                    placeholder,
                                                                    onChange,
                                                                    value,
                                                                    refresh,
                                                                    type = 'text',
                                                                    clearSearch,
                                                                    withRefresh,
                                                                    children
                                                                }, ref) => {


    return (
        <>
            <div
                className={classNames('flex a-center', typeof (className) === 'string' ? className : className?.wrapper)}>
                <label className={classNames(Styles.searchLine)}>
                    <div className={classNames(Styles.iconContainer)}>
                        <SearchIcon/>
                    </div>
                    <Input
                        isSearch={true}
                        ref={ref}
                        type={type}
                        className={classNames(Styles.input, typeof (className) !== 'string' ? {input: className?.input} : '')}
                        placeholder={placeholder}
                        onChange={onChange}
                        value={value}
                    />
                    <button onClick={clearSearch} className={classNames(Styles.closeButton)}>
                        <CloseSearchIcon/>
                    </button>

                </label>
                {children}
                {
                    withRefresh &&
                    <ButtonRefresh onClick={refresh}/>
                }
            </div>
        </>
    )
})

