import { MenuItem } from '@mui/material';
import classNames from 'classnames';
import React, { FC } from 'react'
import Styles from './ContextMenu.module.scss'
interface IContextMenuProps {
    onClick: () => void;
    children: React.ReactNode;
}

export const ContextMenuItem: FC<IContextMenuProps> = ({ children, onClick }) => {
    return (
        <>
            <MenuItem
                className={classNames(Styles.item)}
                onClick={onClick}
                style={
                    {
                        paddingLeft: 12
                    }
                }
            >
                {children}
            </MenuItem>
        </>
    )
}
