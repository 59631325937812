import { Logo } from '@/shared/ui/Logo'
import { rootStore } from '@/store/rootStore'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Styles from './LoginLayout.module.scss'
export const LoginLayout: FC = observer(() => {

    const navigate = useNavigate()

    useEffect(() => {
        if (rootStore.token) {
            navigate('/')
        }
    }, [rootStore.token])


    return (
        <div className='wrapper flex column j-center'>
            <Logo show={true} className='pt-55 pl-55' />
            <div className={Styles.container}>
                <Outlet />
            </div>
        </div>
    )
})
