import React from 'react';
import {TariffsTable} from "@/entities/Tariff";
import {Card} from "@/shared/ui/Card";
import {CreateTariffButton, SearchTariffs} from "@/features/Tariffs";
import {PageTitle} from "@/shared/ui/PageTitle";
import cn from "classnames"

export const TariffsPage = () => {

    return (
        <>
            <Card className={cn("py-30 px-27 mb-12")}>
                <PageTitle className={cn("mb-30")}>
                    Тарифы
                </PageTitle>
                <div className={cn("flex")}>
                    <SearchTariffs/>
                    <CreateTariffButton/>
                </div>
            </Card>
            <TariffsTable/>
        </>
    );
};


