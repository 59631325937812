import {FC, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {Input, InputPassword} from '@/shared/ui/Inputs'
import {Modal, ModalHeader} from '@/shared/ui/Modal'
import {IModalProps} from '@/shared/types/ModalProps'
import {NewUserIcon} from '@/assets/icons/NewUserIcon'
import {Button} from '@/shared/ui/Buttons'
import {ModalSuccess} from '@/shared/ui/Modal'
import passfather from 'passfather'
import Styles from './Styles.module.scss'
import classNames from 'classnames'
import {toast} from 'react-toastify'
import axios from 'axios'
import {IErrorMessage} from '@/shared/api/instanceAxios'
import {ServiceAccountStore} from "@/store/ServiceAccountStore";
import {TypeModal} from "@/modals/ServiceAccount/typeModal";


interface ICardUserProps extends IModalProps {
    type?: null | TypeModal,
    onEdit: () => void;
    onFetch: () => void;
}

export const CardServiceAccountModal: FC<ICardUserProps> = ({onClose, type, id, onEdit, onFetch}) => {


    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: {
            errors,
        },
    } = useForm({
        mode: 'onBlur'
    })


    useEffect(() => {
        if (id) {
            setValue('name', ServiceAccountStore.serviceAccount(id).name)
            setValue('login', ServiceAccountStore.serviceAccount(id).login)
            setValue('phone', ServiceAccountStore.serviceAccount(id).phone)
            setValue('email', ServiceAccountStore.serviceAccount(id).email)
        } else {
            setValue('password', passfather({
                numbers: true,
                uppercase: true,
                lowercase: true,
                symbols: true,
                length: 8
            }))
        }
    }, [id])


    const onSubmit = async (data: any) => {
        try {
            const name = data.name.trim()
            const obj = {
                ...data,
                name,
            }
            if (!getValues('password')) delete obj.password
            if (id) {
                obj.id = id
                await ServiceAccountStore.updateServiceAccount(id, obj)
            } else {
                await ServiceAccountStore.addServiceAccount(obj)
            }
            onFetch()
            setSuccessAddServiceAccount(true)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const response = await e.response?.data as IErrorMessage
                toast.error(response?.details?.join(" "), {
                    position: 'bottom-right'
                })
            }
        }
    }

    /***Modals***/
    const [successAddServiceAccount, setSuccessAddServiceAccount] = useState(false)

    return (
        <>
            {
                successAddServiceAccount ?
                    <ModalSuccess className='pt-8 pb-28 c_first_color' onClose={onClose}>
                        {id ? "Изменения сохранены!" : "Служебный аккаунт успешно создан!"}
                    </ModalSuccess>
                    :
                    <Modal className={classNames(Styles.modal)} onClose={onClose}>
                        <ModalHeader title={type as string} onClose={onClose}>
                            <NewUserIcon/>
                        </ModalHeader>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="modal-form">
                                <label className='label'>
                                    <span>ФИО {type !== TypeModal.VIEW && <span className='required'>*</span>}</span>
                                    <Input
                                        className='--fluid'
                                        {
                                            ...register('name', {
                                                required: {
                                                    value: true,
                                                    message: 'Это поле обязательно для заполнения'
                                                },
                                            })
                                        }
                                        disabled={type === TypeModal.VIEW}
                                        error={errors.fullName?.message as string}
                                        placeholder='Фамилия Имя Отчество пользователя'/>
                                </label>
                                <div className="flex">
                                    <label
                                        className={classNames("label mr-12", type !== TypeModal.VIEW ? "column" : "grow")}>
                                        <span>Логин {(type === TypeModal.CREATE) &&
                                            <span className='required'>*</span>}</span>
                                        <Input
                                            {
                                                ...register('login', {
                                                    required: {
                                                        value: true,
                                                        message: 'Это поле обязательно для заполнения'
                                                    }
                                                })
                                            }
                                            disabled={type === TypeModal.VIEW || type === TypeModal.EDIT}
                                            color={type === TypeModal.VIEW || type === TypeModal.EDIT ? 'disabled' : undefined}
                                            error={errors.login?.message as string}
                                            className='--fluid'
                                            type='text'
                                            placeholder='введите логин'/>
                                    </label>
                                    {
                                        type !== TypeModal.VIEW && <label className='label column'>
                                        <span>Пароль {type === TypeModal.CREATE &&
                                            <span className='required'>*</span>}</span>
                                            <InputPassword
                                                {
                                                    ...register('password', {
                                                        required: {
                                                            value: type === TypeModal.CREATE,
                                                            message: 'Это поле обязательно для заполнения'
                                                        },
                                                        pattern: {
                                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s]).{6,}/,
                                                            message: 'Пароль должен состоять хотя бы из 6 символов и содержать минимум одну цифру, одну заглавную и одну строчную буквы(англ.), один спец символ'
                                                        }
                                                    })
                                                }
                                                visible={true}
                                                error={errors.password?.message as string}
                                                placeholder='Придумайте надежный пароль'/>
                                        </label>
                                    }

                                </div>
                                <div className="flex">
                                    <label className='label column mr-12'>
                                        <span>Адрес e-mail {(type === TypeModal.CREATE) &&
                                            <span className='required'>*</span>}</span>
                                        <Input
                                            {
                                                ...register('email', {
                                                    required: {
                                                        value: true,
                                                        message: 'Это поле обязательно для заполнения'
                                                    },
                                                    pattern: {
                                                        value: /^[_a-z0-9-\+-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i,
                                                        message: 'Введите корректный адрес электронной почты'
                                                    },
                                                })
                                            }
                                            disabled={type === TypeModal.VIEW || type === TypeModal.EDIT}
                                            color={type === TypeModal.VIEW || type === TypeModal.EDIT ? 'disabled' : undefined}
                                            error={errors.email?.message as string}
                                            className='--fluid'
                                            type='email'
                                            placeholder='example@mail.com'/>
                                    </label>

                                    <label className='label column mr-12'>
                                        <span>Номер телефона {type !== TypeModal.VIEW &&
                                            <span className='required'>*</span>}</span>
                                        <Input
                                            disabled={type === TypeModal.VIEW}
                                            masked
                                            mask="+9 (999) 999-99-99"
                                            maskPlaceholder=""
                                            placeholder='+7 (---) --- -- --'
                                            {
                                                ...register('phone', {
                                                    required: {
                                                        value: true,
                                                        message: 'Это поле обязательно для заполнения'
                                                    },
                                                    minLength: {
                                                        value: 18,
                                                        message: 'Введите корректный номер телефона'
                                                    }
                                                })
                                            }
                                            error={errors.phone?.message as string}
                                        />

                                    </label>
                                </div>


                                <div className="flex j-end">
                                    <Button color='cancel' className='mr-18 button' onClick={onClose}>
                                        Отмена
                                    </Button>
                                    {
                                        (type === TypeModal.EDIT || type === TypeModal.CREATE) &&
                                        <Button className='button' type='submit'>
                                            {
                                                type === TypeModal.EDIT ? 'Сохранить' : 'Добавить'

                                            }
                                        </Button>
                                    }
                                    {
                                        type === TypeModal.VIEW &&
                                        <Button onClick={onEdit} className='button' type='button'>
                                            Редактировать
                                        </Button>
                                    }

                                </div>

                            </div>
                        </form>
                    </Modal>
            }
        </>

    )
}
