import theme from '@/shared/themes'
import React from 'react'

export const SearchIcon = (props: { color?: string }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.625 16.3125C4.3875 16.3125 0.9375 12.8625 0.9375 8.625C0.9375 4.3875 4.3875 0.9375 8.625 0.9375C12.8625 0.9375 16.3125 4.3875 16.3125 8.625C16.3125 12.8625 12.8625 16.3125 8.625 16.3125ZM8.625 2.0625C5.0025 2.0625 2.0625 5.01 2.0625 8.625C2.0625 12.24 5.0025 15.1875 8.625 15.1875C12.2475 15.1875 15.1875 12.24 15.1875 8.625C15.1875 5.01 12.2475 2.0625 8.625 2.0625Z" fill={props.color ? props.color : theme.secondColor} />
            <path d="M16.5 17.0626C16.3575 17.0626 16.215 17.0101 16.1025 16.8976L14.6025 15.3976C14.385 15.1801 14.385 14.8201 14.6025 14.6026C14.82 14.3851 15.18 14.3851 15.3975 14.6026L16.8975 16.1026C17.115 16.3201 17.115 16.6801 16.8975 16.8976C16.785 17.0101 16.6425 17.0626 16.5 17.0626Z" fill={props.color ? props.color : theme.secondColor} />
        </svg>

    )
}

