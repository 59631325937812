import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useLocation, useParams, useSearchParams} from 'react-router-dom'
import {PageTitle} from '@/shared/ui/PageTitle'
import {BreadCrumb, BreadCrumbItem} from '@/shared/ui/BreadCumb'
import {TableView} from '@/shared/ui/Tables/TableView'
import {Card} from '@/shared/ui/Card/Card'
import {Input} from '@/shared/ui/Inputs'
import Styles from './TaxpayerHistoryPage.module.scss'
import classNames from 'classnames'
import {Button} from '@/shared/ui/Buttons'
import {InputExclamation} from '@/shared/ui/Inputs/InputExclamation'
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from '@tanstack/react-table'
import {observer} from 'mobx-react-lite'
import {historyTaxpayerItem, TaxpayersHistoryStore} from '@/store/TaxpayerHistoryStore'
import {getSnapshot} from 'mobx-state-tree'
import {TaxpayersStore} from '@/entities/Taxpayer/model/TaxpayesStore'
import {Paginate} from '@/shared/ui/Paginate'
import {Preload} from '@/shared/ui/Preload'
import {formatDate} from "@/shared/lib";
import {formatDateUnix} from "@/shared/lib/formatDate";
import {ISaveChangesData} from "@/shared/api/taxpayersServices";
import {useForm} from "react-hook-form";
import { toast } from 'react-toastify';
import axios from 'axios';
import { IErrorMessage } from '@/shared/api/instanceAxios';


const columnHelper = createColumnHelper<historyTaxpayerItem>()

export const TaxpayerHistoryPage = observer(() => {
        const location = useLocation()
        const {id} = useParams()
        const [searchParams, setSearchParams] = useSearchParams()
        const page = searchParams.get('page')
        const size = searchParams.get('size')
        const [sorting, setSorting] = useState<SortingState>([])
        // const nameOrgfRef = useRef<HTMLInputElement | null>(null)
        // const billingAccountNumberRef = useRef<HTMLInputElement | null>(null)
        // const namePersonRef = useRef<HTMLInputElement | null>(null)
        // const secondNamePersonRef = useRef<HTMLInputElement | null>(null)
        // const lastNamePersonRef = useRef<HTMLInputElement | null>(null)
        const [reason, setReason] = useState("")
        const {
            register, handleSubmit, setValue, formState: {
                errors
            }
        } = useForm()
        const onPaginate = (event: React.ChangeEvent<unknown>, page: Number) => {
            setSearchParams({
                page: page.toString(),
            })
        }

        const onChangeSelectSize = (size: number) => {
            setSearchParams({
                size: size.toString()
            })
        }

        const taxpayer = TaxpayersStore.currentTaxpayer
        const fetchData = useCallback(() => {
            TaxpayersHistoryStore.fetchById({orgId: id, page, size})
        }, [page, size, id])
        const saveChanges = async (values: ISaveChangesData) => {

          try {
            let data: ISaveChangesData = {
              changeReason: reason.trim(),
              billingAccountNumber: values.billingAccountNumber?.trim(),
              legalEntityType: taxpayer.legalEntityType,
            };
            if (taxpayer.legalEntityType) {
              data = {
                ...data, firstName: values.firstName?.trim(),
                lastName: values.lastName?.trim(),
                patronymic: values.patronymic?.trim(),
              };
            } else {
              data.orgName = values.orgName?.trim();
            }
            await TaxpayersHistoryStore.saveChanges(Number(id), data);
            await fetchData();
            setReason('');
            await TaxpayersStore.fetchById(id as string);
            toast.success('Изменения сохранены!', {
              position: 'bottom-right',
            });
          } catch (e) {
              if (axios.isAxiosError(e)) {
                const response = await e.response?.data as IErrorMessage;
                toast.error(response?.details?.join(' ') || response.message, {
                  position: 'bottom-right',
                });
              }
          }

        }
        useEffect(() => {
            TaxpayersStore.fetchById(id as string)
        }, [id])

        useEffect(() => {
            if (taxpayer.title) {
                setValue("billingAccountNumber", taxpayer.billingAccountNumber)
                if (!taxpayer.legalEntityType) {
                    setValue("orgName", taxpayer.title)
                } else {
                    const fio = taxpayer.title.split(" ")
                    setValue("firstName", fio[2])
                    setValue("lastName", fio[1])
                    setValue("patronymic", fio[3])
                }
            }
        }, [taxpayer])

        useEffect(() => {
            fetchData()
        }, [fetchData])

        const columns = useMemo(() => [
            columnHelper.accessor('fieldName', {
                header: 'Поле'
            }),
            columnHelper.accessor('fieldValue', {
                header: 'Значение'
            }),
            columnHelper.accessor('updatedAt', {
                header: 'Дата изменений',
                cell: ({getValue}) => formatDate(getValue()),
                sortingFn: (a, b) => formatDateUnix(b.original.updatedAt) - formatDateUnix(a.original.updatedAt)
            })

        ], [])

        const tableInstance = useReactTable({
            columns,
            state: {
                sorting
            },
            onSortingChange: setSorting,
            getSortedRowModel: getSortedRowModel(),
            data: getSnapshot(TaxpayersHistoryStore.organisationFieldsHistoryList),
            getCoreRowModel: getCoreRowModel()
        })

        return (
            <>
                <Card className={classNames('pt-30 pb-26 pr-47 mb-12')}>
                    <div className='pl-27'>
                        <BreadCrumb className='mb-30'>
                            <BreadCrumbItem to='/taxpayers'>
                                Налогоплательщики
                            </BreadCrumbItem>
                            <BreadCrumbItem to={`/taxpayers/${taxpayer?.id}`}>
                                {taxpayer?.title}
                            </BreadCrumbItem>
                            <BreadCrumbItem to={location.pathname} active>
                                История изменений
                            </BreadCrumbItem>
                        </BreadCrumb>
                        <PageTitle className='mb-30'>
                            {
                                `Редактирование данных ${taxpayer.legalEntityType === 0 ? 'юридического' : (taxpayer.legalEntityType === 1 ? 'физического' : '')} лица`
                            }
                        </PageTitle>
                        <div className={classNames(Styles.form)}>
                            {taxpayer.legalEntityType !== undefined && (taxpayer.legalEntityType === 0 ?
                                <>
                                    <div className={classNames(Styles.formItem, Styles.formItemOranization)}>
                                        <div className="form-field c_first_color">
                                            Наименование организации
                                        </div>
                                        <div className="form-input">
                                            <Input className='grow' {...register("orgName", {
                                                required: {
                                                    value: true,
                                                    message: 'Заполните это поле',
                                                }
                                            })} error={errors.orgName?.message as string}/>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className={classNames(Styles.formItem)}>
                                        <div className="form-field c_first_color">
                                            Фамилия
                                        </div>
                                        <div className="form-input">
                                            <Input {...register("lastName")}/>
                                        </div>
                                    </div>
                                    <div className={classNames(Styles.formItem)}>
                                        <div className="form-field c_first_color">
                                            Имя
                                        </div>
                                        <div className="form-input">
                                            <Input {...register("firstName", {
                                                required: {
                                                    value: true,
                                                    message: 'Заполните это поле',
                                                }
                                            })} error={errors.firstName?.message as string}/>
                                        </div>
                                    </div>
                                    <div className={classNames(Styles.formItem)}>
                                        <div className="form-field c_first_color">
                                            Отчество
                                        </div>
                                        <div className="form-input">
                                            <Input {...register("patronymic")}/>
                                        </div>
                                    </div>
                                </>)
                            }

                            <div className={classNames(Styles.formItem)}>
                                <div className="form-field c_first_color">
                                    Биллинг-аккаунт
                                </div>
                                <div className="form-input c_first_color">
                                    <Input {...register("billingAccountNumber", {
                                        required: {
                                            value: true,
                                            message: 'Заполните это поле',
                                        }
                                    })} error={errors.billingAccountNumber?.message as string}/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="form-field mb-10 c_first_color">
                                Причина изменений
                            </div>
                            <div className="form-input flex j-between">
                                <InputExclamation className='mr-30' value={reason}
                                                  onChange={e => setReason(e.target.value)}/>
                                <Button disabled={!reason} onClick={handleSubmit(saveChanges)}
                                        className={classNames(Styles.saveButton, 'mr-70')}>
                                    Сохранить изменения
                                </Button>
                            </div>
                        </div>
                    </div>

                </Card>
                <Card>
                    <PageTitle className='py-30 pl-27'>
                        История изменений
                    </PageTitle>
                    <TableView className={classNames('table-left')} tableInstance={{...tableInstance}}/>
                </Card>
                {
                    TaxpayersHistoryStore.organisationFieldsHistoryList.length ?
                        <Paginate
                            onChange={onPaginate}
                            currentPage={TaxpayersHistoryStore.currentPage}
                            countPages={TaxpayersHistoryStore.totalPages}
                            size={size}
                            onChangeSelectSize={onChangeSelectSize}
                        /> : ''
                }
                {
                    TaxpayersHistoryStore.loading && <Preload/>
                }
            </>

        )
    }
)
