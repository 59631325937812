import React, {useState} from 'react';
import classNames from "classnames";
import {Input} from "@/shared/ui/Inputs";
import {Button} from "@/shared/ui/Buttons";
import {showToast} from "@/shared/lib";
import {ServiceAccountStore} from "@/store/ServiceAccountStore";
import {ConfirmModal} from "@/modals/General";
import {useParams} from "react-router-dom";
import Styles from '../styles/style.module.scss'
const BindServiceAccount = () => {
    const [login, setLogin] = useState("")
    const [confirmBindModal, setConfirmBindModal] = useState(false)
    const {id} = useParams()
    const bind = async () => {
        setConfirmBindModal(false)
        await showToast(async () => {
            return await ServiceAccountStore.bindToOrg(login.trim(), Number(id))
        }, "Успешно! Аккаунт привязан")
    }

    return (
        <div className={classNames("item grow")}>
            <div className='mb-15'>
                Служебный аккаунт:
            </div>
            <Input className='mb-15' value={login} onChange={e => setLogin(e.target.value)}/>
            <Button disabled={!login} className={classNames(Styles.button)}
                    onClick={() => setConfirmBindModal(true)}>
                Привязать
            </Button>
            {
                confirmBindModal &&
                <ConfirmModal onAccept={bind} acceptButtonText="Ок" onReject={() => setConfirmBindModal(false)}
                              message="Служебный аккаунт позволит авторизоваться с правамиадминистратора этого налогоплательщика. Подтвердите"/>
            }
        </div>
    );
};

export default BindServiceAccount;
