import { FC } from 'react'
import { IconProp } from './iconType'
export const TradePointsIcon: FC<IconProp> = ({ color }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.46991 10.75C4.75991 10.75 4.03991 10.49 3.48991 9.96C1.64991 8.21 0.909911 6.3 1.33991 4.43C1.84991 2.23 3.77991 1.25 5.46991 1.25C7.15991 1.25 9.09991 2.24 9.59991 4.43C10.0299 6.3 9.27991 8.21 7.44991 9.96C6.89991 10.49 6.18991 10.75 5.46991 10.75ZM2.79991 4.77C2.41991 6.44 3.52991 7.92 4.52991 8.87C5.05991 9.38 5.88991 9.37 6.41991 8.87C7.41991 7.92 8.52991 6.44 8.13991 4.76C7.79991 3.28 6.48991 2.75 5.47991 2.75C4.45991 2.75 3.14991 3.28 2.79991 4.77Z" fill={color} />
            <path d="M5.51001 6.19995C4.96001 6.19995 4.51001 5.74995 4.51001 5.19995C4.51001 4.64995 4.95001 4.19995 5.51001 4.19995H5.52001C6.07001 4.19995 6.52001 4.64995 6.52001 5.19995C6.52001 5.74995 6.07001 6.19995 5.51001 6.19995Z" fill={color} />
            <path d="M18.4901 22.75C17.7801 22.75 17.0601 22.49 16.5001 21.96C14.6501 20.21 13.9001 18.3 14.3401 16.42C14.8501 14.23 16.7901 13.24 18.4901 13.24C20.1901 13.24 22.1301 14.23 22.6401 16.42C23.0701 18.29 22.3201 20.21 20.4701 21.95C19.9201 22.49 19.2101 22.75 18.4901 22.75ZM18.4901 14.75C17.4701 14.75 16.1501 15.28 15.8001 16.77C15.4101 18.44 16.5301 19.93 17.5301 20.88C18.0601 21.39 18.9001 21.39 19.4401 20.88C20.4401 19.93 21.5601 18.45 21.1701 16.78C20.8401 15.28 19.5201 14.75 18.4901 14.75Z" fill={color} />
            <path d="M18.53 18.2C17.98 18.2 17.53 17.75 17.53 17.2C17.53 16.65 17.97 16.2 18.53 16.2H18.54C19.09 16.2 19.54 16.65 19.54 17.2C19.54 17.75 19.08 18.2 18.53 18.2Z" fill={color} />
            <path d="M11.9999 19.75H9.31994C8.15994 19.75 7.14994 19.05 6.74994 17.97C6.33994 16.89 6.63994 15.7 7.50994 14.93L15.4999 7.94C15.9799 7.52 15.9899 6.95 15.8499 6.56C15.6999 6.17 15.3199 5.75 14.6799 5.75H11.9999C11.5899 5.75 11.2499 5.41 11.2499 5C11.2499 4.59 11.5899 4.25 11.9999 4.25H14.6799C15.8399 4.25 16.8499 4.95 17.2499 6.03C17.6599 7.11 17.3599 8.3 16.4899 9.07L8.49994 16.06C8.01994 16.48 8.00994 17.05 8.14994 17.44C8.29994 17.83 8.67994 18.25 9.31994 18.25H11.9999C12.4099 18.25 12.7499 18.59 12.7499 19C12.7499 19.41 12.4099 19.75 11.9999 19.75Z" fill={color} />
            <path d="M11.9999 19.75H9.31994C8.15994 19.75 7.14994 19.05 6.74994 17.97C6.33994 16.89 6.63994 15.7 7.50994 14.93L15.4999 7.94C15.9799 7.52 15.9899 6.95 15.8499 6.56C15.6999 6.17 15.3199 5.75 14.6799 5.75H11.9999C11.5899 5.75 11.2499 5.41 11.2499 5C11.2499 4.59 11.5899 4.25 11.9999 4.25H14.6799C15.8399 4.25 16.8499 4.95 17.2499 6.03C17.6599 7.11 17.3599 8.3 16.4899 9.07L8.49994 16.06C8.01994 16.48 8.00994 17.05 8.14994 17.44C8.29994 17.83 8.67994 18.25 9.31994 18.25H11.9999C12.4099 18.25 12.7499 18.59 12.7499 19C12.7499 19.41 12.4099 19.75 11.9999 19.75Z" fill={color} />
        </svg>

    )
}

