import React from 'react';
import {Button} from "@/shared/ui/Buttons";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";
import s from './style.module.scss'
const CreateTariffButton = () => {
    const navigate = useNavigate()
    return (
        <Button onClick={() => navigate("/tariffs/form")} className={classNames('button', s.button)}>
            <div className="flex j-center a-center">
                <span className='mr-15 fs-20 plus'>+</span>
                <span>Создать тариф</span>
            </div>
        </Button>
    );
};

export default CreateTariffButton;
