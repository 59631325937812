export const registrationStatus = [
    'Сохранен',
    'Надо зарегистрировать',
    'Отправлен на регистрацию в КГД',
    'ошибка при регистрации (ответ КГД)',
    'ККМ зарегистрирован',
    'Надо снять с регистрации',
    'Запрос на снятие с регистрации отправлен в КГД',
    'Ошибка при снятии с регистрации (ответ КГД)',
    'ККМ снят с регистрации'
]

export const typeDevices = [
    'Стационарное устройство',
    'Мобильное устройство',
    'ККТ входит в состав платежного терминала (банкомата)'
]

export const billingStatus = [
    {
        id: -1,
        label: "(null) - Касса не зарегистрирована в биллинге",
        checked: true,
    },
    {
        id: 0,
        label: "Тариф подключен (0) REGISTERED",
        checked: false,
    },
    {
        id: 1,
        label: "Тариф активен (1) ACTIVATED",
        checked: false,
    },
    {
        id: 3,
        label: "Тариф отключен (3) DEREGISTERED",
        checked: false,
    },
    {
        id: 4,
        label: "Отключена за неуплату (4) BLOCKED",
        checked: false,
    },

]
