import {Pagination, PaginationItem} from '@mui/material';
import classNames from 'classnames';
import React, {FC} from 'react'
import {PaginateSelect} from '../PaginateSelect';


interface IProps {
    onChange: (event: React.ChangeEvent<unknown>, page: Number) => void;
    currentPage: number | undefined;
    countPages: number | undefined;
    size: string | null;
    onChangeSelectSize: (size: number) => void;
}


export const Paginate: FC<IProps> = ({onChange, currentPage, countPages, size, onChangeSelectSize}) => {
    return (

        <>
            {
                <div className="flex j-end mt-23">
                    <div
                        className={classNames("flex w-50 a-center", (typeof countPages === 'number' && countPages > 1) ? 'j-between' : 'j-end')}>
                        {
                            (typeof countPages === 'number' && countPages > 1) &&
                            <Pagination
                                className='paginate'
                                classes={{}}
                                onChange={onChange}
                                page={currentPage}
                                count={countPages}
                                renderItem={item => <PaginationItem classes={{
                                    selected: 'paginateSelected',
                                }} {...item} />}
                                shape="rounded"/>
                        }

                        <PaginateSelect value={Number(size) || 10} onChange={(size) => onChangeSelectSize(size)}/>
                    </div>
                </div>

            }

        </>

    )
}
