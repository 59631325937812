import {api} from '@/shared/api/api'
import {flow, types} from 'mobx-state-tree'


const RolesModel = types.model('RoleMode', {
    loading: types.boolean,
    totalItems: types.number,
    itemsPerPage: types.number,
    totalPages: types.number,
    currentPage: types.number,
    currentRole: types.frozen(),
    roles: types.array(types.frozen()),
    rolesForSelect: types.array(types.frozen()),
}).actions(self => ({
    createRole: flow(function* (data: any) {
        try {
            self.loading = true
            yield api.createRole(data)
        } catch (e) {
            throw e
        } finally {
            self.loading = false
        }

    }),
    editRole: flow(function* (id: number, data: any) {
        try {
            self.loading = true
            yield api.editRole(id, data)
        } catch (e) {
            throw e
        } finally {
            self.loading = false
        }

    }),
    deleteRole: flow(function* (id: number) {
        try {
            self.loading = true
            yield api.deleteRole(id)
        } catch (e) {
            throw e
        } finally {
            self.loading = false
        }

    }),
    fetchAll: flow(function* (data?, params?) {
        self.loading = true
        try {
            const response = yield api.getRolesList(data, params)
            self.totalItems = response.totalItems
            self.itemsPerPage = response.itemsPerPage
            self.currentPage = response.currentPage
            self.totalPages = response.totalPages
            self.roles = response.roles
        } catch (e) {
            throw e
        } finally {
            self.loading = false
        }

    }),
    fetchListForSelect: flow(function* (data?) {
        self.loading = true
        try {
            const response = yield api.getRolesList(data, {
                page: 1,
                size: 1000
            })
            self.rolesForSelect = response.roles
        } catch (e) {
            throw e
        } finally {
            self.loading = false
        }
    }),
    fetchById: flow(function* (id: number | string) {
        try {
            self.loading = true
            const result = yield api.getRoleById(+id)
            self.currentRole = result
        } catch (e) {
            throw e
        } finally {
            self.loading = false
        }

    }),
})).views(self => ({
    role(id: number) {
        return self.roles.find(item => item.id === id)
    }
}))

export const RolesStore = RolesModel.create({
    loading: false,
    roles: [],
    currentPage: 1,
    itemsPerPage: 15,
    totalItems: 0,
    totalPages: 0,
    currentRole: {}
})


