import React, { forwardRef} from 'react'
import classNames from 'classnames';
import {InputProps} from '@/shared/types/InputPropsType';
import Styles from './Checkbox.module.scss'

interface CheckboxProps extends InputProps {
    checked?: boolean;
    id?: string;
    className?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    defaultChecked?: boolean;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({
                                                                    checked,
                                                                    onChange,
                                                                    className,
                                                                    name,
                                                                    defaultChecked
                                                                }, ref) => {
    return (
        <>
            <label className={classNames(Styles.wrapper, className)}>
                <input name={name} type="checkbox" checked={checked} className={classNames(Styles.input)} ref={ref}
                       onChange={onChange}/>
                <span className={classNames(Styles.square)}></span>
            </label>
        </>

    )
})
