import React, {FC, useMemo, useState} from 'react';
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from "@tanstack/react-table";
import {ITaxpayer} from "@/shared/types/GlobalTypes";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {TableView} from "@/shared/ui/Tables";
import {Card} from "@/shared/ui/Card";
import {observer} from "mobx-react-lite";


const columnHelper = createColumnHelper<ITaxpayer>()

interface Props {
    data: ITaxpayer[]
}

const TaxpayersTable: FC<Props> = observer(({data}) => {


        const [sorting, setSorting] = useState<SortingState>([])
        const columns = useMemo(() =>
                [
                    columnHelper.accessor('iinBin', {
                        header: 'ИИН/БИН',
                        cell: ({getValue, row}) => {
                            return <Link className={classNames('table-link')}
                                         to={`/taxpayers/${row.original.id}`}>{getValue()}</Link>
                        }
                    }),
                    columnHelper.accessor('title', {
                        header: 'Наименование организации',
                    }),
                    columnHelper.accessor('billingAccountNumber', {
                        header: 'Номер лицевого счета',
                        cell: ({getValue}) => Number(getValue()) || ""
                    }),
                    columnHelper.accessor('phone', {
                        header: 'Телефон'
                    }),
                    columnHelper.accessor('responsiblePersonEmail', {
                        header: 'E-mail',
                    }),
                ]
            , [])
        const tableInstance = useReactTable({
            columns,
            data,
            state: {
                sorting,
            },
            onSortingChange: setSorting,
            getCoreRowModel: getCoreRowModel(),
            getSortedRowModel: getSortedRowModel(),
        })


        return (
            <Card>
                <TableView tableInstance={{...tableInstance}}
                           className={classNames('table-border-radius', 'table-left')}/>
            </Card>
        );
    }
)
export default TaxpayersTable;
