import {FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {LoginForm} from './LoginForm'
import {Button} from '@/shared/ui/Buttons'
import {InputPassword} from '@/shared/ui/Inputs'
import classNames from 'classnames'
import Styles from './Auth.module.scss'
import {useForm} from 'react-hook-form'
import {rootStore} from '@/store/rootStore'
import {Preload} from '@/shared/ui/Preload'
import axios from 'axios'
import {IErrorMessage} from '@/shared/api/instanceAxios'
import {toast, ToastContainer} from 'react-toastify'

export const NewPass: FC = () => {

    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        getValues,
        watch,
        formState: {errors}
    } = useForm({
        mode: 'onBlur'
    })

    const [showPreload, setShowPreload] = useState(false)

    useEffect(() => {
        if (!rootStore.recoveryPassword.code) {
            navigate('/')
        }
    }, [rootStore.recoveryPassword.code])

    const [disableButton, setDisableButton] = useState(true)

    useEffect(() => {
        if (getValues('passwod') !== getValues('secondPassword')) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
    }, [getValues('password'), getValues('secondPassword')])

    const sendNewPassword = async ({password}: any) => {
        setShowPreload(true)
        try {
            const response = await rootStore.recoveryPassword.sendNewPassword(password)
            if (response) {
                navigate('/login')
            }
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const response = await e.response?.data as IErrorMessage
                toast.error(response?.details?.join(" "), {
                    position: 'bottom-right'
                })
            }
        } finally {
            setShowPreload(false)
        }
    }

    return (
        <LoginForm
            title='Восстановление пароля'
            subtitle='Проверка прошла успешно!Введите новый пароль'
            className='pt-61 px-50 pb-74'
        >
            <form onSubmit={handleSubmit(sendNewPassword)}>
                <label className={classNames(Styles.label, "mt-34 mb-15")}>
                    <span className={classNames(Styles.captionInput)}>Новый пароль</span>
                    <InputPassword
                        {
                            ...register('password', {
                                required: {
                                    value: true,
                                    message: 'Это поле обязательно для заполнения'
                                },
                                pattern: {
                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s]).{6,}/,
                                    message: 'Пароль должен состоять хотя бы из 6 символов и содержать минимум одну цифру, одну заглавную и одну строчную буквы(англ.), один спец символ'
                                }
                            })
                        }
                        error={errors.password?.message as string}
                        placeholder="Введите новый пароль"/>
                </label>
                <label className={classNames(Styles.label, "mb-21")}>
                    <span className={classNames(Styles.captionInput)}>Повторите новый пароль</span>
                    <InputPassword
                        {
                            ...register('passwordSecond', {
                                required: {
                                    value: true,
                                    message: 'Это поле обязательно для заполнения'
                                },
                                validate: (val: string) => {
                                    if (watch('password') !== val) {
                                        return "Пароль не совпадает! Необходимо совпадения данных в обоих полях";
                                    }
                                }
                            })
                        }
                        error={errors.passwordSecond?.message as string}
                        placeholder="Введите новый пароль повторно"/>
                </label>
                <div className="flex" style={{columnGap: 18}}>
                    <Button color='cancel' onClick={() => navigate('/login')}>
                        Отмена
                    </Button>
                    <Button type='submit' disabled={disableButton}>
                        Отправить
                    </Button>
                </div>
            </form>
            {
                showPreload && <Preload/>
            }
            <ToastContainer/>

        </LoginForm>
    )
}
