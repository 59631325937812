import {Menu} from '@mui/material'
import classNames from 'classnames';
import React, {FC} from 'react'
import {DotsMore} from '../DotsMore'
import Styles from './ContextMenu.module.scss'

interface IContextMenuProps {
    children: React.ReactNode;
}

export const ContextMenu: FC<IContextMenuProps> = ({children}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const show = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (anchorEl) {
            setAnchorEl(null)
            return
        }
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div className={classNames(Styles.menu)} onClick={handleClick}>
            <button>
                <DotsMore/>
            </button>
            <Menu
                anchorEl={anchorEl}
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }} open={show}
                onClose={handleClose}
            >
                <div className="py-2">
                    {children}
                </div>
            </Menu>
        </div>
    )
}
