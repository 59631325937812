import theme from '@/shared/themes'
import { FC } from 'react'

export const ShowHideIcon: FC = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_479_670)">
                <circle cx="30" cy="30" r="14.5" fill="white" stroke={theme.thirdColor} />
                <path d="M32 34L27 29.5L32 25" stroke={theme.firstColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <filter id="filter0_d_479_670" x="0" y="0" width="60" height="60" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="7.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_479_670" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_479_670" result="shape" />
                </filter>
            </defs>
        </svg>

    )
}


