import React from 'react'

export const CloseUploadIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0003 1.6665C5.40866 1.6665 1.66699 5.40817 1.66699 9.99984C1.66699 14.5915 5.40866 18.3332 10.0003 18.3332C14.592 18.3332 18.3337 14.5915 18.3337 9.99984C18.3337 5.40817 14.592 1.6665 10.0003 1.6665ZM12.8003 11.9165C13.042 12.1582 13.042 12.5582 12.8003 12.7998C12.6753 12.9248 12.517 12.9832 12.3587 12.9832C12.2003 12.9832 12.042 12.9248 11.917 12.7998L10.0003 10.8832L8.08366 12.7998C7.95866 12.9248 7.80033 12.9832 7.64199 12.9832C7.48366 12.9832 7.32533 12.9248 7.20033 12.7998C6.95866 12.5582 6.95866 12.1582 7.20033 11.9165L9.11699 9.99984L7.20033 8.08317C6.95866 7.8415 6.95866 7.4415 7.20033 7.19984C7.44199 6.95817 7.84199 6.95817 8.08366 7.19984L10.0003 9.1165L11.917 7.19984C12.1587 6.95817 12.5587 6.95817 12.8003 7.19984C13.042 7.4415 13.042 7.8415 12.8003 8.08317L10.8837 9.99984L12.8003 11.9165Z" fill="#A5A5A5" />
        </svg>

    )
}



