import React, {FC} from 'react';
import {Modal} from "@/shared/ui/Modal";
import Styles from "@/modals/BusinessProcesses/ReplaceFileModal.module.scss";
import {Button} from "@/shared/ui/Buttons";


interface Props {
    onAccept: () => void;
    acceptButtonText: string;
    onReject: () => void;
    message: string;
}

const ConfirmModal: FC<Props> = ({onAccept, onReject, message, acceptButtonText}) => {
    return (
        <Modal className={Styles.modal} onClose={onReject}>
            <div className='flex fs-18 fw-500 c_first_color mb-40'>
                {message}
            </div>
            <div className="flex">
                <Button onClick={onReject} color='cancel' className='mr-18'>
                    Отмена
                </Button>
                <Button onClick={onAccept}>
                    {acceptButtonText}
                </Button>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
