import { IPage } from "@/shared/types/GlobalTypes";
import { instance } from "./instanceAxios";

export interface ISaveChangesData {
  orgName?: string;
  firstName?: string;
  lastName?: string;
  patronymic?: string;
  billingAccountNumber?: string;
  changeReason?: string;
  legalEntityType?: number;
}

interface TaxpayersListParams extends IPage {
  name?: string;
  iinBin?: string;
  billingAccountNumber?: string;
}

export const taxpayersServices = {
  getTaxpayersList: async (data: any, params: TaxpayersListParams) => {
    const response = await instance.post("/taxpayer/list", {
      ...data,
    }, {
      params,
    });
    return response.data;
  },
  getTaxpayerById: async (id: number | string) => {
    const response = await instance.get(`/taxpayer/${id}`);
    return response.data;
  },
  deleteTaxpayer: async (id: number) => {
    const response = await instance.delete(`/taxpayer/${id}`);
    return response.data;
  },
  getTaxpayerByIdKkm: async (kkmId: string) => {
    const response = await instance.get(`/taxpayer/search-by-kkm/${kkmId}`);
    return response.data;
  },
  getHistoryTaxpayer: async (
    params: { orgId: string; page: string; size: string },
  ) => {
    const response = await instance.get("/history/organisation/list", {
      params,
    });
    return response.data;
  },
  setTaxpayerData: async (id: number, data: ISaveChangesData) => {
    const response = await instance.post(`/taxpayer/update/${id}`, data);
    return response.data;
  },
  blockUnblockTaxpayer: async (
    orgId: number,
    status: string,
    blockReason: string,
  ) => {
    const response = await instance.post("/taxpayer/block-unblock", {
      orgId,
      status,
      blockReason,
    });
    return response.data;
  },
  getTaxpayerByIin: (iin: string) =>
    instance.get("/taxpayer/search-by", {
      params: {
        ["iin-bin"]: iin,
      },
    }).then((response) => response.data || []),
  getAllEmailsTaxpayers: () =>
    instance.get("/taxpayer/find-all-active-taxpayers-email").then((response) =>
      response.data
    ),
};
