import {useEffect} from "react"

export const useAutoCloseModal = (onClose?: () => void) => {
    useEffect(() => {
        const timerId = setTimeout(() => {
            if (onClose) onClose()
        }, 2000)
        return () => clearTimeout(timerId)
    }, [onClose])
}
