import { FC } from 'react'
import Styles from './Input.module.scss'

interface InputErrorProps {
    error: string;
}
export const InputError: FC<InputErrorProps> = ({ error }) => {
    return (
        <div className={Styles.errorMessage}>
            {error}
        </div>
    )
}
