import classNames from 'classnames'
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react'
import { Link } from 'react-router-dom';
import Styles from './BreadCrumb.module.scss'
interface BreadCrumbActiveProps {
    to: string;
    children: React.ReactNode;
    active?: true;
}

export const BreadCrumbItem: FC<BreadCrumbActiveProps> = observer(({ children, to, active }) => {
    return (<Link className={classNames(active ? Styles.active : '', Styles.item)} to={to}>{children}</Link>)
}
)
