import { FC } from 'react'
import { UserAvatar } from './UserAvatar'
import Styles from './UserInfo.module.scss'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { LogoutIcon } from '@/assets/icons/menu/LogoutIcon'
import { rootStore } from '@/store/rootStore'
import { observer } from 'mobx-react-lite'
import theme from '@/shared/themes'
import { api } from '@/shared/api/api'

interface UserInfoProps {
    show: boolean;
}

export const UserInfo: FC<UserInfoProps> = observer(({ show }) => {

    const logOut = async () =>{
        try {
            await api.logout(rootStore.refreshToken as string)
        }catch (e) {
            console.log(e)
        }finally {
            rootStore.logout()
        }
    }


    return (
        <div className={classNames(Styles.userInfo)}>
            <div className={classNames("flex a-center", show ? "j-between" : "j-center")}>
                {
                    show &&
                    <div className="flex">
                        <UserAvatar
                            className={classNames("mr-10")}
                            src='https://avatarko.ru/img/kartinka/33/multfilm_lyagushka_32117.jpg' />
                        <div>
                            <Link to="" className={classNames(Styles.name)}>
                                {rootStore.me?.firstName}
                            </Link>
                            <div className={classNames(Styles.mail)}>
                                <a href="mailto:example@gmail.com">{rootStore.me?.email}</a>
                            </div>
                        </div>
                    </div>
                }

                <button onClick={logOut} data-tip="Выход" className={classNames(Styles.logout)}>
                    <LogoutIcon color={theme.mainLinkColor} />
                </button>
            </div>
        </div >
    )
})
