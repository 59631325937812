import { LoginLayout } from "@/components/Layouts/LoginLayout";
import { MainLayout } from "@/components/Layouts/MainLayout";
import { Auth } from "@/pages/Auth/Auth";
import { CheckCode } from "@/pages/Auth/CheckCode";
import { NewPass } from "@/pages/Auth/NewPass";
import { ResetPass } from "@/pages/Auth/ResetPass";
import { KkmPage } from "@/pages/Kkm/KkmPage";
import { IbdPage } from "@/pages/Ibd/IbdPage";
import { RolesPage } from "@/pages/Roles/RolesPage";
import { TaxpayerDetailsPage } from "@/pages/Taxpayers/TaxpayerDetailsPage";
import { TaxpayerHistoryPage } from "@/pages/Taxpayers/TaxpayerHistoryPage";
import { TaxpayersPage } from "@/pages/Taxpayers/TaxpayersPage";
import { TaxpayerTradePointsPage } from "@/pages/Taxpayers/TaxayerTradePointsPage";
import { TaxpayerUsersOrganizationPage } from "@/pages/Taxpayers/TaxpayerUsersOrganizationPage";
import { UsersPage } from "@/pages/Users/UsersPage";
import { KkmDetailsPage } from "@/pages/Kkm/KkmDetailsPage";
import { KkmHistoryEditsPage } from "@/pages/Kkm/KkmHistoryEditsPage";
import { KkmQueryInisPage } from "@/pages/Kkm/KkmQueryInisPage";
import { KkmTransactionInfoPage } from "@/pages/Kkm/KkmTransactionInfoPage";
import { LastShiftPage } from "@/pages/Kkm/LastShiftPage";
import { TradePointsPage } from "@/pages/TradePoints/TradePointsPage";
import { KkmCheckDetailPage } from "@/pages/Kkm/KkmCheckDetailPage";
import { BusinessProcessesPage } from "@/pages/BusinessProcesses/BusinessProcessesPage";
import { SettingsPage } from "@/pages/Settings/SettingsPage";
import { ServiceAccountPage } from "@/pages/ServiceAccount/ServiceAccountPage";
import { ReportListModal } from "@/modals/Kkm/ReportListModal";
import React from "react";
import { TariffsPage } from "@/pages/Tariffs/TariffsPage";
import { TariffsFormPage } from "@/pages/Tariffs/TariffsFormPage";
import { DecoderPage } from "@/pages/Decoder/DecoderPage";
import { SmsPage } from "@/pages/Sms/SmsPage";
import TemplateEdit from "@/pages/Sms/TemplateEdit";

interface IRoutes {
  path: string;
  element: JSX.Element;
  isProtected: boolean;
  child: {
    id: number;
    path: string | undefined;
    element: JSX.Element;
    children?: {
      id: number;
      path: string;
      element: JSX.Element;
    }[];
  }[];
}

export const routes: IRoutes[] = [
  {
    path: "/",
    element: <MainLayout />,
    isProtected: true,
    child: [
      {
        id: 1,
        path: "/taxpayers",
        element: <TaxpayersPage />,
      },
      {
        id: 2,
        path: "/taxpayers/:id",
        element: <TaxpayerDetailsPage />,
      },
      {
        id: 3,
        path: "/taxpayers/:id/trade-points",
        element: <TaxpayerTradePointsPage />,
      },
      {
        id: 4,
        path: "/taxpayers/:id/history",
        element: <TaxpayerHistoryPage />,
      },
      {
        id: 5,
        path: "/taxpayers/:id/usrs-of-org",
        element: <TaxpayerUsersOrganizationPage />,
      },
      {
        id: 6,
        path: "/users",
        element: <UsersPage />,
      },
      {
        id: 7,
        path: "/roles",
        element: <RolesPage />,
      },
      {
        id: 8,
        path: "/kkm",
        element: <KkmPage />,
      },
      {
        id: 9,
        path: "/kkm/:id",
        element: <KkmDetailsPage />,
        children: [
          {
            id: 1,
            path: "shiftList",
            element: <ReportListModal />,
          },
        ],
      },
      {
        id: 10,
        path: "/taxpayers/:taxpayerId/kkm/:id",
        element: <KkmDetailsPage />,
        children: [
          {
            id: 1,
            path: "shiftList",
            element: <ReportListModal />,
          },
        ],
      },
      {
        id: 11,
        path: "/kkm/:id/history",
        element: <KkmHistoryEditsPage />,
      },
      {
        id: 12,
        path: "/kkm/:id/history/inis",
        element: <KkmQueryInisPage />,
      },
      {
        id: 13,
        path: "/kkm/:kkmId/transactionInfo/:id/:transaction_date",
        element: <KkmTransactionInfoPage />,
      },
      {
        id: 14,
        path: "/kkm/:id/lastShift",
        element: <LastShiftPage />,
      },
      {
        id: 15,
        path: "/tradePoints",
        element: <TradePointsPage />,
      },
      {
        id: 16,
        path: "/kkm/:kkmId/check/:id/:transactionDate/:transactionId",
        element: <KkmCheckDetailPage />,
      },
      {
        id: 17,
        path: "/businessProcesses",
        element: <BusinessProcessesPage />,
      },
      {
        id: 18,
        path: "/settings",
        element: <SettingsPage />,
      },
      {
        id: 19,
        path: "/service_account",
        element: <ServiceAccountPage />,
      },
      {
        id: 20,
        path: "/tariffs",
        element: <TariffsPage />,
      },
      {
        id: 21,
        path: "/tariffs/form/:id",
        element: <TariffsFormPage />,
      },
      {
        id: 22,
        path: "/tariffs/form",
        element: <TariffsFormPage />,
      },
      {
        id: 23,
        path: "/ibd",
        element: <IbdPage />,
      },
      {
        id: 24,
        path: "/decoder",
        element: <DecoderPage />,
      },
      {
        id: 25,
        path: "/sms",
        element: <SmsPage />,
      },
      {
        id: 25,
        path: "sms/edit-template/:code",
        element: <TemplateEdit />,
      },
    ],
  },
  {
    path: "/login",
    element: <LoginLayout />,
    isProtected: false,
    child: [
      {
        id: 1,
        path: undefined,
        element: <Auth />,
      },
      {
        id: 2,
        path: "reset",
        element: <ResetPass />,
      },
      {
        id: 3,
        path: "checkcode",
        element: <CheckCode />,
      },
      {
        id: 4,
        path: "newpass",
        element: <NewPass />,
      },
    ],
  },
];
