import {ModalSuccess} from '@/shared/ui/Modal'
import {WarningMessage} from '@/shared/ui/WarningMessage'
import {IModalProps} from '@/shared/types/ModalProps'
import React, {FC, useState} from 'react'
import {GeneralDeleteModal} from '../Generals/GeneralDeleteModal'
import {TaxpayersStore} from "@/entities/Taxpayer";
import {observer} from "mobx-react-lite";
import {showToast} from "@/shared/lib";


export const DeleteTaxpayerModal: FC<IModalProps> = observer(({onClose}) => {
    const [successDeleteUser, setSuccessDeleteUser] = useState<Boolean>(false)

    return (
        <>
            {successDeleteUser ?
                <ModalSuccess onClose={onClose} className='py-3'>
                    Организация удалена!
                </ModalSuccess>
                :
                <GeneralDeleteModal onClose={onClose} onConfirmation={async () => {
                    const r = await showToast(async () => {
                        return await TaxpayersStore.deleteTaxpayer(TaxpayersStore.currentTaxpayer?.id)
                    })
                    if (r) {
                        setSuccessDeleteUser(true)
                    }
                }} blocked={Boolean(TaxpayersStore?.currentTaxpayer?.blockedAt)}>
                    <div className='fw-500 fs-16 mb-38 c_first_color'>
                        Вы действительно хотите удалить организацию?
                    </div>
                    <WarningMessage className="mb-50">
                        Перед удалением необходимо заблокировать организацию
                    </WarningMessage>
                </GeneralDeleteModal>
            }

        </>

    )
})

