import React, { FC } from 'react';
import { Card } from '@/shared/ui/Card';
import cn from 'classnames';
import { Input } from '@/shared/ui/Inputs';
import { observer } from 'mobx-react-lite';
import { Button } from '@/shared/ui/Buttons';
import s from './VariablesLiestEdit.module.scss';
import { SmsStore } from '@/store/SmsStore';
import { PlusIcon } from '@/assets/icons/PlusIcon';
import { DeleteButtonIcon } from '@/assets/icons/DeleteButtonIcon';

interface IProps {
	code: string;
	list: {
		name: string;
		value: string;
	}[];
	onChange: (index: number, value: string, name: string) => void;
	onSave: () => void;
}

export const VariablesListEdit: FC<IProps> = observer(
	({ list, onChange, onSave, code }) => {
		return (
			<Card className={cn('py-30 px-27 mt-12')}>
				<div className={cn('mb-30 c_first_color fs-16 fw-500')}>Заголовки</div>
				<div className={cn('flex j-between mb-7')}>
					<span className={cn('grow mr-30')}>Имя</span>
					<span className={cn('grow')}>Значение</span>
				</div>
				{list.map((item, index) => (
					<div className={cn('flex j-between mb-7')}>
						<div className={cn('mr-30 grow')}>
							<Input
								value={item.name}
								onChange={(e) => onChange(index, item.value, e.target.value)}
							/>
						</div>
						<div className={cn('grow flex')}>
							<Input
								value={item.value}
								onChange={(e) => onChange(index, e.target.value, item.name)}
							/>
						</div>
					</div>
				))}
				<div className={cn('flex')}>
					<div onClick={() => SmsStore.fetchTemplate(code)} className={cn(s.cancel, 'mr-18')}>
						<Button color={'cancel'}>Отмена</Button>
					</div>
					<div className={cn(s.save)}>
						<Button onClick={onSave}>Сохранить</Button>
					</div>
				</div>
			</Card>
		);
	},
);
