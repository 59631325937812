import React, {FC, useRef} from 'react'
import {getSnapshot} from 'mobx-state-tree'
import {TaxpayersStore, TaxpayersTable} from '@/entities/Taxpayer'
import {observer} from 'mobx-react-lite'
import {useSearchParams} from 'react-router-dom';
import {SearchNoResult} from '@/shared/ui/SearchNoResult'
import {PageNoData} from '@/shared/ui/PageNoData'
import {Paginate} from '@/shared/ui/Paginate'
import {Preload} from '@/shared/ui/Preload'
import {SearchTaxpayer} from "@/features/Taxpayer";


export const TaxpayersPage: FC = observer(
    () => {
        const [searchParams, setSearchParams] = useSearchParams()
        const size = searchParams.get('size')
        const searchInput = useRef<HTMLInputElement>(null)

        const onPaginate = (event: React.ChangeEvent<unknown>, page: Number) => {
            setSearchParams(param => {
                param.set("page", page.toString())
                return param
            })
        }

        const onChangeSelectSize = (size: number) => {
            setSearchParams(param => {
                param.set("size", size.toString())
                return param
            })
        }


        return (

            <div>
                <SearchTaxpayer searchInput={searchInput}/>
                {
                    !TaxpayersStore.taxpayers.length && !searchInput.current?.value
                        ?
                        <PageNoData/> :
                        !TaxpayersStore.taxpayers.length && searchInput.current?.value ?
                            <SearchNoResult searchQuery={searchInput.current?.value || ''}/> :
                            <TaxpayersTable data={getSnapshot(TaxpayersStore.taxpayers)}/>

                }
                {
                    TaxpayersStore.taxpayers.length ?
                        <Paginate
                            onChange={onPaginate}
                            currentPage={TaxpayersStore.currentPage}
                            countPages={TaxpayersStore.totalPages}
                            size={size}
                            onChangeSelectSize={onChangeSelectSize}
                        /> : ''
                }
                {
                    TaxpayersStore.loading && <Preload/>
                }
            </div>
        )
    }
)
