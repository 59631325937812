import { authServices } from './authServices';
import { usersServices } from './usersServices';
import { rolesServices } from './rolesServices';
import { taxpayersServices } from './taxpayersServices';
import { kkmServices } from './kkmServices';
import { tradePointsServices } from './tradePointsServices';
import { businessProcessesServices } from './businessProcessesServices';
import { permissionsServices } from './permissionsServices';
import { userOgrServices } from './usersOgrServices';
import { settingsServices } from './settingsServices';
import { serviceAccountService } from './serviceAccountService';
import { tariffsServices } from './tariffsServices';
import { ibdServices } from '@/shared/api/ibdServices';
import { decodeService } from '@/shared/api/decodeService';
import {smsService} from "@/shared/api/smsService";
export const api = {
	...authServices,
	...usersServices,
	...rolesServices,
	...taxpayersServices,
	...kkmServices,
	...tradePointsServices,
	...businessProcessesServices,
	...permissionsServices,
	...userOgrServices,
	...settingsServices,
	...serviceAccountService,
	...tariffsServices,
	...ibdServices,
	...decodeService,
	...smsService
};
