import { FC } from 'react'
import Styles from './UserAvatar.module.scss'
import classNames from 'classnames'

interface UserAvatarProp {
    src: string;
    className?: string;
}
export const UserAvatar: FC<UserAvatarProp> = ({ src, className }) => {
    return (
        <img src={src} alt="Avatar" className={classNames(Styles.avatar, className)} />
    )
}
