import {PageTitle} from '@/shared/ui/PageTitle'
import {Card} from '@/shared/ui/Card'
import {Input} from '@/shared/ui/Inputs'
import {TableView} from '@/shared/ui/Tables'
import {CardTradePointModal} from '@/modals/TradePoints/CardTradePointModal'
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from '@tanstack/react-table'
import classNames from 'classnames'
import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import Styles from './TradePointsPage.module.scss'
import {Select} from '@/shared/ui/Select'
import {Button} from '@/shared/ui/Buttons'
import {useSelect} from '@/shared/hooks/useSelect'
import {Checkbox} from '@/shared/ui/Checkbox'
import {ButtonRefresh} from '@/shared/ui/Buttons'
import {PageNoData} from '@/shared/ui/PageNoData'
import {SearchNoResult} from '@/shared/ui/SearchNoResult'
import {getSnapshot} from 'mobx-state-tree'
import {TradePointStore} from '@/store/TradePointsStore'
import {observer} from 'mobx-react-lite'
import {useSearchParams} from "react-router-dom";
import {Scrollbars} from "react-custom-scrollbars-2";
import {Preload} from "@/shared/ui/Preload";
import {Paginate} from "@/shared/ui/Paginate";

interface ITradePoint {
    id: number;
    iinBin: string;
    regionTitle: string;
    fullAddress: string;
    contactPhone: string;
    channelId: number;
    isDelete: boolean;
    vpnStatus: number;

}

const columnHelper = createColumnHelper<ITradePoint>()


export const TradePointsPage: FC = observer(() => {


        const [searchParams, setSearchParams] = useSearchParams()
        const page = searchParams.get('page')
        const size = searchParams.get('size')
        const iinParam = searchParams.get("iin")
        const chanelParam = searchParams.get("chanel")
        const addressParam = searchParams.get("address")
        const status = searchParams.get("status")
        const [sorting, setSorting] = useState<SortingState>([])
        const iinRef = useRef<HTMLInputElement>(null)
        const chanelIdRef = useRef<HTMLInputElement>(null)
        const addressRef = useRef<HTMLInputElement>(null)

        const [searchDirty, setSearchDirty] = useState(false)

        const [selectedId, setSelectedId] = useState<null | number>(null)
        const [cardTradePointModal, setCardTradePointModal] = useState<boolean>(false)

        const {
            options,
            getLabelOption,
            handleChangeOption,
        } = useSelect(
            [
                {
                    id: -1,
                    label: "Не выбрано",
                    checked: true
                },
                {
                    id: 0,
                    label: "Не подключен",
                    checked: false,
                },
                {
                    id: 1,
                    label: "Ожидание",
                    checked: false,
                },
                {
                    id: 2,
                    label: "Подключен",
                    checked: false,
                },
                {
                    id: 3,
                    label: "Подключение невозможно",
                    checked: false,
                }
            ]
        )

        useEffect(() => {
            if (iinRef.current && addressRef.current && chanelIdRef.current && status) {
                iinRef.current.value = iinParam || ""
                addressRef.current.value = addressParam || ""
                chanelIdRef.current.value = chanelParam || ""
                handleChangeOption(status ? +status : -1)
            }
        }, [iinRef, addressRef, chanelIdRef, addressParam, chanelParam, iinParam, handleChangeOption, status])
        const onPaginate = (event: React.ChangeEvent<unknown>, page: Number) => {
            setSearchParams(param => {
                param.set("page", page.toString())
                return param
            })
        }
        const onChangeSelectSize = (size: number) => {
            setSearchParams(param => {
                param.set("size", size.toString())
                return param
            })
        }

        const openModal = (id: number) => {
            setSelectedId(id)
            setCardTradePointModal(true)
        }


        const handleSearch = () => {

            setSearchParams({
                iin: iinRef.current?.value || '',
                address: addressRef.current?.value || '',
                chanel: chanelIdRef.current?.value || '',
                status: options.find(item => item.checked)?.id === -1 ? "" : String(options.find(item => item.checked)?.id)
            })
        }
        const fetchData = useCallback(() => {
            setSearchDirty(true)
            TradePointStore.fetchAll({
                iinBin: iinParam || undefined,
                vpnStatus: !status ? undefined : +status,
                channelId: Number(chanelParam) || undefined,
                address: addressParam || undefined,
            }, {
                page,
                size
            })
        }, [page, size, iinParam, chanelParam, addressParam, status])

        useEffect(() => {
            if (chanelParam || addressParam || status || iinParam) {
                fetchData()
            }
        }, [page, size, chanelParam, addressParam, status, iinParam, fetchData])


        const columns = useMemo(() => {
            return [
                columnHelper.accessor('id', {
                    header: 'ID точки',
                    cell: ({getValue}) => <span className='table-link'
                                                onClick={() => openModal(getValue())}>{getValue()}</span>
                }),
                columnHelper.accessor('iinBin', {
                    header: 'ИИН/БИН'
                }),
                columnHelper.accessor('regionTitle', {
                    header: 'Регион'
                }),
                columnHelper.accessor('fullAddress', {
                    header: 'Адрес точки'
                }),
                columnHelper.accessor('contactPhone', {
                    header: 'Телефон'
                }),
                columnHelper.accessor('channelId', {
                    header: 'ID канала'
                }),
                columnHelper.accessor('vpnStatus', {
                    header: 'Статус VPN',
                    cell: ({getValue}) => options.find(item => item.id === getValue())?.label
                }),
                columnHelper.accessor("isDelete", {
                    header: 'Удалена',
                    cell: ({getValue}) => <div className="flex j-center"><Checkbox checked={getValue()}/></div>
                })
            ]
        }, [options])

        const tableInstance = useReactTable({
            data: getSnapshot(TradePointStore.retailPlacesData),
            columns,
            state: {
                sorting
            },
            onSortingChange: setSorting,
            getSortedRowModel: getSortedRowModel(),
            getCoreRowModel: getCoreRowModel()
        })

        const handleRefresh = () => {
            if (iinRef.current && chanelIdRef.current && addressRef?.current) {
                handleChangeOption(-1)
                iinRef.current.value = ''
                chanelIdRef.current.value = ''
                addressRef.current.value = ''
                setSearchDirty(false)
                setSearchParams({})
                TradePointStore.clearRetailPlacesData()
            }
        }
        return (
            <>
                <Card className={classNames('py-30 mb-12')}>
                    <div className='pl-27'>
                        <PageTitle className='mb-30'>
                            Торговые точки
                        </PageTitle>
                        <div className={Styles.filter}>
                            <Input
                                ref={iinRef}
                                placeholder='ИИН/БИН'
                                className={{wrapper: classNames(Styles.input, "mr-10")}}/>
                            <Select
                                clickOutside
                                className={classNames(Styles.select, "mr-10")}
                                contentClassName={Styles.selectContent}
                                label={getLabelOption()}>
                                {
                                    options.map(item =>
                                        <li key={item.id} className={classNames('option', item.checked && 'active')}>
                                            <label>
                                                <Checkbox className='mr-11' onChange={() => handleChangeOption(item.id)}
                                                          checked={item.checked}/>
                                                <span>{item.label}</span>
                                            </label>
                                        </li>
                                    )
                                }
                            </Select>
                            <Input
                                ref={chanelIdRef}
                                placeholder='ID канала'
                                className={{wrapper: classNames(Styles.input, "mr-10")}}/>
                            <Input
                                ref={addressRef}
                                placeholder='Адрес точки'
                                className={{wrapper: classNames(Styles.addressInput, "mr-20")}}/>
                            <Button className='mr-20' style={{width: 85}} onClick={handleSearch}>
                                Найти
                            </Button>
                            <ButtonRefresh onClick={handleRefresh}/>
                        </div>
                    </div>
                </Card>
                {
                    (!searchDirty && !TradePointStore.retailPlacesData.length) ?
                        <PageNoData
                            message="Введите значение для поиска и найдите необходимую информацию о торговых точках"/> :
                        !TradePointStore.retailPlacesData.length ?
                            <SearchNoResult/> :
                            <Card>
                                <Scrollbars
                                    autoHeight autoHeightMax={"100%"}>
                                    <TableView tableInstance={{...tableInstance}}
                                               className={classNames(Styles.table, "table-left")}/>
                                </Scrollbars>
                            </Card>
                }
                {
                    cardTradePointModal && <CardTradePointModal id={selectedId} onClose={() => {
                        setCardTradePointModal(false)
                        setSelectedId(null)
                    }}/>
                }

                {
                    TradePointStore.retailPlacesData.length ?
                        <Paginate
                            onChange={onPaginate}
                            currentPage={TradePointStore.currentPage}
                            countPages={TradePointStore.totalPages}
                            size={size}
                            onChangeSelectSize={onChangeSelectSize}
                        /> : ''
                }
                {
                    TradePointStore.loading && <Preload/>
                }
            </>

        )
    }
)
