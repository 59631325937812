import {ModalSuccess} from '@/shared/ui/Modal'
import {UsersStore} from '@/store/UsersStore.';
import {IModalProps} from '@/shared/types/ModalProps'
import {observer} from 'mobx-react-lite';
import React, {FC, useState} from 'react'
import {GeneralDeleteModal} from '../Generals/GeneralDeleteModal'

interface IDeleteModalProps extends IModalProps {
    userId: number | null;
    fetchData: () => void;
}

export const DeleteUserModal: FC<IDeleteModalProps> = observer(({onClose, userId, fetchData}) => {
    const [successDeleteUser, setSuccessDeleteUser] = useState<Boolean>(false)
    const handleAction = async () => {
        await UsersStore.deleteUser(userId as number)
        fetchData()
        setSuccessDeleteUser(true)
    }
    return (
        <>
            {successDeleteUser ?
                <ModalSuccess onClose={onClose} className='py-3 c_first_color'>
                    Удалено!
                </ModalSuccess>
                :
                <GeneralDeleteModal onClose={onClose} onConfirmation={handleAction}>
                    <div className='fw-500 fs-18 mb-47 c_first_color text-no-wrap'>
                        Вы действительно хотите удалить этого пользователя?
                    </div>
                </GeneralDeleteModal>
            }
        </>

    )
})

