import { useEffect, useRef, useState } from 'react'

interface IAnimation {
    classHide: string;
    classShow: string;
}

export default function useShowHideAnimation({ classHide, classShow }: IAnimation) {
    const [state, setState] = useState<boolean>(true)
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (state && ref.current?.classList.contains(classHide)) {
            ref.current?.classList.add(classShow)
        } else if (!state) {
            ref.current?.classList.remove(classShow)
            ref.current?.classList.add(classHide)
        }
    }, [state, ref, classHide, classShow])

    return [state, setState, ref] as const
}
