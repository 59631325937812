import {FC} from 'react'

export const GeoIcon: FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.47003 10.75C4.76003 10.75 4.04003 10.49 3.49003 9.96C1.65003 8.21 0.910033 6.3 1.34003 4.43C1.85003 2.23 3.78003 1.25 5.47003 1.25C7.16003 1.25 9.10003 2.24 9.60003 4.43C10.03 6.3 9.28003 8.21 7.45003 9.96C6.90003 10.49 6.19003 10.75 5.47003 10.75ZM2.80003 4.77C2.42003 6.44 3.53003 7.92 4.53003 8.87C5.06003 9.38 5.89003 9.37 6.42003 8.87C7.42003 7.92 8.53003 6.44 8.14003 4.76C7.80003 3.28 6.49003 2.75 5.48003 2.75C4.46003 2.75 3.15003 3.28 2.80003 4.77Z" fill="white" />
            <path d="M5.51001 6.20001C4.96001 6.20001 4.51001 5.75001 4.51001 5.20001C4.51001 4.65001 4.95001 4.20001 5.51001 4.20001H5.52001C6.07001 4.20001 6.52001 4.65001 6.52001 5.20001C6.52001 5.75001 6.07001 6.20001 5.51001 6.20001Z" fill="white" />
            <path d="M18.49 22.75C17.78 22.75 17.06 22.49 16.5 21.96C14.65 20.21 13.9 18.3 14.34 16.42C14.85 14.23 16.79 13.24 18.49 13.24C20.19 13.24 22.13 14.23 22.64 16.42C23.07 18.29 22.32 20.21 20.47 21.95C19.92 22.49 19.21 22.75 18.49 22.75ZM18.49 14.75C17.47 14.75 16.15 15.28 15.8 16.77C15.41 18.44 16.53 19.93 17.53 20.88C18.06 21.39 18.9 21.39 19.44 20.88C20.44 19.93 21.56 18.45 21.17 16.78C20.84 15.28 19.52 14.75 18.49 14.75Z" fill="white" />
            <path d="M18.53 18.2C17.98 18.2 17.53 17.75 17.53 17.2C17.53 16.65 17.97 16.2 18.53 16.2H18.54C19.09 16.2 19.54 16.65 19.54 17.2C19.54 17.75 19.08 18.2 18.53 18.2Z" fill="white" />
            <path d="M11.9999 19.75H9.31994C8.15994 19.75 7.14994 19.05 6.74994 17.97C6.33994 16.89 6.63994 15.7 7.50994 14.93L15.4999 7.94C15.9799 7.52 15.9899 6.95 15.8499 6.56C15.6999 6.17 15.3199 5.75 14.6799 5.75H11.9999C11.5899 5.75 11.2499 5.41 11.2499 5C11.2499 4.59 11.5899 4.25 11.9999 4.25H14.6799C15.8399 4.25 16.8499 4.95 17.2499 6.03C17.6599 7.11 17.3599 8.3 16.4899 9.07L8.49994 16.06C8.01994 16.48 8.00994 17.05 8.14994 17.44C8.29994 17.83 8.67994 18.25 9.31994 18.25H11.9999C12.4099 18.25 12.7499 18.59 12.7499 19C12.7499 19.41 12.4099 19.75 11.9999 19.75Z" fill="white" />
            <path d="M11.9999 19.75H9.31994C8.15994 19.75 7.14994 19.05 6.74994 17.97C6.33994 16.89 6.63994 15.7 7.50994 14.93L15.4999 7.94C15.9799 7.52 15.9899 6.95 15.8499 6.56C15.6999 6.17 15.3199 5.75 14.6799 5.75H11.9999C11.5899 5.75 11.2499 5.41 11.2499 5C11.2499 4.59 11.5899 4.25 11.9999 4.25H14.6799C15.8399 4.25 16.8499 4.95 17.2499 6.03C17.6599 7.11 17.3599 8.3 16.4899 9.07L8.49994 16.06C8.01994 16.48 8.00994 17.05 8.14994 17.44C8.29994 17.83 8.67994 18.25 9.31994 18.25H11.9999C12.4099 18.25 12.7499 18.59 12.7499 19C12.7499 19.41 12.4099 19.75 11.9999 19.75Z" fill="white" />
        </svg>

    )
}

