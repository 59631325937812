import {createRoot} from 'react-dom/client';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import '@/assets/styles/main.scss'
import 'react-toastify/dist/ReactToastify.css';
import {ProtectedRoute} from './ProtectedRoute';
import React from 'react';
import {routes} from './routes';
import "react-datepicker/dist/react-datepicker.css";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import ru from 'date-fns/locale/ru';

registerLocale('ru', ru)
setDefaultLocale("ru")
const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container)

root.render(
    <BrowserRouter>
        <Routes>
            {
                routes.map(({path, element, child, isProtected}) => {
                    return (
                        <React.Fragment key={path}>
                            <Route path={path} element={
                                <>
                                    {
                                        isProtected ?
                                            <ProtectedRoute>{element}</ProtectedRoute>
                                            :
                                            element
                                    }
                                </>
                            }
                            >
                                {
                                    child.map((item) =>
                                        <React.Fragment key={item.id}>
                                            {
                                                item.children ?
                                                    <Route path={item.path}
                                                           element={item.element}>
                                                        {
                                                            item.children?.map(_item => <Route key={_item.id}
                                                                                               path={_item.path}
                                                                                               element={_item.element}/>)
                                                        }
                                                    </Route>
                                                    :
                                                    <Route path={item.path} index={!item.path} element={item.element}/>
                                            }


                                        </React.Fragment>)
                                }
                            </Route>
                        </React.Fragment>
                    )
                })
            }

        </Routes>
    </BrowserRouter>
);
