import { BreadCumpArrowIcon } from '@/assets/icons/BreadCumpArrowIcon';
import { FC, useState } from 'react'
import Styles from './BreadCrumb.module.scss'
import classNames from 'classnames'
import React from 'react';
import { observer } from 'mobx-react-lite';

interface BreadCrumbProps {
    children: Array<React.ReactElement | undefined | string>;
    className?: string;
}

export const BreadCrumb: FC<BreadCrumbProps> = observer(({ children, className }) => {
    const [itemsAmount] = useState<number>(children.length - 1)
    return (
        <ul className={classNames(Styles.breadCrumb, className)}>
            {
                children.filter(item => typeof item !== 'string').map((item, index) => {
                    if (typeof item !== 'string') {
                        return <React.Fragment key={item?.props.to}>
                            <li>{item}</li>
                            {
                                itemsAmount !== index &&
                                <li>
                                    <BreadCumpArrowIcon />
                                </li>
                            }
                        </React.Fragment>
                    }
                    return null
                })
            }
        </ul>
    )
})
