import {instance} from "@/shared/api/instanceAxios";

export const serviceAccountService = {
    getServicesAccountList: async (params?: any) => {
        const response = await instance.get('/service-user/list', {
            params
        })
        return response.data
    },
    updateServiceAccount: async (data?: any) => {
        const response = await instance.put('/service-user/update', data)
        return response.data
    },
    createServiceAccount: async (data?: any) => {
        const response = await instance.post('/service-user/create', data)
        return response.data
    },
    getServiceAccountById: async (id: string | null) => {
        const response = await instance.get(`/service-user/${id}`)
        return response.data
    },
    serviceAccountDelete: async (id: string | number) => {
        const response = await instance.delete('/service-user/delete', {
            params: {
                id
            }
        })
        return response.data
    },
    serviceAccountBindingToOrg: async (login: string, orgId: number | string) =>{
        const response = await instance.get('/service-user/bind-user-to-organisation', {
            params:{
                login,
                orgId
            }
        })
        return response.data
    }
}
