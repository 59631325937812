import {PageTitle} from '@/shared/ui/PageTitle'
import {Accordion} from '@/shared/ui/Accordion'
import {Button} from '@/shared/ui/Buttons'
import {ButtonRefresh} from '@/shared/ui/Buttons/ButtonRefresh'
import {Card} from '@/shared/ui/Card/Card'
import {Input} from '@/shared/ui/Inputs'
import {InputSearch} from '@/shared/ui/Inputs/InputSearch'
import {Textarea} from '@/shared/ui/Textarea'
import {IErrorMessage} from '@/shared/api/instanceAxios'
import {SettingsStore} from '@/store/SettingsStore'
import axios from 'axios'
import classNames from 'classnames'
import {observer} from 'mobx-react-lite'
import {useCallback, useEffect, useRef, useState} from 'react'
import {toast, ToastContainer} from 'react-toastify'
import Styles from '../GeneralStyles.module.scss'
import styles from './SettingsPage.module.scss';

export const SettingsPage = observer(() => {

    const [searchQuery, setSearchQuery] = useState("")
    const ref = useRef<HTMLInputElement | null>(null)

    const fetchData = useCallback(async (search: string) => {
        try {
            await SettingsStore.fetchAll(search)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const response = await e.response?.data as IErrorMessage
                toast.error(response?.details?.join(" "), {
                    position: 'bottom-right'
                })
            }
        }
    }, [])
    useEffect(() => {
        fetchData(searchQuery)
    }, [fetchData, searchQuery])

    const handleClearSearchClick = () => {
        setSearchQuery('')
        ref?.current?.focus()
        if (ref.current) {
            ref.current.value = ""
        }
    }
    const handleSearch = () => {
        const value = ref.current?.value
        if (value) {
            setSearchQuery(value)
        }
    }
    const saveSetting = async (Key: string, Value: string) => {
        try {
            await SettingsStore.saveSetting(Key, Value)
            toast.success("Успешно сохранено", {
                position: 'bottom-right'
            })
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const response = await e.response?.data as IErrorMessage
                toast.error(response?.details?.join(" "), {
                    position: 'bottom-right'
                })
            }
        }
    }

    return (
        <>
            <Card className={classNames('pt-30 pr-32 pl-27 pb-23 mb-12')}>
                <PageTitle className="mb-30">
                    Настройки
                </PageTitle>
                <div className="flex a-center mb-15 gap-20">
                    <InputSearch
                        clearSearch={handleClearSearchClick}
                        ref={ref}
                        className={Styles.searchInput}
                        placeholder="Введите название или значение настройки для поиска"
                    >
                        <Button onClick={handleSearch} className={classNames(Styles.searchButton)}>
                            Найти
                        </Button>
                    </InputSearch>
                    <ButtonRefresh/>
                </div>
            </Card>
            <div>
                {
                    SettingsStore.settings.map(item =>
                        <Accordion key={item.Key} title={item.Key}>
                            <div className='mb-7 c_first_color fs-14'>
                                Код
                            </div>
                            <Input defaultValue={item.Key} disabled color='disabled'/>
                            <div className='mt-20 mb-7 c_first_color fs-14'>
                                Значение
                            </div>
                            <Textarea
                                value={item.Value || ''}
                                onChange={value => SettingsStore.changeValue(item.Key, value)}/>
                            <Button className={styles.button} onClick={() => saveSetting(item.Key, item.Value || '')}>
                                Сохранить
                            </Button>
                        </Accordion>
                    )
                }

            </div>
            <ToastContainer/>
        </>

    )
})
