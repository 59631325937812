import React, { FC } from 'react'
import theme from '@/shared/themes'
export const NewUserIcon: FC = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="10" fill={theme.mainColor} fillOpacity="0.1" />
            <path d="M18.5214 7C21.4298 7 23.7879 9.35741 23.7879 12.2658C23.7879 15.1749 21.4298 19.153 18.5214 19.153C15.613 19.153 13.2549 15.1749 13.2549 12.2658C13.2549 9.35741 15.613 7 18.5214 7Z" fill={theme.mainPressColor} />
            <path d="M26.4289 33C29.5272 33 32.0388 30.4883 32.0388 27.3901C32.0388 24.2918 29.5272 21.7802 26.4289 21.7802C23.3306 21.7802 20.819 24.2918 20.819 27.3901C20.819 30.4883 23.3306 33 26.4289 33Z" fill={theme.mainColor} />
            <path d="M30.3076 26.9225H22.5503V27.8575H30.3076V26.9225Z" fill="white" />
            <path d="M26.8964 23.5114H25.9614V31.2687H26.8964V23.5114Z" fill="white" />
            <path d="M19.9462 27.3901C19.9462 28.7421 20.362 29.9987 21.0738 31.039C20.246 31.1899 19.3927 31.2684 18.5213 31.2684C14.8568 31.2684 11.5164 29.8784 9 27.5964V27.5883C9 23.7929 11.2209 20.5167 14.4335 18.9877C15.6409 20.1919 17.0546 20.8408 18.5213 20.8408C19.9886 20.8408 21.4017 20.1919 22.6097 18.9877C23.6544 19.4851 24.5943 20.167 25.386 20.9917C22.3055 21.4916 19.9462 24.1706 19.9462 27.3901Z" fill={theme.mainPressColor} />
        </svg>

    )
}

