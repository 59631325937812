import {api} from "@/shared/api/api";
import {flow, types} from "mobx-state-tree";
import {IGetIbdListParams} from "@/shared/api/ibdServices";

const IbdModel = types.model({
    totalItems: types.number,
    itemsPerPage: types.number,
    totalPages: types.number,
    currentPage: types.number,
    loading: types.boolean,
    ibdList: types.array(types.frozen()),
}).actions(self => ({
    fetchAll: flow(function* (params: IGetIbdListParams, {page = 1, size = 10}) {
        try {
            self.loading = true;
            const r = yield api.getIbdList(params, {page, size});
            self.totalItems = r.totalItems
            self.itemsPerPage = r.itemsPerPage
            self.currentPage = r.currentPage
            self.totalPages = r.totalPages
            self.ibdList = r.ibdList || []
        } catch (e) {
            throw e
        } finally {
            self.loading = false;
        }
    }),
    reset() {
        self.loading = false
        self.ibdList.splice(0, self.ibdList.length)
        self.currentPage = 1
        self.itemsPerPage = 15
        self.totalItems = 0
        self.totalPages = 0
    }
}))


export const IbdStore = IbdModel.create({
    loading: false,
    ibdList: [],
    currentPage: 1,
    itemsPerPage: 15,
    totalItems: 0,
    totalPages: 0,
})

