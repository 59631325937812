import React, {FC, useEffect} from 'react';
import {Modal, ModalHeader} from "@/shared/ui/Modal";
import Styles from "@/modals/Kkm/ReportListModal.module.scss";
import classNames from "classnames";
import {Button} from "@/shared/ui/Buttons";
import {IModalProps} from "@/shared/types/ModalProps";
import {observer} from "mobx-react-lite";
import {KkmStore} from "@/entities/Kkm";


interface IProps extends IModalProps {
    id: number | null;
}

export const KkmStateModal: FC<IProps> = observer(({onClose, id}) => {
        useEffect(() => {
            KkmStore.fetchState(id as number)
        }, [id])
        return (
            <Modal onClose={onClose}>
                <ModalHeader onClose={onClose} className={Styles.header} title='Состояние ККМ'/>
                <div className='bg_fourth_color'>
                    <div className='c_first_color fs-12 py-15 px-27'>
                        <pre>
                            {
                                JSON.stringify(KkmStore?.state || '{}', null, 1)
                            }
                        </pre>
                    </div>
                </div>
                <div className="flex j-end">
                    <Button onClick={onClose} color='cancel' className={classNames('mr-18 button', Styles.closeButton)}>
                        Закрыть
                    </Button>
                </div>

            </Modal>
        );
    }
)
