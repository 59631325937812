import {flow, Instance, types} from "mobx-state-tree";
import {api} from "@/shared/api/api";

const TariffModel = types.model("TariffsModel", {
    id: types.identifierNumber,
    nameRu: types.maybeNull(types.string),
    shortDescriptionRu: types.maybeNull(types.string),
    fullDescriptionRu: types.maybeNull(types.string),
    nameKz: types.maybeNull(types.string),
    shortDescriptionKz: types.maybeNull(types.string),
    fullDescriptionKz: types.maybeNull(types.string),
    connectionType: types.maybeNull(types.number),
    extId: types.maybeNull(types.number),
    periodPrice: types.maybeNull(types.number),
    periodUnit: types.maybeNull(types.string),
    tariffType: types.maybeNull(types.number),
    actualFrom: types.maybeNull(types.string),
    actualTo: types.maybeNull(types.string),
    kkmModels: types.maybeNull(types.string),
    kkmSerialNumbers: types.maybeNull(types.string),
    createdBy: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    updatedBy: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string)
})

export interface ITariff extends Instance<typeof TariffModel> {
}

const TariffsStoreModel = types.model("TariffsStore", {
    loading: types.boolean,
    list: types.array(TariffModel),
    currentTariff: types.maybeNull(TariffModel),
    periods: types.maybeNull(types.array(types.model("", {
        code: types.string,
        nameRu: types.string,
        nameKk: types.string,
    })))
}).actions(self => ({
    fetchAll: flow(function* (params?: { name?: string, extId?: number, page?: string, size?: string }) {
        self.loading = true
        try {
            self.list = yield api.getTariffsList(params)

        } catch (e) {
            throw e
        } finally {
            self.loading = false
        }
    }),
    fetchById: flow(function* (tariffId: number) {
        self.loading = true
        try {
            self.currentTariff = yield api.getTariffById({tariffId})
        } catch (e) {
            throw e
        } finally {
            self.loading = false
        }
    }),
    createTariff: flow(function* (tariff: ITariff) {
        self.loading = true
        try {
            yield api.createTariff(tariff)
        } catch (e) {
            throw e
        } finally {
            self.loading = false
        }


    }),
    changeTariff: flow(function* (tariffId: number, tariff: ITariff) {
        self.loading = true;
        try {
            yield api.changeTariff(tariffId, tariff)
        } catch (e) {
            throw e
        } finally {
            self.loading = false
        }
    }),
    fetchPeriods: flow(function* () {
        self.loading = true;
        try {
            self.periods = yield api.getTariffsPeriod()
        } catch (e) {
            throw e
        } finally {
            self.loading = false
        }

    })
}))

export const TariffStore = TariffsStoreModel.create({
    loading: false,
    list: [],
    currentTariff: null,
})

