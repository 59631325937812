import theme from '@/shared/themes'
import { FC } from 'react'

export const CloseSearchIcon: FC = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99996 1.66663C5.40829 1.66663 1.66663 5.40829 1.66663 9.99996C1.66663 14.5916 5.40829 18.3333 9.99996 18.3333C14.5916 18.3333 18.3333 14.5916 18.3333 9.99996C18.3333 5.40829 14.5916 1.66663 9.99996 1.66663ZM12.8 11.9166C13.0416 12.1583 13.0416 12.5583 12.8 12.8C12.675 12.925 12.5166 12.9833 12.3583 12.9833C12.2 12.9833 12.0416 12.925 11.9166 12.8L9.99996 10.8833L8.08329 12.8C7.95829 12.925 7.79996 12.9833 7.64163 12.9833C7.48329 12.9833 7.32496 12.925 7.19996 12.8C6.95829 12.5583 6.95829 12.1583 7.19996 11.9166L9.11663 9.99996L7.19996 8.08329C6.95829 7.84163 6.95829 7.44163 7.19996 7.19996C7.44163 6.95829 7.84163 6.95829 8.08329 7.19996L9.99996 9.11663L11.9166 7.19996C12.1583 6.95829 12.5583 6.95829 12.8 7.19996C13.0416 7.44163 13.0416 7.84163 12.8 8.08329L10.8833 9.99996L12.8 11.9166Z" fill={theme.secondColor} />
        </svg>

    )
}


