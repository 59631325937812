import React, { FC } from 'react';
import { Card } from '@/shared/ui/Card';
import cn from 'classnames';
import TemplateListItem from '@/pages/Sms/components/TemplateListItem';
import { observer } from 'mobx-react-lite';

interface IProps {
	list: {
		code: string;
		title: string | null;
		description: string;
	}[];
	onEdit: (code: string) => void;
}

export const TemplateList: FC<IProps> = observer(({ list, onEdit }) => {
	return (
		<>
			{list.map((item) => (
				<TemplateListItem
					key={item.code}
					onEdit={() => onEdit(item.code)}
					title={item.title}
					description={item.description}
				/>
			))}
		</>
	);
});
