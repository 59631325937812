import React from 'react'

export const FileUploadIcon = () => {
    return (
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.8017 22.532L23.3017 17.032C23.1312 16.8602 22.9285 16.7238 22.7051 16.6307C22.4817 16.5377 22.242 16.4897 22 16.4897C21.758 16.4897 21.5184 16.5377 21.295 16.6307C21.0715 16.7238 20.8688 16.8602 20.6983 17.032L15.1983 22.532C14.8531 22.8773 14.6592 23.3455 14.6592 23.8337C14.6592 24.3219 14.8531 24.7902 15.1983 25.1354C15.5436 25.4806 16.0118 25.6745 16.5 25.6745C16.9882 25.6745 17.4565 25.4806 17.8017 25.1354L20.1667 22.752V40.3337C20.1667 40.8199 20.3598 41.2863 20.7037 41.6301C21.0475 41.9739 21.5138 42.167 22 42.167C22.4862 42.167 22.9526 41.9739 23.2964 41.6301C23.6402 41.2863 23.8333 40.8199 23.8333 40.3337V22.752L26.1983 25.1354C26.3688 25.3072 26.5715 25.4436 26.795 25.5367C27.0184 25.6298 27.258 25.6777 27.5 25.6777C27.742 25.6777 27.9817 25.6298 28.2051 25.5367C28.4285 25.4436 28.6312 25.3072 28.8017 25.1354C28.9735 24.9649 29.1099 24.7622 29.203 24.5388C29.2961 24.3154 29.344 24.0757 29.344 23.8337C29.344 23.5917 29.2961 23.3521 29.203 23.1287C29.1099 22.9052 28.9735 22.7025 28.8017 22.532Z" fill="#758EA8" />
            <path d="M34.7049 12.8335C34.2691 9.77445 32.7441 6.97536 30.4101 4.95039C28.0761 2.92542 25.0899 1.81055 21.9999 1.81055C18.91 1.81055 15.9238 2.92542 13.5898 4.95039C11.2558 6.97536 9.73083 9.77445 9.29494 12.8335C7.10691 12.9381 5.05001 13.9075 3.57676 15.5286C2.1035 17.1497 1.33457 19.2896 1.43911 21.4777C1.54365 23.6657 2.5131 25.7226 4.13419 27.1959C5.75529 28.6691 7.89524 29.4381 10.0833 29.3335H10.9999V23.8335C11.0012 22.3753 11.5815 20.9773 12.6133 19.9468L18.1133 14.4468C19.1445 13.4169 20.5424 12.8384 21.9999 12.8384C23.4574 12.8384 24.8554 13.4169 25.8866 14.4468L31.3866 19.9468C32.4184 20.9773 32.9987 22.3753 32.9999 23.8335V29.3335H33.9166C36.1046 29.4381 38.2446 28.6691 39.8657 27.1959C41.4868 25.7226 42.4562 23.6657 42.5608 21.4777C42.6653 19.2896 41.8964 17.1497 40.4231 15.5286C38.9499 13.9075 36.893 12.9381 34.7049 12.8335Z" fill="#CADCF0" />
        </svg>

    )
}

