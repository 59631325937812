import React, {useCallback, useEffect, useRef} from 'react';
import {InputSearchIcon} from "@/shared/ui/Inputs";
import {Button, ButtonRefresh} from "@/shared/ui/Buttons";
import classNames from "classnames";
import {TariffStore} from "@/entities/Tariff";
import {useSearchParams} from "react-router-dom";
import {SearchIcon} from "@/assets/icons/SearchIcon.";
import theme from "@/shared/themes";
import cn from "classnames"

const SearchTariffs = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const size = searchParams.get("size")
    const page = searchParams.get("page")
    const searchQuery = searchParams.get("search")

    const ref = useRef<HTMLInputElement>(null)
    const fetchData = useCallback(() => {

        const r = Number(searchQuery)
        let search: number | string | undefined | null = r
        if (isNaN(r) || !searchQuery) {
            search = searchQuery
        }
        TariffStore.fetchAll({
            name: typeof search === "string" ? search : undefined,
            extId: typeof search === "number" ? search : undefined,
            page: page || undefined,
            size: size || undefined,
        })
    }, [size, page, searchQuery])
    useEffect(() => {
        fetchData()
    }, [fetchData])
    const handleSearch = () => {
        setSearchParams(param => {
            param.set("search", ref.current?.value || "")
            return param
        })
    }
    const handleReset = () => {
        if(ref.current){
            ref.current.value = ""
        }
        setSearchParams({})
    }
    return (
        <>
            <div className={cn("flex a-center grow")}>
                <InputSearchIcon
                    ref={ref}
                    className={classNames('input')}
                    id="search-input"
                    placeholder='Введите наименование тарифа или billing ID для поиска'>
                    <Button onClick={handleSearch} isIcon className='search-button'>
                        <SearchIcon color={theme.fifthColor}/>
                    </Button>
                </InputSearchIcon>
                <ButtonRefresh className='mr-32' onClick={handleReset}/>
            </div>
        </>
    );
};

export default SearchTariffs;
