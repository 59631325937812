import theme from '@/shared/themes'
import React, { FC } from 'react'

export const ExclamationMarkIcon: FC = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0001 1.66675C5.40841 1.66675 1.66675 5.40841 1.66675 10.0001C1.66675 14.5917 5.40841 18.3334 10.0001 18.3334C14.5917 18.3334 18.3334 14.5917 18.3334 10.0001C18.3334 5.40841 14.5917 1.66675 10.0001 1.66675ZM9.37508 6.66675C9.37508 6.32508 9.65842 6.04175 10.0001 6.04175C10.3417 6.04175 10.6251 6.32508 10.6251 6.66675V10.8334C10.6251 11.1751 10.3417 11.4584 10.0001 11.4584C9.65842 11.4584 9.37508 11.1751 9.37508 10.8334V6.66675ZM10.7667 13.6501C10.7251 13.7584 10.6667 13.8417 10.5917 13.9251C10.5084 14.0001 10.4167 14.0584 10.3167 14.1001C10.2167 14.1417 10.1084 14.1667 10.0001 14.1667C9.89175 14.1667 9.78341 14.1417 9.68342 14.1001C9.58342 14.0584 9.49175 14.0001 9.40841 13.9251C9.33342 13.8417 9.27508 13.7584 9.23342 13.6501C9.19175 13.5501 9.16675 13.4417 9.16675 13.3334C9.16675 13.2251 9.19175 13.1167 9.23342 13.0167C9.27508 12.9167 9.33342 12.8251 9.40841 12.7417C9.49175 12.6667 9.58342 12.6084 9.68342 12.5667C9.88342 12.4834 10.1167 12.4834 10.3167 12.5667C10.4167 12.6084 10.5084 12.6667 10.5917 12.7417C10.6667 12.8251 10.7251 12.9167 10.7667 13.0167C10.8084 13.1167 10.8334 13.2251 10.8334 13.3334C10.8334 13.4417 10.8084 13.5501 10.7667 13.6501Z" fill={theme.secondColor} />
        </svg>

    )
}

