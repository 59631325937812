import React, { FC } from 'react'
import { InputProps } from '@/shared/types/InputPropsType'
import Styles from './Input.module.scss'
import classNames from 'classnames'
import { ExclamationMarkIcon } from '@/assets/icons/ExclamationMarkIcon'


export const InputExclamation: FC<InputProps> = ({ className, value, placeholder, onChange }) => {
    return (
        <label className={classNames(Styles.searchIconWrapper, 'pl-11', typeof (className) === 'string' ? className : '')}>
            <input type="text" className={classNames(Styles.inputIcon)} placeholder={placeholder} onChange={onChange} value={value} />
            <div className={classNames(Styles.exclamationIcon, 'pointer')} data-tip='Обязательное поле'>
                <ExclamationMarkIcon />
            </div>
        </label>
    )
}
