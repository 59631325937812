import React, {forwardRef} from 'react';
import DatePickerA from "react-datepicker";
import styles from './DatePicker.module.scss'
import classNames from "classnames";
import {CalendarIcon} from "@/assets/icons/CalendarIcon";

interface IProps {
    value?: Date | null;
    placeholder?: string;
    onChange?: (date: Date | null | undefined) => void;
    className?: string;
    dateFormat?: string;
    maxDate?: Date | null;
}

const DatePicker = forwardRef<HTMLInputElement, IProps>(({
                                                             value,
                                                             placeholder,
                                                             onChange,
                                                             className,
                                                             dateFormat,
                                                             maxDate
                                                         }, ref) => {
        return (
            <div className={classNames(styles.wrap, className)}>
                <DatePickerA maxDate={maxDate} dateFormat={dateFormat} ref={ref as any} wrapperClassName={styles.wrapper}
                             className={styles.input}
                             placeholderText={placeholder}
                             locale="ru"
                             onChange={(date) => onChange && onChange(date)}
                             selected={value}/>
                <div className={styles.icon}>
                    <CalendarIcon/>
                </div>
            </div>
        )
    }
);

export default DatePicker;
