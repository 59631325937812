import React, { ChangeEvent, FormEvent, useRef } from 'react';
import { Card } from '@/shared/ui/Card';
import cn from 'classnames';
import { Input } from '@/shared/ui/Inputs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from 'draft-js';
import { Checkbox } from '@/shared/ui/Checkbox';
import { observer } from 'mobx-react-lite';
import { Button } from '@/shared/ui/Buttons';

interface IProps {
	isRecipients: boolean;
	subject?: string;
	setSubject: (value: string) => void;
	isHtmlBody?: boolean;
	changeIsHtmlBody?: (value: boolean) => void;
	editorState?: EditorState;
	files: File[],
	setFiles: (files: File[]) => void;
	setEditorState?: (value: EditorState) => void;
}

export const MessageForm = observer<IProps>(({
																							 subject,
																							 setSubject,
																							 isHtmlBody,
																							 changeIsHtmlBody,
																							 editorState,
																							 setEditorState,
																							 setFiles, files,
																							 isRecipients,
																						 }) => {
	const ref = useRef<HTMLInputElement>(null);
	const handleChangeFileInput = (e: any) => {
		const target = e.target;
		const files = target.files;
		setFiles([...files]);
	};
	return (
		<Card className={cn('py-30 px-27 mt-12')}>
			<div className={cn('mb-30')}>
				<div className={cn('c_first_color fs-14 mb-7')}>Тема</div>
				<Input value={subject} onChange={e => setSubject(e.target.value)} />
			</div>
			<div className={cn('mb-30')}>
				<div className={cn('c_first_color fs-14 mb-7')}>Текст</div>
				<Editor
					editorState={editorState}
					toolbarClassName='toolbarClassName'
					wrapperClassName='wrapperClassName'
					editorClassName='editorClassName'
					onEditorStateChange={setEditorState}
				/>
			</div>
			<div className='flex'>
				<label className={cn('flex a-center')}>
					<Checkbox checked={isHtmlBody} onChange={() => changeIsHtmlBody && changeIsHtmlBody(!isHtmlBody)} />
					<span className={cn('ml-5 c_first_color fs-14 fw-400 pointer')}>Тело письма - HTML</span>
				</label>

			</div>
			{
				isRecipients && <>
					<div className='flex mt-15'>
						<div className='flex'>
							<input onChange={handleChangeFileInput} type='file' ref={ref} style={{ display: 'none' }} multiple />
							<Button onClick={() => {
								if (ref.current) {
									ref.current.click();
								}
							}}>
						<span className={'px-10'}>
						Добавить файлы
						</span>
							</Button>
						</div>
					</div>
					{
						files.map(item => <div className={cn('mt-5 fs-14 c_first_color')}>{item.name}</div>,
						)
					}
					{
						!!files.length && <div onClick={() => setFiles([])} className='c_first_color fs-14 pointer mt-5'>
							Отменить загрузку файлов
						</div>
					}
				</>
			}

		</Card>
	);
});

