import {flow, types} from "mobx-state-tree";
import makeInspectable from 'mobx-devtools-mst';
import {api} from "@/shared/api/api";
import {RecoveryPasswordModel} from "./RecoveryPasswordModel";
import {instance} from "@/shared/api/instanceAxios";

const rootStoreModel = types.model({
    token: types.maybeNull(types.string),
    me: types.maybeNull(types.frozen()),
    refreshToken: types.maybeNull(types.string),
    menu: types.array(types.frozen()),
    recoveryPassword: RecoveryPasswordModel,
}).actions(self => ({
    afterCreate() {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        const refreshToken = localStorage.getItem('refreshToken')

        if (token || refreshToken) {
            self.token = token;
            this.loadMe().then(r => this.loadMenu())

        }
        if (refreshToken) self.refreshToken = refreshToken


    },
    setToken(token: string | null) {
        self.token = token
    },
    setRefreshToken(token: string | null) {
        self.refreshToken = token
    },
    signIn(saveMe: boolean, access_token: string, refresh_token: string) {

        self.token = access_token;
        self.refreshToken = refresh_token;
        localStorage.setItem('refreshToken', refresh_token)
        if (saveMe) {
            localStorage.setItem('saveMe', 'true')
        }
        this.loadMe();
        this.loadMenu();
        localStorage.setItem('token', access_token)

    },
    loadMenu: flow(function* () {
        const response = yield instance.get('/sections/list')
        self.menu = response.data
    }),
    loadMe: flow(function* () {
        self.me = yield api.getMe()
    }),
    logout() {
        self.token = null
        self.refreshToken = null
        sessionStorage.removeItem('token')
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken')
    },
}))


export const rootStore = rootStoreModel.create({
    recoveryPassword: {
        emailRecovery: null,
        codeWaiting: false,
        code: null,
    }
})

makeInspectable(rootStore)
