import React, {FC} from 'react';
import s from './Tabs.module.scss';
import cn from 'classnames'

interface IProps {
    tabs: string[]
    setTab: (index: number) => void;
    activeTab: number;
}

export const Tabs: FC<IProps> = ({tabs, setTab, activeTab}) => {
    return (
        <div className={cn(s.tabs)}>
            {
                tabs.map((item, index) => <div key={index} onClick={() => setTab(index)}
                                               className={cn(s.item, index === activeTab ? cn(s.active) : '')}>{item}</div>)
            }
        </div>
    );
};

