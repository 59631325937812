import {instance} from "@/shared/api/instanceAxios";

export const tariffsServices = {
    getTariffsList: async (params?: any) => {
        const response = await instance.get('/tariff/listTariffs', {
            params
        })
        return response.data
    },
    getTariffById: async (params?: any) => {
        const response = await instance.get("/tariff/getTariff", {
            params
        })
        return response.data
    },
    changeTariff: async (tariffId: number, body: any) => {
        const response = await instance.put('/tariff/updateTariff', body, {
            params: {
                tariffId
            }
        })
        return response.data
    },
    createTariff: async (body: any) => {
        const response = await instance.post('/tariff/updateTariff', body)
        return response.data
    },
    getTariffsPeriod: async () => {
        const response = await instance.get("/tariff/getPeriods")
        return response.data
    }
}
