import {api} from "@/shared/api/api";
import {flow, Instance, types} from "mobx-state-tree";
import {ISaveChangesData} from "@/shared/api/taxpayersServices";

const historyItemModel = types.model({
    id: types.number,
    organisationId: types.number,
    fieldName: types.string,
    fieldValue: types.maybeNull(types.string),
    updatedAt: types.string,
})


export interface historyTaxpayerItem extends Instance<typeof historyItemModel> {
}

const HistoryChangesModel = types.model({
    loading: types.boolean,
    totalItems: types.number,
    itemsPerPage: types.number,
    totalPages: types.number,
    currentPage: types.number,
    organisationFieldsHistoryList: types.array(historyItemModel)
}).actions(self => ({
    fetchById: flow(function* (params) {
        self.loading = true;
        try {
            const data = yield api.getHistoryTaxpayer(params)
            self.organisationFieldsHistoryList = data.organisationFieldsHistoryList
            self.totalPages = data.totalPages
            self.currentPage = data.currentPage
            self.totalItems = data.totalItems
            self.itemsPerPage = data.itemsPerPage
        } catch (e) {
            throw e
        } finally {
            self.loading = false
        }

    }),
    saveChanges: flow(function* (id: number, data: ISaveChangesData) {
        yield api.setTaxpayerData(id, data)
    })
}))

export const TaxpayersHistoryStore = HistoryChangesModel.create({
    loading: false,
    totalItems: 0,
    itemsPerPage: 0,
    totalPages: 0,
    currentPage: 0,
    organisationFieldsHistoryList: []
})
