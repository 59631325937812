import React, { FC } from 'react'
import classNames from 'classnames';
import Styles from './Hr.module.scss'

interface IHrProps {
    className?: string;
}


export const Hr: FC<IHrProps> = ({ className }) => {
    return (
        <div className={classNames(Styles.hr, className)} />
    )
}
