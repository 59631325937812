import { api } from "@/shared/api/api";
import { flow, types } from "mobx-state-tree";

const SettingsModel = types.model({
    settings: types.array(types.model({
        Key: types.string,
        Value: types.maybeNull(types.string),
    }))
}).actions((self) => ({
    fetchAll: flow(function* (searchQuery?: string) {
        let data = []
        if (searchQuery) {
            data = yield api.getSettingsByKeyOrValue(searchQuery);
        } else {
            data = yield api.getAllSettings()

        }

        self.settings = data
    }),
    changeValue(Key: string, Value: string) {
        const set = self.settings.find(item => item.Key === Key)
        if (set) {
            set.Value = Value
        }
    },
    saveSetting: flow(function* (Key: string, Value: string) {
        yield api.saveSetting(Key, Value)
    })
}))

export const SettingsStore = SettingsModel.create({
    settings: []
})
