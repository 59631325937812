import { FC } from 'react'
import { Card } from '@/shared/ui/Card/Card'
import Styles from './SidePanel.module.scss'
import { Logo } from '@/shared/ui/Logo'
import { MenuList } from './MenuList'
import classNames from 'classnames'
import { UserInfo } from './UserInfo'
import { ShowHideIcon } from '@/assets/icons/ShowHideIcon'
import {useShowHideAnimation} from '@/shared/hooks'
export const SidePanel: FC = () => {

    //Animation showHide hook
    const [show, setShow, ref] = useShowHideAnimation({
        classHide: Styles.hideCard,
        classShow: Styles.openCard
    })

    return (
        <Card className={classNames(Styles.card)}>
            <div ref={ref} className={classNames(Styles.sidePanel)}>
                <button
                    onClick={() => {
                        setShow(!show)
                    }}
                    className={classNames(Styles.showHideButton, !show ? Styles.hide : "")}>
                    <ShowHideIcon />
                </button>
                <Logo show={show} className={classNames('mb-50')} />
                <MenuList show={show} />
                <UserInfo show={show} />

            </div >
        </Card >
    )
}

